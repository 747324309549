import React from "react";
import {Form, InputNumber, Select} from "antd";
import {Option} from "antd/es/mentions";
import {Category} from "../../data/Category";
import CategoryApi from "../../apis/CategoryApi";
import {DetailEditView, DetailEditViewProps, DetailEditViewState} from "../../frame/components/base/DetailEditView";
import {Budget, getIntervalTranslation, Interval} from "../../data/Budget";
import {BudgetsApi} from "../../apis/BudgetsApi";


interface State extends DetailEditViewState {
    categoriesLoading: boolean
    categories?: Category[]
}

interface Props extends DetailEditViewProps {

}

export default class BudgetsDetailView extends DetailEditView<Props, State, Budget> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            categoriesLoading: true,
            entryId: props.match.params.id
        }
    }

    componentDidMount() {
        this.update();
    }

    protected async update() {
        if (this.isNew()) {
            this.formRef.current?.setFieldsValue({
                amount: 0,
                executionDay: 1
            });
            this.setState({loading: false});
        } else {
            let recurringTransactionEntry = await BudgetsApi.getEntry(this.state.entryId);
            this.formRef.current?.setFieldsValue(recurringTransactionEntry);
            this.setState({loading: false});
        }
        let categories = await CategoryApi.getAll("");
        this.setState({categories, categoriesLoading: false});
    }

    renderForm() {
        return <>
            {this.renderDesignation()}

            <Form.Item
                label="Betrag"
                name="amount"
                required
                rules={[{pattern: /^(-[1-9]\d*(.\d+)?)$/, message: "Budgets können nur Ausgaben sein"}]}
            >
                {this.loadable(<InputNumber step={0.01} decimalSeparator="," style={{width: "100%"}}/>)}
            </Form.Item>

            <Form.Item
                label="Kategorien"
                name={["categoryIds"]}
            >
                {this.loadable(
                    <Select
                        loading={this.state.categoriesLoading}
                        disabled={this.state.categoriesLoading}
                        showSearch
                        optionFilterProp="children"
                        mode="multiple"
                    >
                        {this.state.categories?.map(value => <Option value={value.id}>{value.designation}</Option>)}
                    </Select>
                )}
            </Form.Item>
            <Form.Item
                label="Interval"
                name={"interval"}
            >
                {this.loadable(
                    <Select
                        loading={this.state.loading}
                        disabled={this.state.loading}
                        showSearch
                        optionFilterProp="children"
                    >
                        {Object.values(Interval).map(value => <Option value={value}>{getIntervalTranslation(value)}</Option>)}
                    </Select>
                )}
            </Form.Item>

            {this.renderDescription()}
        </>;
    }

    protected deleteHandler() {
        return BudgetsApi.deleteEntry;
    }

    protected redirectTarget(): string {
        return "/recurring-transactions";
    }

    protected redirectTargetEditPage(): string {
        return "/budgets";
    }

    protected createHandler() {
        return BudgetsApi.postEntry;
    }

    protected updateHandler() {
        return BudgetsApi.putEntry;
    }


}
