import React from "react";
import {OverviewProps, OverviewState, OverviewView} from "../../frame/components/base/OverviewView";
import {Institution} from "../../data/bankingapi/BankingApiModel";
import {BankingApi} from "../../apis/BankingApi";

interface State extends OverviewState {
    data: Institution[]
}

interface Props extends OverviewProps {
}

class AddInstitutionView extends OverviewView<Props, State, Institution> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            query: "",
            data: this.generateDummyData()
        }
    }

    async componentDidMount() {
        await this.loadBaseQueryFromUrl();
        await this.onSearch();
    }

    renderList() {
        return <>

            <h3 style={{margin: "10px"}}>Institution auswählen (nur noch nicht hinzugefügte auswählbar)</h3>
            {this.renderBasicCardList(this.state.data, item => <b><img style={{marginRight: 10}} width={40}
                                                                       src={item.logo} alt={item.name}/> {item.name}
            </b>, 46)}
        </>;
    }

    protected showAddButton(): boolean {
        return false;
    }

    protected async onItemSelected(item: Institution) {
        const result = await BankingApi.addInstitution(item);
        window.location.href = result.link;
    }

    protected getBasePath(): string {
        return "/banking-api/add-new";
    }

    protected async onSearch() {
        this.setState({loading: true, data: this.generateDummyData()})
        await this.pushBasicStateToUrl();
        this.setState({loading: false, data: await BankingApi.getBanks(this.state.query)})
    }

}

export default AddInstitutionView;