import React from 'react';
import {Col, DatePicker, Row} from "antd";
import DateHelper, {Range} from "../../global/DateHelper";
import moment from "moment";
import Button from "antd/es/button";

export class Parent extends React.Component<any, { from: string, to: string }> {

}

interface State {
}

interface Props {
    parent: Parent
}

export class RangePicker extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {}
    }

    private width100 = {width: "100%"};

    render() {
        return <>
            <Row style={{margin: "10px"}}>
                <Col xs={24} sm={12} md={12}>
                    <DatePicker value={DateHelper.toMomentDate(this.props.parent.state.from)} placeholder="von"
                                style={this.width100}
                                format="DD.MM.yyyy" allowClear={false} onChange={val => this.setFrom(val)}/>
                </Col>

                <Col xs={24} sm={12} md={12}>
                    <DatePicker value={DateHelper.toMomentDate(this.props.parent.state.to)} placeholder="bis"
                                style={this.width100}
                                format="DD.MM.yyyy" allowClear={false} onChange={val => this.setTo(val)}/>
                </Col>

            </Row>

            {RangePicker.renderButtonSet(
                <Button onClick={() => this.setRange(DateHelper.getNextMonth(this.getRange(), -1))}
                        style={this.width100}>{"<"}</Button>,
                <Button onClick={() => this.setRange(DateHelper.getCurrentMonthRange())} style={this.width100}>Aktueller
                    Monat</Button>,
                <Button onClick={() => this.setRange(DateHelper.getNextMonth(this.getRange(), 1))}
                        style={this.width100}>{">"}</Button>
            )}

            {RangePicker.renderButtonSet(
                <Button onClick={() => this.setRange(DateHelper.getNextYear(this.getRange(), -1))}
                        style={this.width100}>{"<"}</Button>,
                <Button onClick={() => this.setRange(DateHelper.getCurrentYearRange())}
                        style={this.width100}>{"Aktuelles Jahr  "}</Button>,
                <Button onClick={() => this.setRange(DateHelper.getNextYear(this.getRange(), 1))}
                        style={this.width100}>{">"}</Button>
            )}

        </>;
    }

    private static renderButtonSet(first: any, second: any, third: any) {

        return <Row style={{margin: "10px"}}>
            <Col xs={4} sm={4} md={4}>
                {first}
            </Col>

            <Col xs={16} sm={16} md={16}>
                {second}
            </Col>

            <Col xs={4} sm={4} md={4}>
                {third}
            </Col>
        </Row>

    }

    public getRange(): Range {
        return {to: this.props.parent.state.to, from: this.props.parent.state.from};
    }

    public setRange(currentMonthRange: Range) {
        this.props.parent.setState({to: currentMonthRange.to, from: currentMonthRange.from});
    }

    public setFrom(val: moment.Moment | null) {
        if (val) {
            this.props.parent.setState({from: DateHelper.fromMomentDate(val) || ""})
        }
    }

    public setTo(val: moment.Moment | null) {
        if (val) {
            this.props.parent.setState({to: DateHelper.fromMomentDate(val) || ""})
        }
    }

}
