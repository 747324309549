import React from "react";
import {Card, Col, List, Progress} from "antd";
import {File} from "../../data/File";
import MyFileApi from "../../apis/MyFileApi";
import Row from "antd/es/row";
import Formatter from "../../global/Formatter";
import {OverviewState, OverviewView} from "../../frame/components/base/OverviewView";
import AppendedFiles from "../../frame/components/AppendedFiles";
import {FilePreview} from "../../frame/components/FilePreview";

interface State extends OverviewState {
    files: File[],
    currentSize: number,
    maxSize: number,
    sizesLoading: boolean
}

interface Props {
    parent?: AppendedFiles
}

export class MyFilesView extends OverviewView<Props, State, File> {

    constructor(props: Props) {
        super(props);
        this.state = {
            query: "",
            files: [],
            loading: true,
            sizesLoading: true,
            currentSize: 0,
            maxSize: 0
        }
    }

    async componentDidMount() {
        if (!this.props.parent) {
            await this.loadBaseQueryFromUrl();
        }
        await this.onSearch();

        if (!this.props.parent) {
            this.setState({sizesLoading: true});
            const sizes = await MyFileApi.getCurrentSizeConsumption();
            this.setState({currentSize: sizes.currentSize, maxSize: sizes.maxSize, sizesLoading: false});
        }
    }

    protected async onSearch() {
        this.setState({files: this.generateDummyData(), loading: true});
        if (!this.props.parent) {
            await this.pushBasicStateToUrl();
        }
        const entries = await MyFileApi.query(this.state.query);
        this.setState({files: entries, loading: false});
    }

    protected renderList() {

        return <List
            grid={{
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 2,
                xl: 3,
                xxl: 4
            }}
            dataSource={this.state.files}
            renderItem={item => (
                <List.Item>
                    {this.loadable(
                        <Card hoverable onClick={() => this.onItemSelected(item)}>
                            <FilePreview file={item} previewable={false}/>
                            {item.filename}
                        </Card>
                        , 400)}
                </List.Item>
            )}
        />
    }

    protected getBasePath(): string {
        return "/files";
    }

    protected showAddButton(): boolean {
        return false;
    }

    protected onItemSelected(item: File): void {
        if (this.props.parent) {
            this.props.parent.pushFile(item);
        } else {
            super.onItemSelected(item);
        }
    }

    protected renderAdditionalContent() {

        if (this.props.parent) {
            return <></>;
        }

        if (this.state.sizesLoading) {
            return this.renderSkeleton();
        }

        const percent = (this.state.currentSize / this.state.maxSize) * 100;
        const label = Formatter.formatAsMb(this.state.currentSize) + "/" + Formatter.formatAsMb(this.state.maxSize);

        return <Row gutter={[16, 16]} style={{padding: "10px"}}>
            <Col xs={16}>
                <Progress showInfo={false} percent={percent} type="line"/>
            </Col>
            <Col xs={8}>
                {label} MB ({Formatter.formatPercent(percent)})
            </Col>
        </Row>;
    }

}

