import React, {CSSProperties} from "react";
import {CryptoCurrency} from "../../../data/Crypto";
import Formatter from "../../../global/Formatter";
import {OverviewState, OverviewView} from "../../../frame/components/base/OverviewView";
import CryptoApi from "../../../apis/CryptoApi";

interface State extends OverviewState {
    entries: CryptoCurrency[]
}

interface Props {

}

export default class CryptoCurrenciesView extends OverviewView<Props, State, CryptoCurrency> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            entries: this.generateDummyData(),
            query: ""
        }
    }

    private bigStyle: CSSProperties = {
        margin: 0,
        fontSize: "17px"
    };

    private smallStyle: CSSProperties = {
        margin: 0,
        fontSize: "15px"
    };

    async componentDidMount() {
        await this.loadBaseQueryFromUrl();
        await this.onSearch();
    }

    protected renderList() {
        return this.renderBasicCardList(this.state.entries, item => this.renderListItem(item))
    }

    private renderListItem(item: CryptoCurrency) {
        return <>
            <p style={this.bigStyle}>{item.currencyName}
                <i style={{fontSize: "12px"}}>Letztes Update: {Formatter.formatDateTime(item.timestamp)}</i>
            </p>
            <p style={this.smallStyle}>{Formatter.formatCurrencyWithDigits(item.price, 10)}</p>
        </>;
    }

    protected getBasePath(): string {
        return "/crypto/currencies";
    }

    protected showAddButton(): boolean {
        return false;
    }

    protected async onSearch() {
        this.setState({entries: this.generateDummyData(), loading: true});
        const entries = await CryptoApi.queryCurrencies(this.state.query);
        this.pushBasicStateToUrl();
        //TODO: vllt. in Zukunft auf Server auslagern...!
        const filteredEntries = entries.filter(value =>
            value.currencyName.toLocaleLowerCase().match(`.*${this.state.query.toLocaleLowerCase()}.*`)
        );
        this.setState({entries: filteredEntries, loading: false});
    }

}

