import React from 'react';
import './ResponsiveSidebar.css';
import 'antd/dist/antd.less';
import {Link} from 'react-router-dom';

import {Drawer, Menu} from "antd";
import Sider from "antd/es/layout/Sider";
import App from "../App";
import {Navigation, NavigationEntry} from "./Navigation";
import {ItemType} from "antd/lib/menu/hooks/useItems";

interface Props {
    drawerOpened: boolean,
    parent: App,
    versionString: string
}

interface State {

}


class ResponsiveSidebar extends React.Component<Props, State> {


    render() {
        return (
            <>
                <Sider
                    theme="light"
                    collapsible
                    collapsed={!this.props.drawerOpened}
                    trigger={null}
                    className="sider-invisible"
                    width={275}
                >
                    {this.renderMenu(false)}
                </Sider>

                <Drawer visible={this.props.drawerOpened}
                        mask={true}
                        maskClosable={true}
                        placement="left"
                        closeIcon={false}
                        onClose={() => this.props.parent.close()}
                        width={260}
                        className="drawer-invisible" bodyStyle={{padding: "0px", margin: "0px"}}
                >
                    {this.renderMenu(true)}
                </Drawer>
            </>

        );

    }

    private renderMenu(closeOnNav: boolean) {
        return <>
            <Menu theme="light" mode="inline" selectable
                  onSelect={() => closeOnNav ? this.props.parent.close() : null}
                  defaultSelectedKeys={Navigation.getSelectedByLocation()}
                  defaultOpenKeys={Navigation.getOpenedByLocation()}
                  items={Navigation.getAccessibleInMenu().map(value => {
                      return value.subEntries ? ResponsiveSidebar.renderMenuGroupItem(value) :
                          ResponsiveSidebar.renderMenuItem(value)
                  })}
            />

            <p onClick={() => closeOnNav ? this.props.parent.close() : null}
               style={{width: "100%", textAlign: "center", marginTop: "25px"}}>
                <Link to="/local-settings">{this.props.versionString}</Link></p>

        </>;
    }

    private static renderMenuGroupItem(value: NavigationEntry): ItemType {
        return {
            key: value.orderKey + "",
            label: value.label,
            children: Navigation.getAccessibleInMenu(value.subEntries).map(ResponsiveSidebar.renderMenuItem),
            icon: value.icon,
        }
    }

    private static renderMenuItem(value: NavigationEntry): ItemType {
        return {
            key: value.orderKey + "",
            label: <Link to={value.path || ""}>{value.label} {value.betaFeature ?
                <i> (beta)</i> : ""} {value.devFeature ? <i> (dev)</i> : ""}</Link>,
            icon: value.icon
        }
    }
}

export default ResponsiveSidebar;
