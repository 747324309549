import React from 'react';
import StatisticApi from "../../apis/StatisticApi";
import {LabelValuePayload} from "../../data/statistics/LabelValuePayload";
import {Card, ConfigProvider} from "antd";
import NoData from "../../global/NoData";
import {List} from "antd/es";
import Formatter from "../../global/Formatter";
import Row from "antd/es/row";
import Col from "antd/es/col";
import {Redirect} from "react-router-dom";
import {WidgetBase} from "./WidgetBase";

interface State {
    loading: boolean,
    incomes: LabelValuePayload[],
    expenses: LabelValuePayload[],
    redirectId?: string
}

interface Props {
    from: string,
    to: string
}

class TopIncomesExpensesWidget extends WidgetBase<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            incomes: [],
            expenses: [],
            loading: true
        }
    }

    async componentDidMount() {
        await this.update();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.from !== prevProps.from || this.props.to !== prevProps.to) {
            await this.update();
        }
    }

    private async update() {
        const rawData = await StatisticApi.getTopIncomesExpenses(this.props.from, this.props.to);
        this.setState({incomes: rawData.incomes, expenses: rawData.expenses, loading: false});
    }

    render() {

        if (this.state.redirectId) {
            return <Redirect to={`/budget-book/${this.state.redirectId}`} push/>;
        }

        return <>
            <Row style={{margin: "10px"}}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    {this.loadable(this.renderList(this.state.incomes, true), 400)}
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    {this.loadable(this.renderList(this.state.expenses, false), 400)}
                </Col>
            </Row>
        </>
    }

    private renderList(entries: LabelValuePayload[], positive: boolean) {
        return <ConfigProvider renderEmpty={NoData.getNoDataDisplayNoAdd()}>
            <List loading={this.state.loading}
                  dataSource={entries}
                  bordered
                  header={positive ? "Top Einnahmen" : "Top Ausgaben"}
                  renderItem={(entry: LabelValuePayload) => (
                      <Card hoverable
                            key={entry.id}
                            size="small"
                            style={{margin: "10px"}}
                            loading={this.state.loading}
                            onClick={() => this.setState({redirectId: entry.id})}
                      >
                          <p style={{margin: 0, fontSize: "17px"}}>
                              <b>{entry.designation}:</b> {Formatter.formatCurrency(entry.value)}
                          </p>
                      </Card>
                  )}
            >
            </List>
        </ConfigProvider>;
    }

}

export default TopIncomesExpensesWidget;