import React from "react";
import {Button, FormInstance, InputNumber, Space} from "antd";
import {InstrumentPlanElement} from "../../../data/Instrument";
import {InstrumentApi} from "../../../apis/InstrumentApi";
import Icon from "../../../frame/components/Icon";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import Column from "antd/es/table/Column";
import DateHelper from "../../../global/DateHelper";
import moment from "moment";
import {Utils} from "../../../frame/Utils";
import {InstrumentPlanTableBase, InstrumentPlanTableBaseProps} from "./InstrumentPlanTableBase";

interface State {
}

interface Props extends InstrumentPlanTableBaseProps {

}

export class InstrumentExcecutionTab extends InstrumentPlanTableBase<Props, State> {

    protected formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {
            renderModal: false
        }
    }

    async componentDidMount() {
        this.formRef.current?.setFieldsValue(this.props.plan);
    }

    render() {


        return <div style={{margin: "10px"}}>
            <Space style={{marginBottom: "10px"}}>
                {this.renderTopButton()}
            </Space>
            {this.renderList()}
        </div>;
    }

    private renderTopButton() {
        return <Button onClick={() => this.onExecute()} loading={this.props.loading}>
            <Icon icon={faPlus}/> Übertragen
        </Button>
    }

    protected renderColumns() {
        return <>
            {this.renderNameColumn()}
            {this.renderActualExecutionAmountColumn()}
            {this.renderExecutionPriceInputColumn()}
        </>;
    }

    protected renderSummaryRow(data: InstrumentPlanElement[]): React.ReactNode {
        return <></>;
    }

    private renderActualExecutionAmountColumn() {
        return <Column title="Tatsächlicher Einkaufswert" render={(value, record) => {
            return <><InputNumber value={(record as InstrumentPlanElement).actualExecAmount} min={0} step={1E-4}
                                  decimalSeparator=","
                                  onChange={value => this.handleOnActualExecutionAmountChange(record as InstrumentPlanElement, value)}
                                  style={{marginRight: 5}}
            />
            </>
        }}/>
    }

    private renderExecutionPriceInputColumn() {
        return <Column title="Einkaufspreis pro Stück" render={(value, record) => {
            return <><InputNumber value={(record as InstrumentPlanElement).buyIn} min={0} step={1E-4}
                                  decimalSeparator=","
                                  onChange={value => this.handleOnBuyInChange(record as InstrumentPlanElement, value)}/></>
        }}/>
    }

    private handleOnActualExecutionAmountChange(element: InstrumentPlanElement, value: number) {
        const plan = this.props.plan;
        element.actualExecAmount = value;
        this.setListState(plan);
    }

    private handleOnBuyInChange(element: InstrumentPlanElement, value: number) {
        const plan = this.props.plan;
        element.buyIn = value;
        this.setListState(plan);
    }

    protected getData() {
        return super.getData().filter(value => value.percent > 0);
    }


    private onExecute() {
        this.getData().forEach(value => {
            const absoluteAmount = (value.percent / 100) * this.props.plan.totalPerMonth;
            InstrumentApi.postTransaction({
                instrument: value.instrument,
                date: DateHelper.fromMomentDate(moment()) || "0",
                buyInPrice: value.buyIn,
                amount: Utils.round(absoluteAmount / value.buyIn, 4),
                description: "",
                type: "BUY",
                id: ""
            })
        });
    }
}