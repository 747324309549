import React from "react";
import {DatePicker, Form, InputNumber} from "antd";
import TextArea from "antd/es/input/TextArea";
import {Instrument, InstrumentTransaction} from "../../data/Instrument";
import {DetailEditView, DetailEditViewProps, DetailEditViewState} from "../../frame/components/base/DetailEditView";
import DateHelper from "../../global/DateHelper";
import {InstrumentApi} from "../../apis/InstrumentApi";
import {InstrumentCard} from "./InstrumentCard";
import Formatter from "../../global/Formatter";
import {InstrumentRenderingHelper} from "./InstrumentRenderingHelper";
import {TreeSlotGrid} from "../../frame/components/TreeSlotGrid";

interface State extends DetailEditViewState {
    entry: InstrumentTransaction
    instrument?: Instrument,
    renderModal: boolean
}

interface Props extends DetailEditViewProps {

}

export class InstrumentTransactionsDetailView extends DetailEditView<Props, State, InstrumentTransaction> {

    constructor(props: Props) {
        super(props)
        this.state = {
            entry: {
                buyInPrice: 0,
                amount: 0,
                instrument: {
                    lastPrice: 0
                }
            } as unknown as InstrumentTransaction,
            loading: true,
            renderModal: false,
            entryId: this.props.match.params.id
        }
    }

    componentDidMount() {
        this.update();
    }

    protected async update() {

        if (this.isNew()) {
            this.formRef.current!.setFieldsValue({
                description: "",
                dateAsMoment: DateHelper.toMoment(Date.now()),
            });
        } else {
            const entry = await InstrumentApi.getTransaction(this.state.entryId);
            entry.dateAsMoment = DateHelper.toMomentDate(entry.date);
            this.formRef.current!.setFieldsValue(entry);
            this.setState({entry, instrument: entry.instrument});
        }

        this.setState({loading: false});
    }

    renderForm() {
        //TODO: Übersicht hinzufügen...

        const buyIn = this.state.entry.buyInPrice * this.state.entry.amount;
        const current = this.state.entry.instrument.lastPrice * this.state.entry.amount;

        return <>
            {this.state.renderModal ? this.renderModal() : null}

            <Form.Item
                label={" "}
                colon={false}
            >
                <TreeSlotGrid className="price-style-statistics"
                              firstElement={"Kosten: " + Formatter.formatCurrency(buyIn)}
                              secondElement={"Wert: " + Formatter.formatCurrency(current)}
                              thirdElement={<>Bilanz: {this.formatCurrencyColored(current - buyIn)} ({this.formatPercentColored(((current / buyIn) - 1) * 100)})</>}/>

            </Form.Item>

            <Form.Item
                label="Instrument"
            >
                {this.loadable(this.renderInstrumentSelect())}
            </Form.Item>

            <Form.Item
                label="Anzahl"
                name="amount"
                rules={[{required: true, message: 'Bitte Anzahl ausfüllen!'}]}
            >
                {this.loadable(<InputNumber style={{width: "100%"}} min={0} step={1E-4} decimalSeparator=","/>)}
            </Form.Item>

            <Form.Item
                label="Preis pro Stück (€)"
                name="buyInPrice"
                rules={[{required: true, message: 'Bitte Preis ausfüllen!'}]}
            >
                {this.loadable(<InputNumber style={{width: "100%"}} min={0} step={1E-4} decimalSeparator=","/>)}
            </Form.Item>

            <Form.Item
                label="Datum"
                name="dateAsMoment"
                required
            >
                {this.loadable(<DatePicker style={{width: "100%"}} format="DD.MM.yyyy"/>)}
            </Form.Item>

            <Form.Item
                label="Details"
                name="description"
            >
                {this.loadable(<TextArea rows={5}/>)}
            </Form.Item>
        </>;
    }

    private renderModal() {
        return InstrumentRenderingHelper.renderSelectionModal(() => this.setState({renderModal: false}),
            item => this.setState({
                instrument: item,
                renderModal: false
            }), this.state.instrument?.isin);
    }


    //Overrides:
    protected deleteHandler() {
        return InstrumentApi.deleteTransaction;
    }

    protected redirectTarget(): string {
        return "/instruments/transactions";
    }

    protected prePersistActions(value: InstrumentTransaction): void {
        value.date = DateHelper.fromMomentDate(value.dateAsMoment) || "0";
        if (this.state.instrument) {
            value.instrument = this.state.instrument;
        }
    }

    protected createHandler() {
        return InstrumentApi.postTransaction;
    }

    protected updateHandler() {
        return InstrumentApi.putTransaction;
    }

    private renderInstrumentSelect() {
        return <InstrumentCard hoverable instrument={this.state.instrument}
                               onItemSelected={item => this.setState({renderModal: true})}/>
    }
}
