import {EnvironmentHandler} from "./env/EnvironmentHandler";
import QuickAddView from "../pages/localsettings/QuickAddView";
import Comp from "../global/Comp";
import {ServerConfig} from "../pages/localsettings/ServerConfig";


export interface ConfigTab {
    label: string,
    items?: BoolConfigItem[],
    CustomComponent?: any
}


export interface BoolConfigItem {
    label: string,
    id: string,
    default: boolean
}

export class Config {

    public static CONFIG_DEFINITIONS: ConfigTab[] = [
        {
            label: "Präferenzen",
            items: [
                {id: "sider-default-open", label: "Menü standardmäßig ausgeklappt", default: false},
                {id: "show-precious-metal-widget", label: "Zeige Edelmetall Widget", default: true},
                {id: "show-quick-add-widget", label: "Zeige Schnelles Hinzufügen Widget", default: true},
                {id: "show-top-income-expenses-widget", label: "Zeige Top Einnahme/Ausgaben Widget", default: true},
                {id: "show-cat-in-out-widget", label: "Zeige Einnahmen/Ausgaben je Kategorie Widget", default: true},
                {id: "show-balance-per-month-widget", label: "Zeige Bilanz je Monat Widget", default: true},
                {
                    id: "budget-book-details-opened-by-default",
                    label: "Details Tab standardmäßig öffnen",
                    default: false
                },
                {
                    id: "show-future-entries-by-default",
                    label: "Zukünftige Einträge standardmäßig anzeigen",
                    default: true
                }
            ]
        },
        {
            label: "Server Konfig",
            CustomComponent: ServerConfig
        },
        {
            label: "Schnelles hinzufügen",
            CustomComponent: QuickAddView
        }

    ];

    public static BASE_URL_DEFAULT_PROD = "https://900m7.backend.sollder1.de/yfm/rest";
    public static BASE_URL_DEFAULT_DEV = "http://localhost:8080/backend-1.2.0/rest";

    public static getBaseURL(): string {
        let url: string | null = localStorage.getItem("api-url");

        if (url == null) {
            return EnvironmentHandler.isProd() ? this.BASE_URL_DEFAULT_PROD : this.BASE_URL_DEFAULT_DEV;
        }
        return url;
    }

    public static showPreciousMetalOverview(): boolean {
        return this.getBool("show-precious-metal-widget");
    }

    public static budgetBookDetailsOpenedByDefault(): boolean {
        return this.getBool("budget-book-details-opened-by-default");
    }

    public static getBool(id: string): boolean {
        let def: boolean = Config.CONFIG_DEFINITIONS.flatMap(value => value.items || [])
            .filter(value => value.id === id)[0].default;
        const res: string | null = localStorage.getItem(id);
        if (res == null) {
            return def;
        }
        return res === "true";
    }

    static getString(key: string): string {
        const res = localStorage.getItem(key);
        // @ts-ignore
        return Comp.nill(res) ? "" : res;
    }

    public static persist(key: string, value: any) {
        localStorage.setItem(key, value)
    }

}
