import React from 'react';
import {Col, Row} from "antd";
import {ChartData} from "chart.js";
import StatisticApi from "../../apis/StatisticApi";
import {ChartHelper} from "../../frame/charts/ChartHelper";
import {AssetAllocationLabelValuePayload} from "../../data/statistics/LabelValuePayload";
import {WidgetBase} from "./WidgetBase";


interface State {
    loading: boolean,
    assetData: ChartData<"doughnut">,
    assetDataGrouped: ChartData<"doughnut">,
}

interface Props {
    from: string,
    to: string
}

export class AssetDistributionWidget extends WidgetBase<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            assetData: {datasets: []},
            assetDataGrouped: {datasets: []}
        }
    }

    async componentDidMount() {
        await this.update();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.from !== prevProps.from || this.props.to !== prevProps.to) {
            await this.update();
        }
    }

    private async update() {
        this.setState({loading: true});

        const data = await StatisticApi.getAssetAllocation();
        this.buildState(data);
    }

    private buildState(dataPoints: AssetAllocationLabelValuePayload[]) {
        const groups = Array.from(new Set(dataPoints.map(value => value.group)));
        const assetData = {
            labels: dataPoints.map(value => {
                return value.designation
            }),
            datasets: [
                {
                    data: dataPoints.map(value => value.value),
                    backgroundColor: ChartHelper.hardCodedPalette(dataPoints.length, "light"),
                    fill: false,
                    borderWidth: 1
                }
            ]
        };

        const groupedData = groups.map(group => {
            return dataPoints
                .filter(value => value.group === group).map(value => value.value)
                .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
        });

        const assetDataGrouped = {
            labels: groups.map(this.mapGroupName),
            datasets: [
                {
                    data: groupedData.map(value => value),
                    backgroundColor: ChartHelper.hardCodedPalette(dataPoints.length, "light"),
                    fill: false,
                    borderWidth: 1
                }
            ]
        };

        this.setState({assetData, assetDataGrouped, loading: false});
    }

    private mapGroupName(label: string) {
        switch (label) {
            case "INSTRUMENTS":
                return "Finanzinstrumente"
            case "PRECIOUS_METALS":
                return "Edelmetalle"
            case "CRYPTO":
                return "Crypto"
            default:
                return "-"
        }
    }

    render() {

        return <>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    {this.renderDoughnut(this.state.assetData, "Kapitalallokation")}
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    {this.renderDoughnut(this.state.assetDataGrouped, "Kapitalallokation (gruppiert)")}
                </Col>
            </Row>
        </>
    }

}
