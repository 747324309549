import React from "react";
import {OverviewProps, OverviewState, OverviewView} from "../../frame/components/base/OverviewView";
import {Institution} from "../../data/bankingapi/BankingApiModel";
import {BankingApi} from "../../apis/BankingApi";
import {Redirect} from "react-router-dom";

interface State extends OverviewState {
    data: Institution[],
    addPage: boolean
}

interface Props extends OverviewProps {
}

class ConnectedInstitutionsOverview extends OverviewView<Props, State, Institution> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            addPage: false,
            query: "",
            data: this.generateDummyData()
        }
    }

    async componentDidMount() {
        await this.loadBaseQueryFromUrl();
        await this.onSearch();
    }

    renderList() {

        if (this.state.addPage) {
            return <Redirect to={`/banking-api/add-new`} push/>
        }

        return <>
            {this.renderBasicCardList(this.state.data, item => <b><img style={{marginRight: 10}} width={40}
                                                                       src={item.logo} alt={item.name}/> {item.name}
            </b>, 46)}
        </>;
    }

    protected onAddButton() {
        this.setState({addPage: true});
    }

    protected getBasePath(): string {
        return "/banking-api/connected";
    }

    protected async onSearch() {
        this.setState({loading: true, data: this.generateDummyData()})
        await this.pushBasicStateToUrl();
        this.setState({loading: false, data: await BankingApi.getMyInstitutions(this.state.query)})
    }

}

export default ConnectedInstitutionsOverview;