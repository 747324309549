import {Category} from "../data/Category";
import Rest from "./Rest";

class CategoryApi {

    public static getAll(query: string): Promise<Category[]> {
        return Rest.getMultiple(`/categories?query=${query}`);
    }

    public static getAllParents(): Promise<Category[]> {
        return Rest.getMultiple(`/categories/parents`);
    }

    public static post(value: Category): Promise<Category> {
        return Rest.post(value, "/categories");
    }

    public static get(categoryId?: string): Promise<Category> {
        return Rest.getSpecific(`/categories/${categoryId}`);
    }

    public static put(categoryId: string, value: Category): Promise<Category> {
        return Rest.put(value, `/categories/${categoryId}`);
    }

    public static delete(categoryId?: string): Promise<any> {
        return Rest.delete(`/categories/${categoryId}`);
    }

    static tryToFind(input: string): Promise<Category[]> {
        return Rest.getMultiple(`/categories/functions/find?input=${input}`);
    }
}

export default CategoryApi;