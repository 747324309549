import Rest from "./Rest";
import {CryptoCurrency, CryptoCurrencyEntry, CryptoCurrencyHistory} from "../data/Crypto";
import {PricePrecision} from "../data/PricePrecision";

class CryptoApi {

    public static queryCurrencies(query: string): Promise<CryptoCurrency[]> {
        return Rest.getMultiple(`/crypto/currencies?query=${query}`);
    }

    public static getEntityDetails(id: string, precision: PricePrecision): Promise<CryptoCurrencyHistory> {
        return Rest.getSpecific(`/crypto/currencies/${id}?precision=${precision}`);
    }

    public static queryEntries(query: string): Promise<CryptoCurrencyEntry[]> {
        return Rest.getMultiple(`/crypto/entries?query=${query}`);
    }

    public static getEntry(id: string): Promise<CryptoCurrencyEntry> {
        return Rest.getSpecific(`/crypto/entries/${id}`);
    }

    public static putEntry(id: string, value: CryptoCurrencyEntry): Promise<CryptoCurrencyEntry> {
        return Rest.put(value, `/crypto/entries/${id}`);
    }

    public static postEntry(value: CryptoCurrencyEntry): Promise<CryptoCurrencyEntry> {
        return Rest.post(value, `/crypto/entries`);
    }

    public static deleteEntry(id?: string): Promise<any> {
        return Rest.delete(`/crypto/entries/${id}`);
    }

}

export default CryptoApi;