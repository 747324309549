import React from 'react';

import {PreciousMetalPrices} from "../../data/PreciousMetal";
import PreciousMetalPriceOverviewWidget from "./widgets/PreciousMetalPriceOverviewWidget";
import {Config} from "../../frame/Config";
import DistributedPerCategoryChartWidget from "../statistics/DistributedPerCategoryChartWidget";
import TotalIncomePerMontWidget from "../statistics/TotalIncomePerMontWidget";
import Security from "../../global/Security";
import TopIncomesExpensesWidget from "../statistics/TopIncomesExpensesWidget";
import QuickAddWidget from "./widgets/QuickAddWidget";
import {Col, Row} from "antd";
import DateHelper from "../../global/DateHelper";

interface State {
    priceHistory: PreciousMetalPrices[]
    from: string,
    to: string
}

interface Props {
}

class HomeView extends React.Component<Props, State> {

    //Examples: https://github.com/reactchartjs/react-chartjs-2/tree/master/example/src/charts

    constructor(props: Props) {
        super(props);
        this.state = {
            priceHistory: [],
            from: DateHelper.getCurrentYearRange().from,
            to: DateHelper.getCurrentYearRange().to
        }
    }

    render() {

        if (!Security.get().isLoggedIn()) {
            return <>
                <h1 style={{textAlign: "center"}}>Sie sind nicht eingeloggt!</h1>
                {Config.showPreciousMetalOverview() ? <PreciousMetalPriceOverviewWidget/> : null}
            </>
        }

        return <>
            {Config.showPreciousMetalOverview() ? <PreciousMetalPriceOverviewWidget/> : null}
            {this.renderWidgets()}
        </>
    }

    private renderWidgets() {
        return <>
            <Row gutter={[16, 16]} style={{padding: "10px"}}>
                {
                    Config.getBool("show-quick-add-widget") ?
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                            <QuickAddWidget/>
                        </Col> : null
                }
                {
                    Config.getBool("show-top-income-expenses-widget") ?
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                            <TopIncomesExpensesWidget from={this.state.from} to={this.state.to}/>
                        </Col> : null
                }
                {
                    Config.getBool("show-cat-in-out-widget") ?
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                            <DistributedPerCategoryChartWidget from={this.state.from} to={this.state.to}/>
                        </Col> : null
                }
                {
                    Config.getBool("show-balance-per-month-widget") ?
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                            <TotalIncomePerMontWidget from={this.state.from} to={this.state.to}/>
                        </Col> : null
                }
            </Row>
        </>;
    }


}

export default HomeView;