import {
    Institution,
    InstitutionConnectLink,
    InstitutionDetails,
    Transactions
} from "../data/bankingapi/BankingApiModel";
import Rest from "./Rest";

export class BankingApi {

    private static BASE: string = "/banking-api/nordigen";

    public static async getBanks(query: string) {
        return Rest.getMultiple<Institution>(`${this.BASE}/institutions?query=${query}`);
    }

    public static async getMyInstitutions(query: string) {
        return Rest.getMultiple<Institution>(`${this.BASE}/me/institutions?query=${query}`);
    }

    static async addInstitution(institution: Institution): Promise<InstitutionConnectLink> {
        return Rest.save("POST", institution, `${this.BASE}/me/institutions`);
    }

    static async getInstitutionDetails(institutionId: string) {
        return Rest.getSpecific<InstitutionDetails>(`${this.BASE}/me/institutions/${institutionId}`);
    }

    static async getAccountTransactions(accountId: string) {
        return Rest.getSpecific<Transactions>(`${this.BASE}/me/accounts/${accountId}/transactions`);
    }

    static async evictCache(institutionId: string) {
        return Rest.delete(`${this.BASE}/me/institutions/${institutionId}/accounts-cache`);
    }
}