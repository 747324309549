class Comp {

    public static nill(value: any): boolean {
        return value === undefined || value === null;
    }

    public static empty(value: string | undefined | null): boolean {
        return value === undefined || value === null || value === "";
    }


}

export default Comp;