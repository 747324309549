import React from "react";
import Checkbox from 'antd/es/checkbox';
import {Config, ConfigTab} from "../../frame/Config";
import {Tabs} from "antd";

const {TabPane} = Tabs;

interface State {
}

interface Props {

}

export class LocalSettingsView extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {}
    }

    render() {
        return <>
            <h2 style={{marginTop: 10, marginBottom: 5}}>Lokale Konfiguration</h2>
            <p style={{margin: 0}}><i>Hinweis: Alle Daten werden sofort gespeichert.</i></p>
            <Tabs defaultActiveKey="1">
                {Config.CONFIG_DEFINITIONS.map(value => this.renderDefinition(value))}
            </Tabs>
        </>;
    }

    private renderDefinition(value: ConfigTab) {
        let inner;
        if (value.items) {
            inner = value.items.map(inner => <>
                <Checkbox defaultChecked={Config.getBool(inner.id)}
                          onChange={event => Config.persist(inner.id, event.target.checked)}>{inner.label}
                </Checkbox>
                <br/>
            </>);
        } else if (value.CustomComponent) {
            inner = <value.CustomComponent></value.CustomComponent>;
        } else {
            inner = "Keine Elemente!";
        }

        return <TabPane tab={value.label} key={value.label}>{inner}</TabPane>;

    }
}

