import React from "react";
import {DatePicker, Form, InputNumber, Select} from "antd";
import {Option} from "antd/es/mentions";
import {BudgetBookEntry} from "../../data/BudgetBookEntry";
import {Category} from "../../data/Category";
import CategoryApi from "../../apis/CategoryApi";
import BudgetBookApi from "../../apis/BudgetBookApi";
import moment from "moment";
import DateHelper from "../../global/DateHelper";
import {File} from "../../data/File";
import {DetailEditView, DetailEditViewProps, DetailEditViewState} from "../../frame/components/base/DetailEditView";


interface State extends DetailEditViewState {
    categoriesLoading: boolean
    categories?: Category[],
    files: File[]
}

interface Props extends DetailEditViewProps {

}

class BudgetBookDetailView extends DetailEditView<Props, State, BudgetBookEntry> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            categoriesLoading: true,
            entryId: props.match.params.id,
            files: []
        }
    }

    componentDidMount() {
        this.update();
    }

    protected async update() {
        if (this.isNew()) {
            this.setState({loading: false});
            this.formRef.current?.setFieldsValue({
                dateAsMoment: DateHelper.toMoment(moment.now()),
                amount: 0
            });
        } else {
            let budgetBookEntry = await BudgetBookApi.get(this.state.entryId);
            budgetBookEntry.dateAsMoment = DateHelper.toMomentDate(budgetBookEntry.date)
            this.setState({loading: false, files: budgetBookEntry.files})
            this.formRef.current?.setFieldsValue(budgetBookEntry);
        }
        let categories = await CategoryApi.getAll("");
        this.setState({categories, categoriesLoading: false});
    }


    renderForm() {
        return <>
            {this.renderDesignation()}

            <Form.Item
                label="Datum"
                name="dateAsMoment"
                required
            >
                {this.loadable(<DatePicker style={{width: "100%"}} format="DD.MM.yyyy"/>)}
            </Form.Item>

            <Form.Item
                label="Betrag"
                name="amount"
                required
                shouldUpdate={() => true}

            >
                {this.loadable(<InputNumber step={0.01} decimalSeparator="," style={{width: "100%"}}
                                            addonAfter={<div className="moneyToggle"
                                                             onClick={e => this.onToggle()}>Umkehren</div>}/>)}
            </Form.Item>


            <Form.Item
                label="Kategorie"
                name={["category", "id"]}
            >
                {this.loadable(
                    <Select
                        loading={this.state.categoriesLoading}
                        disabled={this.state.categoriesLoading}
                        showSearch
                        optionFilterProp="children"
                    >
                        <Option value={undefined}>keine</Option>
                        {this.state.categories?.map(value => <Option value={value.id}>{value.designation}</Option>)}
                    </Select>)}
            </Form.Item>

            {this.renderAppendedFiles(this.state.files, this)}
            {this.renderDescription()}
        </>;
    }

    protected deleteHandler() {
        return BudgetBookApi.delete;
    }

    protected redirectTarget(): string {
        return "/budget-book";
    }

    protected prePersistActions(value: BudgetBookEntry): void {
        value.date = DateHelper.fromMomentDate(value.dateAsMoment) || "";
        value.files = this.state.files;
    }

    protected createHandler() {
        return BudgetBookApi.post;
    }

    protected updateHandler() {
        return BudgetBookApi.put;
    }

    private onToggle() {
        this.formRef.current!.setFieldsValue({amount: this.formRef.current!.getFieldValue("amount") * -1});
    }
}

export default BudgetBookDetailView;
