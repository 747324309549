import {Chart, Plugin} from "chart.js";
import {AnyObject, EmptyObject} from "chart.js/types/basic";
import Formatter from "../../global/Formatter";

export class ChartPlugins {

    public static getDoughnutInnerRendererPlugin(placeholder: string, excludePercent?: boolean): Plugin<"doughnut"> {
        return {
            id: "innerText",
            afterDraw(chart: Chart<"doughnut">, args: EmptyObject, options: AnyObject) {
                if (!chart.getDatasetMeta(0).controller) {
                    return;
                }

                //Build State:
                let lines = [placeholder];
                const total = chart.config.data.datasets[0].data.reduce((a, b) => a + b, 0);
                const elementSelected = chart.getActiveElements()[0];

                if (elementSelected) {
                    const labels = chart.config.data.labels;
                    if (!labels) {
                        return;
                    }
                    const data = chart.config.data.datasets[0].data[elementSelected.index];
                    if (excludePercent) {
                        lines = [`${labels[elementSelected.index] as string}`, `${Formatter.formatPercent(data)}`];
                    } else {
                        lines = [`${labels[elementSelected.index] as string} (${Formatter.formatPercent((data / total) * 100)})`,
                            `${Formatter.formatCurrency(data)}`];
                    }
                } else {
                    lines.push(excludePercent ? Formatter.formatPercent(total) : Formatter.formatCurrency(total));
                }

                //Render out:
                const lineHeight = 35;
                const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

                const ctx = chart.ctx;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.font = "italic 15px Roboto";

                ctx.fillStyle = "#d3d3d3";
                const textWidth = ctx.measureText(lines[0]).width;
                const lineHeightFactored = lineHeight * 0.7

                roundRect(ctx, (centerX - textWidth / 2) - 5, centerY - ((lineHeight + lineHeightFactored) / 2), textWidth + 10, lineHeightFactored, 3);

                ctx.fillStyle = "black";
                ctx.fillText(lines[0], centerX, centerY - lineHeight / 2);


                ctx.font = "bold 30px Roboto";
                ctx.fillText(lines[1], centerX, centerY + lineHeight / 2);

                //@ts-ignore
                function roundRect(ctx, x, y, w, h, r) {
                    if (w < 2 * r) r = w / 2;
                    if (h < 2 * r) r = h / 2;
                    ctx.beginPath();
                    ctx.moveTo(x + r, y);
                    ctx.arcTo(x + w, y, x + w, y + h, r);
                    ctx.arcTo(x + w, y + h, x, y + h, r);
                    ctx.arcTo(x, y + h, x, y, r);
                    ctx.arcTo(x, y, x + w, y, r);
                    ctx.closePath();
                    ctx.fill();
                }

            }
        }
    }
}