import React from "react";
import {FormInstance} from "antd";
import {InstrumentPlan} from "../../../data/Instrument";
import JoditEditor from "jodit-react";
import {DetailView} from "../../../frame/components/base/DetailView";
import {InstrumentPlanView} from "./InstrumentPlanView";

interface State {

}

interface Props {
    loading: boolean,
    plan: InstrumentPlan,
    parent: InstrumentPlanView
}

export class InstrumentPlanDescriptionView extends React.Component<Props, State> {

    protected formRef = React.createRef<FormInstance>();

    render() {
        return <div style={{margin: "10px"}}>
            {this.renderDescription()}
        </div>;
    }


    private renderDescription() {
        return <JoditEditor
            value={this.props.plan.description}
            onBlur={value => {
                const plan = this.props.plan;
                plan.description = value;
                this.props.parent.setState({plan: plan})
            }}
            config={DetailView.getJoditConfig(600)}
        />;
    }
}