import {BaseResponse, Timed} from "./BaseResponse";
import moment from "moment";
import {File} from "./File";

export interface PreciousMetalPrices extends Timed {

    priceGold: number;
    priceSilver: number;
    pricePlatinum: number;
    pricePalladium: number;

}

export interface PreciousMetal extends BaseResponse {

    designation: string
    type: PreciousMetalType
    weightGram: number
    purchasePrice: number
    titlePictureId?: string
    description?: string
    currentValue: number
    amount: number
    mintingYear?: number
    date?: string
    dateAsMoment?: moment.Moment
    files: File[]

}

export enum PreciousMetalType {
    GOLD = "GOLD", SILVER = "SILVER", PLATINUM = "PLATINUM", PALLADIUM = "PALLADIUM"
}

