import {TransactionResponse} from "./BaseResponse";
import {Category} from "./Category";

export enum Interval {MONTHLY = "MONTHLY", QUARTERLY = "QUARTERLY", HALF_YEARLY = "HALF_YEARLY", YEARLY = "YEARLY"}


export interface Budget extends TransactionResponse {
    categories: Category[],
    categoryIds: string[],
    interval: Interval
}

export function getIntervalTranslation(interval: Interval) {
    switch (interval) {
        case "MONTHLY":
            return "Monatlich";
        case "QUARTERLY":
            return "Quartalsweise";
        case "HALF_YEARLY":
            return "Halbjährlich";
        case "YEARLY":
            return "Jährlich";
        default:
            return "-";
    }
}