import {Account} from "../data/Account";
import Rest from "./Rest";

class AccountApi {

    public static getAll(query: string): Promise<Account[]> {
        return Rest.getMultiple(`/accounts/admin?query=${query}`);
    }

    public static get(accountId: string): Promise<Account> {
        return Rest.getSpecific(`/accounts/admin/${accountId}`);
    }

    static postEntry(value: Account): Promise<Account> {
        return Rest.post(value, "/accounts/admin");
    }

    static putEntry(accountId: string, value: Account): Promise<Account> {
        return Rest.put(value, `/accounts/admin/${accountId}`);
    }

    static deleteEntry(accountId: string): Promise<Account> {
        return Rest.delete(`/accounts/admin/${accountId}`);
    }


}

export default AccountApi;