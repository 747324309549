import React from 'react';
import {Line} from "react-chartjs-2";
import {ChartData, ChartDataset} from "chart.js";
import Formatter from "../../../global/Formatter";
import {PreciousMetalPrices, PreciousMetalType} from "../../../data/PreciousMetal";
import PreciousMetalApi from "../../../apis/PreciousMetalApi";
import {Radio} from "antd";
import {ChartHelper} from "../../../frame/charts/ChartHelper";
import {PricePrecision} from "../../../data/PricePrecision";
import {LoadingView, LoadingViewState} from "../../../frame/components/base/LoadingView";

interface State extends LoadingViewState {
    currentPrice?: any,
    currentDiff?: any,
    borderColor: string,
    backgroundColor: string,
    data: ChartData<"line">,
    rawDataSorted: PreciousMetalPrices[],
    precision: PricePrecision
}

interface Props {
    displayedType: PreciousMetalType,
    multiplier?: number,
    alternativeTitle?: string
}

class PreciousMetalPriceWidget extends LoadingView<Props, State> {

    //Examples: https://github.com/reactchartjs/react-chartjs-2/tree/master/example/src/charts
    constructor(props: Props) {
        super(props);
        this.state = {
            precision: "DAY",
            loading: true,
            backgroundColor: "",
            data: {
                labels: [],
                datasets: []
            },
            rawDataSorted: [],
            borderColor: ""
        }
    }

    componentDidMount() {
        this.fetchData("DAY");
    }

    async fetchData(precision: PricePrecision) {
        this.setState({loading: true, precision});
        const value = await PreciousMetalApi.getAllPrices(precision);
        await this.update(value);
        this.setState({loading: false});
    }

    async update(history: PreciousMetalPrices[]) {

        let datasets: ChartDataset<"line">[] = [];
        let sortedRawData: PreciousMetalPrices[] = history.sort((a, b) => a.timestamp - b.timestamp) || [];
        await this.setState({rawDataSorted: sortedRawData});
        const multiplier = this.props.multiplier === undefined ? 1 : this.props.multiplier;

        switch (this.props.displayedType) {
            case PreciousMetalType.GOLD:
                this.setHeaderAttributes("priceGold");
                datasets.push(this.generateDataset(
                    "Goldpreis",
                    sortedRawData.map(value => value.priceGold * multiplier) || []
                ));
                break;
            case PreciousMetalType.SILVER:
                this.setHeaderAttributes("priceSilver");
                datasets.push(this.generateDataset(
                    "Silberpreis",
                    sortedRawData.map(value => value.priceSilver * multiplier) || []
                ));
                break;
            case PreciousMetalType.PLATINUM:
                this.setHeaderAttributes("pricePlatinum");
                datasets.push(this.generateDataset(
                    "Platinpreis",
                    sortedRawData.map(value => value.pricePlatinum * multiplier) || []
                ));
                break;
            case PreciousMetalType.PALLADIUM:
                this.setHeaderAttributes("pricePalladium");
                datasets.push(this.generateDataset(
                    "Palladiumpreis",
                    sortedRawData.map(value => value.pricePalladium * multiplier) || []
                ));
                break;
        }


        this.setState({
            data: {
                labels: history.map(value => Formatter.formatDateTime(value.timestamp)),
                datasets: datasets
            }
        });
    }

    private generateDataset(label: string, data?: number[]) {
        return {
            label: this.props.alternativeTitle ? this.props.alternativeTitle : label,
            data: data || [],
            fill: true,
            backgroundColor: this.state.backgroundColor,
            borderColor: this.state.borderColor,
            borderWidth: 1
        }
    }

    render() {
        return <>
            {this.loadable(this.state.currentPrice)}
            {this.loadable(this.state.currentDiff)}
            {this.loadable(<Radio.Group onChange={e => this.fetchData(e.target.value)} value={this.state.precision}>
                <Radio value={"DAY"}>Tag</Radio>
                <Radio value={"WEEK"}>Woche</Radio>
                <Radio value={"MONTH"}>Monat</Radio>
                <Radio value={"YEAR"}>Jahr</Radio>
                <Radio value={"FOREVER"}>Gesamt</Radio>
            </Radio.Group>)}

            {this.loadable(<Line data={this.state.data} options={ChartHelper.getPriceSettings(2)}/>, 500)}
        </>
    }

    private setHeaderAttributes(field: string) {
        ChartHelper.setHeaderAttributes(field, this.state.rawDataSorted, this, this.props.multiplier);
    }

}

export default PreciousMetalPriceWidget;