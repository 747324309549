import {ChartOptions, TooltipItem} from "chart.js";
import Formatter from "../../global/Formatter";
import {Timed} from "../../data/BaseResponse";
import Icon from "../components/Icon";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons/faArrowUp";
import {faArrowDown} from "@fortawesome/free-solid-svg-icons/faArrowDown";
import React from "react";

export interface ChartStateBase {

}

export class ChartHelper {


    public static BALANCE_SETTINGS: ChartOptions<"line"> = {
        scales: {
            y: {
                ticks: {
                    callback: tickValue => {
                        if (typeof tickValue === "number") {
                            return Formatter.formatCurrency(tickValue as number);
                        }
                        return "-";
                    }
                }
            }
        },
        responsive: true,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            legend: {
                position: 'top'
            },
            tooltip: {
                callbacks: {
                    label: (data: TooltipItem<any>) => {
                        if (typeof data.raw === "number") {
                            return data.dataset.label + ":\t\t" + Formatter.formatCurrency(data.raw as number);
                        }
                        return "-";
                    }
                }
            }

        }
    };


    public static getPriceSettings(numDigits: number): ChartOptions {
        return {
            elements: {
                point: {
                    radius: 0
                }
            },
            scales: {
                y: {
                    display: false,
                    grid: {
                        display: false
                    },
                    ticks: {
                        display: false
                    }
                },
                x: {
                    display: false,
                    grid: {
                        display: false
                    },
                    ticks: {
                        display: false
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: true,
            interaction: {
                intersect: false,
                mode: 'index',
            },
            plugins: {
                legend: {
                    position: 'top'
                },
                tooltip: {
                    callbacks: {
                        label: (data: TooltipItem<any>) => {
                            if (typeof data.raw === "number") {
                                return data.dataset.label + ":\t\t" + Formatter.formatCurrencyWithDigits(data.raw as number, numDigits);
                            }
                            return "-";
                        }
                    }
                }

            }
        };
    }

    public static getChartColors(palette: string, setsCount: number) {

        /*Gradients
          The keys are percentage and the values are the color in a rgba format.
          You can have as many "color stops" (%) as you like.
          0% and 100% is not optional.*/
        let gradient: any;
        switch (palette) {
            case 'cool':
                gradient = {
                    0: [255, 255, 255, 1],
                    20: [220, 237, 200, 1],
                    45: [66, 179, 213, 1],
                    65: [26, 39, 62, 1],
                    100: [0, 0, 0, 1]
                };
                break;
            case 'warm':
                gradient = {
                    0: [255, 255, 255, 1],
                    15: [220, 210, 101, 1],
                    30: [180, 140, 90, 1],
                    45: [160, 100, 70, 1],
                    60: [130, 80, 60, 1],
                    75: [100, 60, 40, 1],
                    90: [50, 40, 30, 1],
                    100: [20, 10, 5, 1]
                };
                break;
            case 'neon':
                gradient = {
                    0: [255, 255, 255, 1],
                    20: [255, 236, 179, 1],
                    45: [232, 82, 133, 1],
                    65: [106, 27, 154, 1],
                    100: [0, 0, 0, 1]
                };
                break;
        }

        //Get a sorted array of the gradient keys
        const gradientKeys = Object.keys(gradient);
        gradientKeys.sort(function (a, b) {
            return +a - +b;
        });

        //Calculate colors
        let chartColors = [];
        for (let i: number = 0; i < setsCount; i++) {
            let gradientIndex = (i + 1) * (100 / (setsCount + 1)); //Find where to get a color from the gradient
            for (let j: number = 0; j < gradientKeys.length; j++) {
                let gradientKey: any = gradientKeys[j];
                if (gradientIndex === +gradientKey) { //Exact match with a gradient key - just get that color
                    chartColors[i] = 'rgba(' + gradient[gradientKey].toString() + ')';
                    break;
                } else if (gradientIndex < +gradientKey) { //It's somewhere between this gradient key and the previous
                    let prevKey: any = gradientKeys[j - 1];
                    let gradientPartIndex = (gradientIndex - prevKey) / (gradientKey - prevKey); //Calculate where
                    let color = [];
                    for (let k: number = 0; k < 4; k++) { //Loop through Red, Green, Blue and Alpha and calculate the correct color and opacity
                        color[k] = gradient[prevKey][k] - ((gradient[prevKey][k] - gradient[gradientKey][k]) * gradientPartIndex);
                        if (k < 3) color[k] = Math.round(color[k]);
                    }
                    chartColors[i] = 'rgba(' + color.toString() + ')';
                    break;
                }
            }
        }

        return chartColors;
    }


    public static hardCodedPalette(length: number, type: "light" | "dark") {

        if (type === "light") {
            return ["#6929c4", "#1192e8", "#005d5d", "#9f1853", "#fa4d56", "#570408", "#198038", "#002d9c", "#ee538b", "#b28600", "#009d9a", "#012749", "#8a3800", "#a56eff"].slice(0, length);
        } else if (type === "dark") {
            return ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"].slice(0, length);
        } else {
            return [];
        }

    }


    public static setHeaderAttributes(field: string, rawData: Timed[], self: any, multiplier?: number) {

        const multiplierNormalized = multiplier === undefined ? 1 : multiplier;

        if (!rawData || rawData.length < 2) {
            self.setState({
                currentDiff: <p>"Zu wenige Daten"</p>,
                currentPrice: <p>-</p>,
                borderColor: "rgb(0, 128, 0, 0.8)",
                backgroundColor: "rgb(0, 128, 0, 0.1)"
            });
            return;
        }

        const lastValue = rawData[rawData.length - 1];
        // @ts-ignore
        const lastPrice = lastValue[field] as number * multiplierNormalized;

        const firstValue = rawData[0];
        // @ts-ignore
        const firstPrice = firstValue[field] as number * multiplierNormalized;
        const diffAbsolute: number = lastPrice - firstPrice;
        const diffRelative: string = Formatter.formatPercent(((lastPrice / firstPrice) - 1) * 100);

        const icon = diffAbsolute > 0 ? <Icon icon={faArrowUp}/> : <Icon icon={faArrowDown}/>;
        self.setState({
            currentDiff: <p
                style={ChartHelper.getStyle(diffAbsolute)}>{icon} {Formatter.formatCurrency(diffAbsolute)} ({diffRelative})</p>,
            currentPrice: <h2 style={{margin: 0, padding: 0}}>{Formatter.formatCurrency(lastPrice)}</h2>,
            borderColor: diffAbsolute < 0 ? "rgb(255, 0, 0, 0.8)" : "rgb(0, 128, 0, 0.8)",
            backgroundColor: diffAbsolute < 0 ? "rgb(255, 0, 0, 0.1)" : "rgb(0, 128, 0, 0.1)",
        })
    }


    private static getStyle(diffAbsolute: number) {
        if (diffAbsolute > 0) {
            return {color: "green", margin: 0, padding: 0};
        } else if (diffAbsolute < 0) {
            return {color: "red", margin: 0, padding: 0};
        } else {
            return {margin: 0, padding: 0};
        }
    }

    public static getDoughnutOptions(): ChartOptions<"doughnut"> {
        // @ts-ignore
        return {
            cutout: "70%",
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false
                }
            }
        };
    }


}