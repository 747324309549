import React from "react";
import {FormInstance, Space, Tabs} from "antd";
import {InstrumentPlan} from "../../../data/Instrument";
import {LoadingView} from "../../../frame/components/base/LoadingView";
import {InstrumentApi} from "../../../apis/InstrumentApi";
import SaveButton from "../../../frame/components/SaveButton";
import {InstrumentPlanDescriptionView} from "./InstrumentPlanDescriptionView";
import {InstrumentPlanAllocationTab} from "./InstrumentPlanAllocationTab";
import {InstrumentPlanChartsView} from "./InstrumentPlanChartsView";
import {InstrumentExcecutionTab} from "./InstrumentExcecutionTab";
import {InstrumentPlanRebalancingTab} from "./InstrumentPlanRebalancingTab";

interface State {
    loading: boolean,
    plan: InstrumentPlan
}

interface Props {
}

export class InstrumentPlanView extends LoadingView<Props, State> {

    protected formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            plan: {
                id: "",
                description: "",
                totalPerMonth: 1000,
                totalAlreadyInvested: 20000,
                instruments: []
            }
        }
    }


    async componentDidMount() {
        const plan = await InstrumentApi.getPlan();
        plan.instruments.forEach(value => value.buyIn = value.instrument.lastPrice)
        this.setState({plan})
        this.formRef.current?.setFieldsValue(plan);
        this.updateTotalPerMonth(plan.totalPerMonth);
    }

    render() {
        return <div style={{margin: "10px"}}>
            <h1>Investitionsplan</h1>
            <Space>
                <SaveButton onClick={() => this.handleSave()} loading={this.state.loading}/>
            </Space>
            <Tabs>
                <Tabs.TabPane tab="Allokation" key={1} disabled={this.state.loading}>
                    <InstrumentPlanAllocationTab loading={this.state.loading}
                                                 plan={this.state.plan} parent={this}/>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Übertragung" key={2} disabled={this.state.loading}>
                    <InstrumentExcecutionTab loading={this.state.loading}
                                             plan={this.state.plan} parent={this}/>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Statistiken" key={3} disabled={this.state.loading}>
                    <InstrumentPlanChartsView loading={this.state.loading}
                                              plan={this.state.plan} parent={this}/>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Beschreibung" key={4} disabled={this.state.loading}>
                    <InstrumentPlanDescriptionView loading={this.state.loading} plan={this.state.plan} parent={this}/>
                </Tabs.TabPane>


                <Tabs.TabPane tab="Rebalancing" key={5} disabled={this.state.loading}>
                    <InstrumentPlanRebalancingTab loading={this.state.loading}
                                                  plan={this.state.plan} parent={this}/>
                </Tabs.TabPane>
            </Tabs>
        </div>;
    }

    public updateTotalPerMonth(newValue: number) {
        this.state.plan.totalPerMonth = newValue;
        this.state.plan.instruments.forEach(value => {
            value.actualExecAmount = (value.percent / 100) * this.state.plan.totalPerMonth
        })
        this.setState({plan: this.state.plan})
    }

    private handleSave() {
        InstrumentApi.putPlan(this.state.plan);
    }
}