export class EnvironmentHandler {

    //Test
    public static isDev(): boolean {
        return process.env.NODE_ENV === "development";
    }

    public static isProd(): boolean {
       return !this.isDev();
    }
}