import React from "react";
import {Col, FormInstance, InputNumber, Row, Table} from "antd";
import {InstrumentPlanTableBase, InstrumentPlanTableBaseProps} from "./InstrumentPlanTableBase";
import {InstrumentPlanElement} from "../../../data/Instrument";
import Column from "antd/es/table/Column";
import Formatter from "../../../global/Formatter";

interface State {
    renderModal: boolean,
    additionalAmount: number
}

interface Props extends InstrumentPlanTableBaseProps {

}

export class InstrumentPlanRebalancingTab extends InstrumentPlanTableBase<Props, State> {

    protected formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {
            renderModal: false,
            additionalAmount: this.props.plan.totalPerMonth
        }
        this.recalculate(this.state.additionalAmount);
    }

    render() {
        return <div style={{margin: "10px"}}>
            {this.renderTotalInput()}
            {this.renderList()}
        </div>;
    }


    renderTotalInput() {
        return <Row gutter={[16, 16]} style={{padding: "10px"}}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                Zusätzliches Kapital (€):
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                <InputNumber value={this.state.additionalAmount} style={{width: "100%"}} min={0} step={1E-2}
                             decimalSeparator="," onChange={value => this.recalculate(value)}/>
            </Col>
        </Row>
    }

    protected renderColumns() {
        return <>
            {this.renderNameColumn()}
            <Column title="Zu kaufende Summe" key="instrument.rebalanceAmount'"
                    render={(value, record) => {
                        // @ts-ignore
                        return <>{Formatter.formatCurrency(record["rebalanceAmount"])}</>
                    }}/>

        </>;
    }

    protected renderSummaryRow(data: InstrumentPlanElement[]): React.ReactNode {

        const additional = this.props.plan.instruments.reduce((a, b) => a + (b.rebalanceAmount || 0), 0);

        return <Table.Summary.Row style={{fontWeight: "bold"}}>
            <Table.Summary.Cell index={0}>Gesamt</Table.Summary.Cell>
            <Table.Summary.Cell index={2}>{Formatter.formatCurrency(additional)}</Table.Summary.Cell>
        </Table.Summary.Row>;
    }


    //TODO: Groupings....

    private recalculate(additionalAmount: number) {
        const plan = this.props.plan;

        this.props.plan.instruments.forEach(i => {
            const expectedValue = (plan.totalAlreadyInvested + additionalAmount) * (i.percent / 100);
            i.rebalanceAmount = expectedValue - i.currentValue;
        })

        this.setListState(plan);
        this.setState({additionalAmount})

    }


}