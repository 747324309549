import React from "react";
import Formatter from "../../global/Formatter";
import Col from "antd/es/col";
import Row from "antd/es/row";

interface State {

}

interface Props {
    totalIncome: number,
    totalExpenses: number
}

export class BalanceStatisticsPanel extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return <Row style={{margin: "10px"}}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                <div className="price-style-statistics income-color">
                    Einnahmen: {" " + Formatter.formatCurrency(this.props.totalIncome)}
                </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                <div className="price-style-statistics expenses-color">
                    Ausgaben: {" " + Formatter.formatCurrency(this.props.totalExpenses)}
                </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                <div
                    className={`price-style-statistics ${this.props.totalIncome - this.props.totalExpenses > 0 ? "income-color" : "expenses-color"}`}>
                    Bilanz: {" " + Formatter.formatCurrency(this.props.totalIncome - this.props.totalExpenses)}
                </div>
            </Col>
        </Row>;
    }


}

