import {LoadingView, LoadingViewState} from "./base/LoadingView";
import {FileUtils} from "../../global/FileUtils";
import {Image} from "antd";
import {File} from "../../data/File";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {faFile} from "@fortawesome/free-solid-svg-icons/faFile";

interface State extends LoadingViewState {
    imageUrl: string
}

interface Props {
    file: File
    previewable: boolean
    width?: number
}


export class FilePreview extends LoadingView<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: this.isPicture(),
            imageUrl: ""
        }
    }

    async componentDidMount() {
        if (this.isPicture()) {
            this.setState({imageUrl: await FileUtils.getObjectUrlForFile(this.props.file), loading: false});
        }
    }

    public render() {
        if (this.isPicture()) {
            return this.loadable(<Image src={this.state.imageUrl} preview={this.props.previewable} width={this.props.width}/>, 360);
        } else {
            return <FontAwesomeIcon style={{width: "100%"}} size={"5x"} icon={faFile}/>;
        }
    }

    private isPicture() {
        return FileUtils.isPicture(this.props.file);
    }
}