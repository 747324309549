import {Instrument, InstrumentTransaction} from "../../data/Instrument";
import React from "react";
import {Button, DatePicker, Form, FormInstance, InputNumber, message} from "antd";
import {Constants} from "../../frame/Constants";
import {InstrumentCard} from "./InstrumentCard";
import {InstrumentApi} from "../../apis/InstrumentApi";
import DateHelper from "../../global/DateHelper";
import {InstrumentRenderingHelper} from "./InstrumentRenderingHelper";

interface QuickAdd extends InstrumentTransaction {
    totalBuyInPrice: number
}

interface State {
    instrument?: Instrument
    renderModal: boolean
}

interface Props {
}


export class InstrumentQuickAdd extends React.Component<Props, State> {

    private formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {
            renderModal: false
        }
    }


    render() {
        return <>
            <h1>Quick-Add (alpha)</h1>
            <Form
                ref={this.formRef}
                name="basic"
                labelCol={Constants.LABEL_COL}
                wrapperCol={Constants.WRAPPER_COL}
            >
                {this.state.renderModal ? this.renderModal() : null}
                <Form.Item
                    label="Instrument"
                >
                    <InstrumentCard hoverable instrument={this.state.instrument}
                                    onItemSelected={item => this.setState({renderModal: true})}/>
                </Form.Item>
                <Form.Item
                    label="Datum"
                    name="dateAsMoment"
                    required
                >
                    <DatePicker style={{width: "100%"}} format="DD.MM.yyyy"/>
                </Form.Item>
                <Form.Item
                    label="Preis pro Stück (€)"
                    name="buyInPrice"
                    rules={[{required: true, message: 'Bitte Preis ausfüllen!'}]}
                >
                    <InputNumber style={{width: "100%"}} min={0} step={1E-4} decimalSeparator=","/>
                </Form.Item>

                <Form.Item
                    label="Preis Gesamt (€)"
                    name="totalBuyInPrice"
                    rules={[{required: true, message: 'Bitte Preis ausfüllen!'}]}
                >
                    <InputNumber style={{width: "100%"}} min={0} step={1E-4} decimalSeparator=","/>
                </Form.Item>
                <Button onClick={event => this.anlegen()}>Anlegen</Button>
            </Form>
        </>

    }

    private renderModal() {
        return InstrumentRenderingHelper.renderSelectionModal(() => this.setState({renderModal: false}),
            item => this.setState({
                instrument: item,
                renderModal: false
            }), this.state.instrument?.isin);
    }

    private anlegen() {
        const instrument = this.state.instrument;
        if (!instrument) {
            message.info("Bitte Instrument auswählen!");
            return;
        }

        const transaction: QuickAdd = this.formRef.current?.getFieldsValue();
        transaction.instrument = instrument;
        transaction.amount = parseFloat((transaction.totalBuyInPrice / transaction.buyInPrice).toFixed(4));
        transaction.date = DateHelper.fromMomentDate(transaction.dateAsMoment) || "";
        transaction.description = "Erstellt mit QuzickAdd Funktion am " + new Date();


        InstrumentApi.postTransaction(transaction);


        this.formRef.current!.setFieldValue("buyInPrice", undefined);
        // @ts-ignore
        //this.formRef.current!.setFieldValue("dateAsMoment", transaction.dateAsMoment.add({month: 1}));


    }
}