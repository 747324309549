import {PreciousMetalStatistic} from "../data/Statistic";
import Rest from "./Rest";
import {DistributionPerCategory} from "../data/statistics/DistributionPerCategory";
import {AmountsPerMonthEntry} from "../data/statistics/AmountsPerMonthEntry";
import {TopIncomesExpenses} from "../data/statistics/TopIncomesExpenses";
import {TotalIncomeExpense} from "../data/statistics/TotalIncomeExpense";
import {TotalAssetValues} from "../data/statistics/TotalAssetValues";
import {AssetAllocationLabelValuePayload} from "../data/statistics/LabelValuePayload";


class StatisticApi {

    public static getPreciousMetalTotalPrice(): Promise<PreciousMetalStatistic> {
        return Rest.getSpecific(`/precious-metals/statistics`);
    }

    public static getDistributedPerCategory(from: string, to: string): Promise<DistributionPerCategory> {
        return Rest.getSpecific(`/statistics/distribution-per-category?from=${from}&to=${to}`);
    }

    public static getAmountsPerMonth(from: string, to: string): Promise<AmountsPerMonthEntry[]> {
        return Rest.getMultiple(`/statistics/amounts-per-month?from=${from}&to=${to}`);
    }

    public static getTopIncomesExpenses(from: string, to: string): Promise<TopIncomesExpenses> {
        return Rest.getSpecific(`/statistics/top-incomes-expenses?from=${from}&to=${to}`);
    }

    public static getTotalIncomesExpenses(from: string, to: string): Promise<TotalIncomeExpense> {
        return Rest.getSpecific(`/statistics/total-incomes-expenses?from=${from}&to=${to}`);
    }

    public static getTotalAssetValues(to: string): Promise<TotalAssetValues> {
        return Rest.getSpecific(`/statistics/total-asset-values?to=${to}`);
    }

    public static getAssetAllocation(): Promise<AssetAllocationLabelValuePayload[]> {
        return Rest.getMultiple(`/statistics/asset-allocation`);
    }

}

export default StatisticApi;