import {Account} from "../data/Account";
import Rest from "./Rest";
import {PasswordChange} from "../pages/external/PasswordReset";

class MyAccountApi {

    public static get(): Promise<Account> {
        return Rest.getSpecific("/accounts/me");
    }

    public static put(value: Account): Promise<Account> {
        return Rest.put(value, "/accounts/me");
    }

    public static delete(): Promise<any> {
        return Rest.delete("/accounts/me");
    }

    public static post(oAuthId ?: string): Promise<any> {
        return Rest.post(null, `/accounts/me?oauth-id=${oAuthId}`);
    }

    public static async requestNewPassword(username: string): Promise<any> {
        return Rest.post(null, `/accounts/me/password/change-mail?username=${username}`);
    }

    public static changePassword(value: PasswordChange) : Promise<any> {
        return Rest.put(value, `/accounts/me/password/change-mail`);
    }
}

export default MyAccountApi;