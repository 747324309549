import React from "react";
import Input from 'antd/es/input';
import {Config} from "../../frame/Config";
import Icon from "../../frame/components/Icon";
import {Category} from "../../data/Category";
import CategoryApi from "../../apis/CategoryApi";
import {Select} from "antd";
import {Option} from "antd/es/mentions";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Button from "antd/es/button";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";


interface State {
    quickAdds: QuickAdd[],
    categories: Category[]
}

interface Props {

}

interface QuickAdd {
    id: string
    designation?: string,
    categoryId: string
}

class QuickAddView extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            quickAdds: [],
            categories: []
        }
    }

    async componentDidMount() {
        console.log(process.env.NODE_ENV);
        const categories = await CategoryApi.getAll("");
        this.setState({categories, quickAdds: QuickAddView.loadQuickAdds()})
    }

    render() {

        return <>
            <section style={{margin: 10}}>
                <Button icon={<Icon icon={faPlus}/>} onClick={() => this.addNewQuickAddItem()}>Hinzufügen</Button>
                {this.renderQuickAdds()}
            </section>
        </>;
    }

    private addNewQuickAddItem() {
        const state = this.state.quickAdds;
        state.push({
            id: "" + Math.random(),
            categoryId: "",
            designation: ""
        });
        Config.persist("quickAdds", JSON.stringify(state));
        this.setState({quickAdds: QuickAddView.loadQuickAdds()});
    }


    public static loadQuickAdds(): QuickAdd[] {
        let quickAddData = Config.getString("quickAdds");
        if (quickAddData === "") {
            Config.persist("quickAdds", "[]");
            quickAddData = Config.getString("quickAdds");
        }

        return JSON.parse(quickAddData) as QuickAdd[];
    }

    private renderQuickAdds() {
        return <>
            {this.state.quickAdds.map(value => {
                return <Row style={{marginBottom: 5, marginTop: 5}}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        <Input value={value.designation}
                               onChange={event => this.updateQuickAddField(value.id, event.target.value, value.categoryId)}/>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
                        <Select showSearch
                                value={value.categoryId}
                                optionFilterProp="children"
                                style={{width: "100%"}}
                                onChange={event => this.updateQuickAddField(value.id, value.designation || "", event)}>
                            {this.state.categories.map(value =>
                                <Option key={value.id} value={value.id}>{value.designation}</Option>)}
                        </Select>
                    </Col>
                    <Col xs={24} sm={24} md={2} lg={2} xl={2} xxl={2}>
                        <Button style={{width: "100%"}} icon={<Icon icon={faTrash}/>}
                                onClick={event => this.removeQuickAddItem(value.id)}/>
                    </Col>
                </Row>
            })}
        </>;
    }

    private updateQuickAddField(id: string, designation: string, categoryId: string) {

        const state = this.state.quickAdds;
        state.filter(value => value.id === id).forEach(value => {
            value.designation = designation;
            value.categoryId = categoryId;
        })
        Config.persist("quickAdds", JSON.stringify(state));
        this.setState({quickAdds: QuickAddView.loadQuickAdds()});
    }

    private removeQuickAddItem(id: string) {
        const state = this.state.quickAdds.filter(value => value.id !== id);
        Config.persist("quickAdds", JSON.stringify(state));
        this.setState({quickAdds: QuickAddView.loadQuickAdds()});
    }
}

export default QuickAddView;