import React from "react";
import {Button, Table} from "antd";
import {LoadingView} from "../../frame/components/base/LoadingView";
import TextArea from "antd/es/input/TextArea";

import Papa from "papaparse";
import moment from "moment";
import Column from "antd/es/table/Column";
import Formatter from "../../global/Formatter";
import {InstrumentApi} from "../../apis/InstrumentApi";
import DateHelper from "../../global/DateHelper";
import {InstrumentTransaction} from "../../data/Instrument";

interface Entry {
    amount: number,
    date: moment.Moment,
    isin: string,
    price: number,
    shares: number

}


interface State {
    loading: boolean,
    entries: Entry[]
}


interface Props {
}

export class InstrumentTransactionsScalable extends LoadingView<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            entries: []
        }
    }

    render() {
        return <div style={{margin: "10px"}}>
            <h1>Scalable-CSV-Import</h1>
            <Button onClick={() => this.handleSave()}>Save</Button>
            <TextArea onChange={e => this.onChange(e.target.value)}/>
            <Table
                loading={this.state.loading}
                dataSource={this.state.entries}
                pagination={false}
                bordered
                size="small"
                style={{marginBottom: "10px"}}
            >
                <Column title="ISIN" dataIndex={["isin"]} key="isin"
                        render={(value, record) => {
                            return <>
                                {value}
                            </>
                        }}/>
                <Column title="Betrag" dataIndex={["amount"]} key="amount"
                        render={(value, record) => {
                            return <>
                                {Formatter.formatCurrency(value)}
                            </>
                        }}/>
                <Column title="Preis" dataIndex={["price"]} key="price"
                        render={(value, record) => {
                            return <>
                                {Formatter.formatCurrency(value)}
                            </>
                        }}/>
                <Column title="Anteile" dataIndex={["shares"]} key="shares"
                        render={(value, record) => {
                            return <>
                                {value}
                            </>
                        }}/>
                <Column title="Datum" dataIndex={["date"]} key="date"
                        render={(value, record) => {
                            return <>
                                {Formatter.formatDate(value)}
                            </>
                        }}/>
            </Table>
        </div>;
    }


    /*
    description: string;
     */

    private async handleSave() {
        this.state.entries.map(async value => {
            return {
                id: "",
                amount: value.shares,
                buyInPrice: value.price,
                type: "BUY",
                date: DateHelper.fromMomentDate(value.date),
                description: "",
                instrument: (await InstrumentApi.queryInstruments(value.isin))[0]
            } as InstrumentTransaction
        }).forEach(async value => InstrumentApi.postTransaction(await value));
    }

    private onChange(value: string) {
        var result = Papa.parse(value, {delimiter: ";", header: true, skipEmptyLines: true});


        let res: Entry[] = result.data.map((raw: any) => {
            return {
                isin: raw.isin,
                shares: this.number( raw.shares),
                price: this.number(raw.price),
                amount: this.number(raw.amount),
                date: moment(raw.date, 'YYYY-MM-DD')
            }
        })
        this.setState({entries: res})

    }

    private number(value: string) {
        value = value.replaceAll(".", '_');
        value = value.replaceAll(",", ".");
        value = value.replaceAll("_", ",");
        return Number(value);
    }
}