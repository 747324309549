import Rest from "./Rest";
import RecurringTransactionEntry from "../data/RecurringTransactionEntry";

export default class RecurringTransactionsApi {

    static queryEntries(query?: string, parentCategoryId?: string): Promise<RecurringTransactionEntry[]> {
        return Rest.getMultiple(`/recurring-transaction-entries?query=${query}&parentCategoryId=${parentCategoryId}`);
    }

    static getEntry(entryId?: string): Promise<RecurringTransactionEntry> {
        return Rest.getSpecific(`/recurring-transaction-entries/${entryId}`)
    }

    static postEntry(value: RecurringTransactionEntry): Promise<RecurringTransactionEntry> {
        return Rest.post(value, `/recurring-transaction-entries`);
    }

    static putEntry(entryId: string, value: RecurringTransactionEntry): Promise<RecurringTransactionEntry> {
        return Rest.put(value, `/recurring-transaction-entries/${entryId}`)
    }

    static deleteEntry(entryId: string) {
        return Rest.delete(`/recurring-transaction-entries/${entryId}`)
    }
}
