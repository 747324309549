import React from 'react';
import {Form, FormInstance, InputNumber} from "antd";
import {Constants} from "../../frame/Constants";
import Button from "antd/es/button";


interface TrackSection {
    name: string,
    length: number,
    amount: number
}

interface State {
    consumptionPer100km: number,
    pricePerLiter: number,
    tracks: TrackSection[]
}

interface Props {
}

export class FuelcostCalculator extends React.Component<Props, State> {

    private formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {
            consumptionPer100km: 6,
            pricePerLiter: 1.7,
            tracks: []
        }
    }

    componentDidMount() {
        this.formRef!.current?.setFieldsValue({
            consumptionPer100km: 6,
            pricePerLiter: 1.7
        });
    }

    render() {

        return <Form
            ref={this.formRef}
            name="basic"
            labelCol={Constants.LABEL_COL}
            wrapperCol={Constants.WRAPPER_COL}
        >
            <Form.Item
                label="Verbrauch je 100km"
                name="consumptionPer100km"
                required
            >
                <InputNumber step={0.01} decimalSeparator="," style={{width: "100%"}}/>
            </Form.Item>
            <Form.Item
                label="Preis pro Liter"
                name="pricePerLiter"
                required
            >
                <InputNumber step={0.01} decimalSeparator="," style={{width: "100%"}}/>
            </Form.Item>
            <Form.Item
                label="Streckenabschnitte"
                name="s"
                required
            >
                {this.renderSectionsInput()}
            </Form.Item>
        </Form>
    }

    private renderSectionsInput() {
        return <>
            <Button>Hinzufügen</Button>
            {this.state.tracks.map(value => {
                return "";
            })}
        </>;
    }
}