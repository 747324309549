import Rest from "./Rest";
import {Level, LogEntry} from "../data/Admin";

export default class AdminApi {

    public static async queryLogs(allowedLevels: Level[], textSearch?: string, from?: number, to?: number): Promise<LogEntry[]> {
        return Rest.getMultiple(`/administration/logs?levels=${allowedLevels.join(",") || ""}&textSearch=${textSearch || ""}&from=${from || ""}&to=${to || ""}`);
    }
    public static async getLog(id: string): Promise<LogEntry> {
        return Rest.getSpecific(`/administration/logs/${id}`);
    }
}