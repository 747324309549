import React from 'react';
import {Col, Collapse, Row} from "antd";
import DateHelper from "../../global/DateHelper";
import {AssetDistributionWidget} from "./AssetDistributionWidget";
import DistributedPerCategoryChartWidget from "./DistributedPerCategoryChartWidget";
import TotalIncomePerMontWidget from "./TotalIncomePerMontWidget";
import {TotalsWidget} from "./TotalsWidget";
import {RangePicker} from "../../frame/components/RangePicker";
import {Config} from "../../frame/Config";
import Formatter from "../../global/Formatter";
import CollapsePanel from "antd/es/collapse/CollapsePanel";

interface State {
    from: string,
    to: string
}

interface Props {

}

class StatisticsOverviewPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            from: DateHelper.getCurrentYearRange().from,
            to: DateHelper.getCurrentYearRange().to
        }
    }

    render() {
        return <>
            <Collapse style={{margin: "10px"}}
                      defaultActiveKey={Config.budgetBookDetailsOpenedByDefault() ? 1 : undefined}>
                <CollapsePanel key={1} header={
                    `Zeitraum: ${Formatter.formatDate(this.state.from)} - ${Formatter.formatDate(this.state.to)}`
                }>
                    <Row style={{margin: "10px"}}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} style={{margin: "2px"}}>
                            <RangePicker parent={this}/>
                        </Col>
                    </Row>
                </CollapsePanel>
            </Collapse>
            {this.renderWidgets()}
        </>
    }

    private renderWidgets() {
        return <>
            <Row gutter={[16, 16]} style={{padding: "10px"}}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <TotalsWidget from={this.state.from} to={this.state.to}/>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <AssetDistributionWidget from={this.state.from} to={this.state.to}/>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <DistributedPerCategoryChartWidget from={this.state.from} to={this.state.to}/>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <TotalIncomePerMontWidget from={this.state.from} to={this.state.to}/>
                </Col>
            </Row>
        </>;
    }
}

export default StatisticsOverviewPage;