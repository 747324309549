import {Redirect, RouteComponentProps} from "react-router-dom";
import React, {ReactNode} from "react";
import {Col, Form, FormInstance, Input, PageHeader} from "antd";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {LoadingView, LoadingViewState} from "./LoadingView";
import AppendedFiles from "../AppendedFiles";
import {File} from "../../../data/File";
import Row from "antd/es/row";
import JoditEditor from "jodit-react";

export interface DetailViewState extends LoadingViewState {
    entryId: string
    redirectOverview?: boolean
    redirectEditPage?: boolean
}

export interface DetailViewProps extends RouteComponentProps<{ id: string }> {

}

export abstract class DetailView<PROPS extends DetailViewProps, STATE extends DetailViewState> extends LoadingView<PROPS, STATE> {

    protected formRef = React.createRef<FormInstance>();

    protected constructor(props: PROPS) {
        super(props);
    }

    render(): ReactNode {

        if (this.state.redirectOverview) {
            return <Redirect to={this.redirectTarget()} push/>
        }

        if (this.state.redirectEditPage) {
            this.setState({redirectEditPage: false});
            return <Redirect to={`${this.redirectTargetEditPage()}/${this.state.entryId}`}/>
        }

        return this.renderContent();

    }

    protected renderHeader() {

        if (this.state.loading) {
            return <>
                <Skeleton height={50} inline style={{marginBottom: 20}}/>
            </>
        } else {
            return <PageHeader
                onBack={() => this.setState({redirectOverview: true})}
                title={this.isNew() ? "Eintrag erstellen" : "Eintrag bearbeiten"}
            />
        }

    }

    protected abstract renderContent(): ReactNode;

    protected abstract redirectTarget(): string;

    protected redirectTargetEditPage(): string {
        return this.redirectTarget();
    }


    public isNew() {
        return this.state.entryId === 'new';
    }

    //Default Form Renderers:

    protected renderDesignation() {
        return <Form.Item
            label="Bezeichnung"
            name="designation"
            rules={[{required: true, message: 'Bitte Bezeichnung ausfüllen!'}]}
        >
            {this.loadable(<Input autoFocus/>)}
        </Form.Item>;
    }

    protected renderDescription() {
        return <Form.Item
            label="Details"
            name="description"
        >
            {this.loadable(
                <JoditEditor
                    value=""
                    config={DetailView.getJoditConfig()}
                />, 120)}
        </Form.Item>;
    }

    protected renderAppendedFiles(files: File[], parent: React.Component<any, { files: File[] }>) {
        return <Form.Item
            label="Dateianhänge"
        >
            {this.loadable(<AppendedFiles files={files} parent={parent}/>, 47)}
        </Form.Item>;
    }

    protected renderEntryAttribute(key: string, value?: ReactNode, loadableHeight?: number) {
        return this.loadable(<Row gutter={[16, 16]} style={{padding: "10px"}}>
            <Col xs={24} sm={6} md={6} lg={6} xl={5} xxl={4}>
                {key}:
            </Col>
            <Col xs={24} sm={18} md={18} lg={18} xl={19} xxl={20}>
                {value}
            </Col>
        </Row>, loadableHeight);
    }

    //https://github.com/xdan/jodit/blob/master/src/config.ts#L106
    public static getJoditConfig(height?: string | number) {

        const buttons = ["bold", "italic", "underline", "strikethrough", "|", "ul", "ol", "paragraph", "|", "fullsize", "table", "link"];

        return {
            buttons: buttons,
            buttonsMD: buttons,
            buttonsSM: buttons,
            buttonsXS: buttons,
            toolbarAdaptive: false,
            height: height || 400
        };
    }

}