import React from "react";
import {InstrumentPlan} from "../../../data/Instrument";
import {InstrumentPlanView} from "./InstrumentPlanView";
import {ChartHelper} from "../../../frame/charts/ChartHelper";
import {Doughnut} from "react-chartjs-2";
import {ChartPlugins} from "../../../frame/charts/ChartPlugins";
import {FourSlotGrid} from "../../../frame/components/FourSlotGrid";

interface State {

}

interface Props {
    loading: boolean,
    plan: InstrumentPlan,
    parent: InstrumentPlanView
}

export class InstrumentPlanChartsView extends React.Component<Props, State> {

    render() {
        return <div style={{margin: "10px"}}>
            {this.renderCharts()}
        </div>;
    }


    private renderCharts() {
        return <FourSlotGrid firstElement={this.renderAllocationChart()} secondElement={this.renderIsAllocationChart()}
                             thirdElement={this.renderTotals()} fourthElement={<></>}/>
    }


    private renderTotals() {
        //TODO: Hinzufügen der Gewinn / Verluste, gesamtengagement usw...
        // this.props.plan.totalAlreadyInvested;
        return <>

        </>;
    }

    private renderAllocationChart() {

        const assetData = {
            labels: this.props.plan.instruments.map(value => {
                return value.instrument.shortName
            }),
            datasets: [
                {
                    data: this.props.plan.instruments.map(value => value.percent),
                    backgroundColor: ChartHelper.hardCodedPalette(this.props.plan.instruments.length, "light"),
                    fill: false,
                    borderWidth: 1
                }
            ]
        };


        return <Doughnut data={assetData}
                         options={ChartHelper.getDoughnutOptions()}
                         plugins={[ChartPlugins.getDoughnutInnerRendererPlugin("Soll-Verteilung", true)]}
        />;
    }

    private renderIsAllocationChart() {

        const assetData = {
            labels: this.props.plan.instruments.map(value => {
                return value.instrument.shortName
            }),
            datasets: [
                {
                    data: this.props.plan.instruments.map(value => value.currentPercent),
                    backgroundColor: ChartHelper.hardCodedPalette(this.props.plan.instruments.length, "light"),
                    fill: false,
                    borderWidth: 1
                }
            ]
        };


        return <Doughnut data={assetData}
                         options={ChartHelper.getDoughnutOptions()}
                         plugins={[ChartPlugins.getDoughnutInnerRendererPlugin("Ist-Verteilung", true)]}
        />;
    }
}