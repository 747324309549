import React from "react";
import {DetailEditViewProps} from "../../frame/components/base/DetailEditView";
import {BankingApi} from "../../apis/BankingApi";
import {DetailView, DetailViewProps, DetailViewState} from "../../frame/components/base/DetailView";
import {InstitutionAccount, InstitutionDetails} from "../../data/bankingapi/BankingApiModel";
import {Button, Card, Image, List, PageHeader} from "antd";


interface State extends DetailViewState {
    entry?: InstitutionDetails
}


export class ConnectedInstitutionDetailView extends DetailView<DetailViewProps, State> {

    constructor(props: DetailEditViewProps) {
        super(props);
        this.state = {
            loading: true,
            entryId: this.props.match.params.id
        }
    }

    async componentDidMount() {
        await this.update();
    }

    protected renderContent(): React.ReactNode {
        const entry = this.state.entry;
        return <>
            <PageHeader
                onBack={() => this.setState({redirectOverview: true})}
                title={"Details"}
            />
            {this.renderEntryAttribute("Bezeichnung", entry?.name, 30)}
            {this.renderEntryAttribute("BIC", entry?.bic, 30)}
            {this.renderEntryAttribute("Logo", <Image width={200} src={entry?.logo} alt={entry?.name}/>, 200)}
            {this.renderEntryAttribute("Status", <a target="_blank" rel="noreferrer"
                                                    href="https://nordigen.com/en/account_information_documenation/integration/statuses/">{entry?.connectionStatus}</a>, 30)}
            {this.renderEntryAttribute("Konten", this.renderAccounts(), 75)}
            <Button onClick={() => this.evictCache()}>Aktuelle Daten laden</Button>
        </>
    }

    private renderAccounts() {

        return <List dataSource={this.state.entry?.accounts}
                     style={{width: "50%"}}
                     renderItem={(item: InstitutionAccount) => (
                         this.loadableMargin(
                             <Card hoverable
                                   size="small"
                                   style={{margin: "10px"}}
                                   onClick={() => this.onAccountSelected(item)}>
                                 {`${item.ownerName} (${item.name})`}
                             </Card>, 64, "10px"
                         )
                     )}
        >
        </List>;

    }

    private async onAccountSelected(item: InstitutionAccount) {
        const details = await BankingApi.getAccountTransactions(item.accountId);
        console.log(details);
    }

    protected async update() {
        this.setState({loading: true});
        const details = await BankingApi.getInstitutionDetails(this.state.entryId);
        this.setState({entry: details, loading: false});
    }

    protected redirectTarget(): string {
        return "/banking-api/connected";
    }

    private async evictCache() {
        this.setState({loading: true});
        await BankingApi.evictCache(this.state.entryId);
        this.update();
    }
}