//


import React from "react";
import {ConfigProvider, List} from "antd";
import {InstrumentApi} from "../../apis/InstrumentApi";
import Search from "antd/es/input/Search";
import {Instrument} from "../../data/Instrument";
import {LoadingView} from "../../frame/components/base/LoadingView";
import NoData from "../../global/NoData";
import {InstrumentCard} from "./InstrumentCard";

interface State {
    loading: boolean,
    query: string,
    instruments: Instrument[],
}

interface Props {
    onItemSelected: (item?: Instrument) => void;
    defaultQuery?: string
}

export class InstrumentSearchView extends LoadingView<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            query: "",
            instruments: []
        }
    }

    async componentDidMount() {
        if (this.props.defaultQuery) {
            this.onSearch(this.props.defaultQuery);
        }
    }

    render() {
        return <>
            <Search
                autoFocus
                size="middle"
                disabled={this.state.loading}
                enterButton
                onChange={event => this.onSearch(event.target.value)}
                onSearch={() => this.onSearch(undefined)}
                placeholder="Suche"
                value={this.state.query}
                loading={this.state.loading}
            />
            {this.renderList()}
        </>;
    }

    private renderList() {
        return <ConfigProvider renderEmpty={NoData.getNoDataDisplay()}>
            <List dataSource={this.state.instruments}
                  renderItem={(item: any) => (
                      this.loadableMargin(this.renderItem(item))
                  )}
            >
            </List>
        </ConfigProvider>;
    }

    private renderItem(item: Instrument) {
        return <InstrumentCard hoverable instrument={item} onItemSelected={(item) => this.props.onItemSelected(item)}/>
    }

    private async onSearch(query?: string) {
        if (query !== undefined) {
            await this.setState({query});
        }
        const instruments = await InstrumentApi.queryInstruments(this.state.query);
        this.setState({instruments});
    }

}