import React from "react";
import AdminApi from "../../../apis/AdminApi";
import {LogEntry} from "../../../data/Admin";
import Formatter from "../../../global/Formatter";
import {DetailView, DetailViewProps, DetailViewState} from "../../../frame/components/base/DetailView";

interface State extends DetailViewState {
    entry?: LogEntry
}

interface Props extends DetailViewProps {

}

export class AdminLogsDetailView extends DetailView<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            entryId: this.props.match.params.id
        }
    }

    async componentDidMount() {
        const entry = await AdminApi.getLog(this.props.match.params.id);
        this.setState({entry, loading: false});
    }

    renderContent() {
        return <>
            {this.renderHeader()}
            {this.loadable(this.renderEntry())}
        </>
    }

    private renderEntry() {
        return <>
            {this.renderEntryAttribute("Datum", Formatter.formatDateTime(this.state.entry?.date))}
            {this.renderEntryAttribute("Level", this.state.entry?.level)}
            {this.renderEntryAttribute("Klassenname", this.state.entry?.className)}
            {this.renderEntryAttribute("Methode", this.state.entry?.method)}
            {this.renderEntryAttribute("Zeile", this.state.entry?.line + "")}
            {this.renderEntryAttribute("Nachricht", this.state.entry?.message)}
            {this.renderEntryAttribute("Stack-Trace", <div
                style={{whiteSpace: "pre-wrap"}}>{this.state.entry?.exception}</div>)}
        </>;
    }

    protected redirectTarget(): string {
        return "/admin/logs";
    }

}

