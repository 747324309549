import React from 'react';
import {Col, Row} from "antd";
import {ChartData} from "chart.js";
import StatisticApi from "../../apis/StatisticApi";
import {DistributionPerCategoryEntry} from "../../data/statistics/DistributionPerCategory";
import {ChartHelper} from "../../frame/charts/ChartHelper";
import {WidgetBase} from "./WidgetBase";

interface State {
    loading: boolean,
    incomeData: ChartData<"doughnut">,
    expenseData: ChartData<"doughnut">
}

interface Props {
    from: string,
    to: string
}

class DistributedPerCategoryChartWidget extends WidgetBase<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            expenseData: DistributedPerCategoryChartWidget.buildState([]),
            incomeData: DistributedPerCategoryChartWidget.buildState([])
        }
    }

    async componentDidMount() {
        await this.update();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.from !== prevProps.from || this.props.to !== prevProps.to) {
            await this.update();
        }
    }

    private async update() {
        this.setState({loading: true});
        const data = await StatisticApi.getDistributedPerCategory(this.props.from, this.props.to);
        this.setState({
            incomeData: DistributedPerCategoryChartWidget.buildState(data.incomes),
            expenseData: DistributedPerCategoryChartWidget.buildState(data.expenses)
        })
        this.setState({loading: false});
    }

    private static buildState(dataPoints: DistributionPerCategoryEntry[]): ChartData<"doughnut"> {
        return {
            labels: dataPoints.map(value => value.designation),
            datasets: [{
                data: dataPoints.map(value => value.value),
                backgroundColor: ChartHelper.hardCodedPalette(dataPoints.length, "light"),
                borderWidth: 1
            }]
        };
    }

    render() {

        return <>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    {this.renderDoughnut(this.state.incomeData, "Einnahmen")}
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    {this.renderDoughnut(this.state.expenseData, "Ausgaben")}
                </Col>
            </Row>
        </>
    }

}

export default DistributedPerCategoryChartWidget;