import Rest from "./Rest";
import {Instrument, InstrumentPlan, InstrumentTransaction} from "../data/Instrument";
import {Profit, SellOrder} from "../pages/instruments/InstrumentsSellView";

export class InstrumentApi {

    public static queryInstruments(query: string): Promise<Instrument[]> {
        return Rest.getMultiple(`/instruments?query=${query}`);
    }

    public static queryTransactions(query: string): Promise<InstrumentTransaction[]> {
        return Rest.getMultiple(`/instruments/transactions?query=${query}`)
    }

    public static async getTransaction(id: string): Promise<InstrumentTransaction> {
        return Rest.getSpecific(`/instruments/transactions/${id}`);
    }

    public static async deleteTransaction(id: string) {
        return Rest.delete(`/instruments/transactions/${id}`);
    }

    public static async postTransaction(entry: InstrumentTransaction) {
        return Rest.post(entry, `/instruments/transactions`);
    }

    public static async putTransaction(id: string, entry: InstrumentTransaction) {
        return Rest.put(entry, `/instruments/transactions/${id}`);
    }

    public static async sellTransactions(entry: SellOrder) {
        return Rest.post(entry, `/instruments/transactions/functions/sell`);
    }

    public static async checkSellTransactions(entry: SellOrder): Promise<Profit> {
        return Rest.save('POST', entry, `/instruments/transactions/functions/check-sell`, true);
    }


    public static async getPlan(): Promise<InstrumentPlan> {
        return Rest.getSpecific(`/instruments/me/plan`);
    }

    public static async putPlan(plan: InstrumentPlan) {
        return Rest.put(plan, `/instruments/me/plan`);
    }
}