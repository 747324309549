import React from "react";
import Input from 'antd/es/input';
import {Config} from "../../frame/Config";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import Icon from "../../frame/components/Icon";
import {EnvironmentHandler} from "../../frame/env/EnvironmentHandler";

interface State {
}

interface Props {

}

export class ServerConfig extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {

    }

    render() {

        return <>

            <p><span style={{color: "red"}}><Icon icon={faExclamationTriangle}/> ACHTUNG!</span> Ein gewöhnlicher User
                sollte hier nichts ändern!!</p>

            <p>Ist Development: "{EnvironmentHandler.isDev() + ""}"</p>
            <h3>Alternative Api-URL</h3>
            <Input defaultValue={Config.getString("api-url")}
                   onChange={event => Config.persist("api-url", event.target.value)}/>
        </>;
    }
}

