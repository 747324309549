import React from "react";
import {FormInstance, Table} from "antd";
import {InstrumentPlan, InstrumentPlanElement} from "../../../data/Instrument";
import {InstrumentPlanView} from "./InstrumentPlanView";
import Column from "antd/es/table/Column";
import {InstrumentRenderingHelper} from "../InstrumentRenderingHelper";

interface InstrumentPlanTableBaseState {
}

export interface InstrumentPlanTableBaseProps {
    loading: boolean,
    plan: InstrumentPlan,
    parent: InstrumentPlanView
}

export abstract class InstrumentPlanTableBase<PROPS extends InstrumentPlanTableBaseProps, STATE extends InstrumentPlanTableBaseState> extends React.Component<PROPS, STATE> {

    protected formRef = React.createRef<FormInstance>();

    async componentDidMount() {
        this.formRef.current?.setFieldsValue(this.props.plan);
    }

    protected renderList() {

        let data = this.getData();

        return <Table
            loading={this.props.loading}
            dataSource={data}
            pagination={false}
            bordered
            size="small"
            style={{marginBottom: "10px"}}
            summary={data => this.renderSummaryRow(data as InstrumentPlanElement[])}
        >
            {this.renderColumns()}
        </Table>
    }


    protected getData() {
        return this.props.plan.instruments
            .sort((a, b) => b.percent - a.percent);
    }

    protected renderNameColumn() {
        return <Column title="Name" dataIndex={["instrument", "officialName"]} key="instrument.officialName'"
                       render={(value, record) => {
                           return <>
                               {InstrumentRenderingHelper.renderWknIsin((record as InstrumentPlanElement).instrument)}
                               <br/>
                               <a target="_blank" rel="noreferrer"
                                  href={(record as InstrumentPlanElement).instrument.detailsWebPage}>{value}</a>
                           </>
                       }}/>;
    }


    protected setListState(plan: InstrumentPlan) {
        plan.instruments = [...plan.instruments];
        this.props.parent.setState({plan: plan});
    }

    protected abstract renderColumns(): React.ReactNode

    protected abstract renderSummaryRow(data: InstrumentPlanElement[]): React.ReactNode


}