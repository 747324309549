import React from "react";
import {Button, Divider, Form, FormInstance, Input, message, Modal, Popconfirm, Select} from "antd";
import LoginMenu from "./LoginMenu";
import MyAccountApi from "../apis/MyAccountApi";
import {Option} from "antd/es/mentions";
import Security from "../global/Security";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import Icon from "./components/Icon";
import {faKey} from "@fortawesome/free-solid-svg-icons/faKey";

type Props = {
    parent: LoginMenu
}

type State = {
    loading: boolean
    profilePictureImage?: string
}


class AccountModal extends React.Component<Props, State> {

    private formRef = React.createRef<FormInstance>();


    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        MyAccountApi.get().then(value => {
                this.formRef.current!.setFieldsValue(value);
                this.setState({profilePictureImage: value.profilePictureImage})
            }
        ).catch(reason => console.log("Bin hier!"))
    }

    render() {
        return (
            <Modal
                title="Accountdaten"
                visible={true}
                okButtonProps={{loading: this.state.loading}}
                onOk={() => this.handleSave()}
                okText={<><Icon icon={faSave}/> Speichern</>}
                onCancel={() => this.handleClose()}
                cancelText="Abbrechen"

            >
                <img style={{display: "block", marginRight: "auto", marginLeft: "auto", marginBottom: "20px"}}
                     src={this.state.profilePictureImage} alt="PIC"/>

                <Form
                    ref={this.formRef}
                    name="basic"
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                >
                    <Form.Item
                        label="Nutzername"
                        name="username"
                        rules={[{required: true, message: 'Bitte Nutzername ausfüllen'}]}
                    >
                        <Input autoFocus/>
                    </Form.Item>

                    <Form.Item
                        label="E-Mail"
                        name="email"
                        rules={[{required: true, type: "email", message: 'Bitte eine valide E-Mail eintragen'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Divider/>

                    <Form.Item
                        label="Geschlecht"
                        name="gender"
                    >
                        <Select defaultValue="lucy" style={{width: "100%"}}>
                            <Option value="MALE">Männlich</Option>
                            <Option value="FEMALE">Weiblich</Option>
                            <Option value="OTHER">Divers</Option>
                            <Option value="UNKNOWN">Keine Angabe</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Vorname"
                        name="firstname"
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Nachname"
                        name="lastname"
                    >
                        <Input/>
                    </Form.Item>

                </Form>

                <Popconfirm placement="top" title="Sicher? ALLE daten werden vollständig gelöscht!"
                            onConfirm={e => this.handleDelete()} okText="Ja!" cancelText="Nein">
                    <Button icon={<Icon icon={faTrash}/>} danger>Account löschen</Button>
                </Popconfirm>

                <Button icon={<Icon icon={faKey}/>} onClick={e => this.handlePasswordChange()}>Passwort ändern</Button>

            </Modal>
        );
    }

    private handleClose() {
        this.props.parent.setState({showAccountModal: false});
    }

    private handleSave() {
        this.formRef.current!.validateFields()
            .then(value => {
                this.setState({loading: true});
                MyAccountApi.put(value)
                    .then(response => {
                        this.formRef.current!.setFieldsValue(response);
                        this.setState({loading: false})
                    });
            });
    }

    private handleDelete() {
        MyAccountApi.delete()
            .then(value => Security.get().logout());
    }

    private async handlePasswordChange() {
        await MyAccountApi.requestNewPassword(Security.get().getUsername())
        message.success("Passwort-Email angefordert.");
    }
}

export default AccountModal;