import React, {ReactNode} from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {Card, ConfigProvider, List} from "antd";
import NoData from "../../../global/NoData";
import Formatter from "../../../global/Formatter";

export interface LoadingViewState {
    loading: boolean
}

export interface LoadingViewProps {

}


export abstract class LoadingView<PROPS extends LoadingViewProps, STATE extends LoadingViewState> extends React.Component<PROPS, STATE> {

    protected constructor(props: PROPS) {
        super(props);
    }

    protected loadable(node: ReactNode, height?: number, width?: number): ReactNode {
        return this.state.loading ?
            <Skeleton enableAnimation height={height ? height : 32} width={width} inline={true}/> : node;
    }

    protected loadableRound(node: ReactNode, height?: number, width?: number): ReactNode {
        return this.state.loading ?
            <Skeleton enableAnimation circle height={height ? height : 32} width={width} inline={true}/> : node;
    }


    protected loadableMargin(node: ReactNode, height?: number, margin?: string): ReactNode {
        return this.state.loading ?
            <Skeleton enableAnimation height={height ? height : 32} style={{margin: margin || 0}}/> : node;
    }


    protected renderSkeleton() {
        return <Skeleton enableAnimation/>
    }

    protected renderBasicCardListBase(data: any[], bodyRenderer: (item: any) => ReactNode, onItemSelected: (item: any) => void, skeletonHeight?: number) {
        return <ConfigProvider renderEmpty={NoData.getNoDataDisplay()}>
            <List dataSource={data}
                  renderItem={(item: any) => (
                      this.loadableMargin(
                          <Card hoverable
                                size="small"
                                style={{margin: "10px"}}
                                onClick={() => onItemSelected(item)}
                          >
                              {bodyRenderer(item)}
                          </Card>, skeletonHeight || 64, "10px"
                      )
                  )}
            >
            </List>
        </ConfigProvider>;
    }

    protected formatCurrencyColored(value: number | undefined) {
        let color = "black";
        if (value) {
            color = value > 0 ? "green" : "red";
        }
        return <span style={{color}}>{Formatter.formatCurrency(value)}</span>;
    }

    protected formatPercentColored(value: number) {
        let color = "black";
        if (value) {
            color = value > 0 ? "green" : "red";
        }
        return <span style={{color}}>{Formatter.formatPercent(value)}</span>;
    }

}