import React from 'react';
import {Avatar, Collapse, Modal} from "antd";
import {PreciousMetal, PreciousMetalPrices, PreciousMetalType} from "../../data/PreciousMetal";
import Formatter from "../../global/Formatter";
import PreciousMetalApi from "../../apis/PreciousMetalApi";
import Meta from "antd/es/card/Meta";
import TreePartStatisticsView from "../../frame/components/TreePartStatisticsView";
import {OverviewState, OverviewView} from "../../frame/components/base/OverviewView";
import {FilePreview} from "../../frame/components/FilePreview";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import {FourSlotGrid} from "../../frame/components/FourSlotGrid";
import PreciousMetalPriceWidget from "../home/widgets/PreciousMetalPriceWidget";

interface State extends OverviewState {
    pricesLoading: boolean
    priceHistoryType?: PreciousMetalType
    currentPrices?: PreciousMetalPrices
    data: PreciousMetal[]
}

interface Props {
}

class RecurringTransactionsEntriesView extends OverviewView<Props, State, PreciousMetal> {

    constructor(props: Props) {
        super(props);
        this.state = {
            pricesLoading: true,
            loading: true,
            query: "",
            data: this.generateDummyData()
        }
    }

    async componentDidMount() {
        await this.loadBaseQueryFromUrl();
        await this.onSearch();
    }

    async onSearch() {
        //Load prices
        const prices = await PreciousMetalApi.getCurrentPrice();
        this.setState({currentPrices: prices, pricesLoading: false})
        await this.pushBasicStateToUrl();
        // Load actual value
        const values = await PreciousMetalApi.query(this.state.query)
        values.forEach(value => value.currentValue = this.calculateCurrentPrice(value));
        this.setState({data: values, loading: false})
    }

    protected renderAdditionalContent() {

        const purchaseValue = this.getPurchaseValueTotal();
        const currentValue = this.getCurrentValueTotal();
        const type = this.state.priceHistoryType || PreciousMetalType.GOLD;
        return <>

            {
                this.state.priceHistoryType ?
                    <Modal
                        visible
                        title="Edelmetallpreise (oz)"
                        onCancel={() => this.setState({priceHistoryType: undefined})}
                        footer={null}
                        bodyStyle={{margin: 5, padding: 5}}
                        width={window.innerWidth < 600 ? "100%" : "75%"}
                        centered
                    >
                        <PreciousMetalPriceWidget displayedType={type} multiplier={this.calculateWeight(type) / 31.1}
                                                  alternativeTitle={"Wert"}/>
                    </Modal> :
                    null
            }


            <Collapse style={{margin: "10px"}}>
                <CollapsePanel key={1}
                               header={RecurringTransactionsEntriesView.renderCollapsableHeader(currentValue - purchaseValue)}>
                    <>
                        {this.renderWeighOverview()}
                        {this.renderValueOverview()}
                        <TreePartStatisticsView firstLabel="Gesamter Kaufpreis: "
                                                secondLabel="Aktueller Gesamtwert: "
                                                thirdLabel="Aktuelle Bilanz: "
                                                first={purchaseValue}
                                                second={currentValue}
                                                third={currentValue - purchaseValue}
                                                loading={this.state.loading}
                        />
                    </>
                </CollapsePanel>
            </Collapse>
        </>
    }


    private static renderCollapsableHeader(balance: number) {
        return <span style={{padding: "1px"}}>Filter / Details (<span
            className={`${balance > 0 ? "income-color" : "expenses-color"}`}>
                    Bilanz: {" " + Formatter.formatCurrency(balance)}</span>) </span>;
    }

    private renderWeighOverview() {
        return <FourSlotGrid
            firstElement={this.asCardalike(`Gold: ${this.calculateWeightAndFormat(PreciousMetalType.GOLD)}`)}
            secondElement={this.asCardalike(`Silber: ${this.calculateWeightAndFormat(PreciousMetalType.SILVER)}`)}
            thirdElement={this.asCardalike(`Platin: ${this.calculateWeightAndFormat(PreciousMetalType.PLATINUM)}`)}
            fourthElement={this.asCardalike(`Palladium: ${this.calculateWeightAndFormat(PreciousMetalType.PALLADIUM)}`)}
        />
    }

    private renderValueOverview() {
        return <FourSlotGrid
            firstElement={this.formatValueCard("Gold", PreciousMetalType.GOLD)}
            secondElement={this.formatValueCard("Silber", PreciousMetalType.SILVER)}
            thirdElement={this.formatValueCard("Platin", PreciousMetalType.PLATINUM)}
            fourthElement={this.formatValueCard("Palladium", PreciousMetalType.PALLADIUM)}
        />
    }

    public asCardalike(inner: React.ReactNode): React.ReactNode {
        return <div className="price-style-statistics">{inner}</div>;
    }

    public formatValueCard(title: string, type: PreciousMetalType): React.ReactNode {
        const inner = `${title}: ${this.getCurrentValueTotalAndFormat(type)}`;
        return <div className="card-alike"
                    onClick={ev => this.setState({priceHistoryType: type})}>
            {inner}
        </div>;
    }

    protected getBasePath(): string {
        return "/precious-metals";
    }

    protected renderList(): React.ReactNode {
        return this.renderBasicCardList(this.state.data, item => RecurringTransactionsEntriesView.renderItem(item), 115)
    }

    private static renderItem(item: PreciousMetal) {
        return <Meta avatar={this.renderAvatar(item)}
                     title={<>{item.designation} - [{Formatter.formatPreciousMetalType(item.type)}]</>}
                     description={this.renderItemDescription(item)}
        />;
    }

    private static renderAvatar(item: PreciousMetal) {
        return <Avatar
            icon={this.getTitlePicturePreview(item)}
            shape="square" size="large"/>;
    }

    public static getTitlePicturePreview(item: PreciousMetal) {
        if (!item.files) {
            return <></>;
        }
        let titlePictureFile = item.files.filter(value => value.id === item.titlePictureId);
        if (titlePictureFile && titlePictureFile.length === 1) {
            return <FilePreview file={titlePictureFile[0]} previewable={false}/>;
        }
        return <></>;
    }

    private static renderItemDescription(item: PreciousMetal) {
        return <>
            Kaufpreis: {Formatter.formatCurrency(item.purchasePrice)} ({Formatter.formatWeight(item.weightGram)})
            x {item.amount}<br/>
            Aktueller Rohwert: {Formatter.formatCurrency(item.currentValue)}
        </>;
    }


    //Calculators:
    private calculateCurrentPrice(item: PreciousMetal) {
        let perOunce: number;
        switch (item.type) {
            case PreciousMetalType.GOLD:
                perOunce = this.state.currentPrices?.priceGold || 0;
                break;
            case PreciousMetalType.SILVER:
                perOunce = this.state.currentPrices?.priceSilver || 0;
                break;
            case PreciousMetalType.PLATINUM:
                perOunce = this.state.currentPrices?.pricePlatinum || 0;
                break;
            case PreciousMetalType.PALLADIUM:
                perOunce = this.state.currentPrices?.pricePalladium || 0;
                break;
            default:
                perOunce = 0;
        }
        return (perOunce / 31.1) * item.weightGram;
    }

    private getPurchaseValueTotal(): number {
        return this.state.data.map(value => value.purchasePrice * value.amount)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0) || 0;
    }

    private getCurrentValueTotal(type?: PreciousMetalType): number {
        return this.state.data.filter(value => !type || value.type === type)
            .map(elm => elm.amount * elm.currentValue)
            .reduce((a, b) => a + b, 0);
    }

    private calculateWeight(type: PreciousMetalType) {
        return this.state.data.filter(value => value.type === type)
            .map(elm => elm.amount * elm.weightGram)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    }


    private getCurrentValueTotalAndFormat(type?: PreciousMetalType) {
        return Formatter.formatCurrency(this.getCurrentValueTotal(type));
    }

    private calculateWeightAndFormat(type: PreciousMetalType) {
        return Formatter.formatWeight(this.calculateWeight(type));

    }


}

export default RecurringTransactionsEntriesView;