import {Instrument} from "../../data/Instrument";
import React from "react";
import {Card} from "antd";
import Formatter from "../../global/Formatter";
import {InstrumentRenderingHelper} from "./InstrumentRenderingHelper";

interface State {
}

interface Props {
    instrument?: Instrument,
    onItemSelected?: (item?: Instrument) => void,
    hoverable?: boolean
}


export class InstrumentCard extends React.Component<Props, State> {

    render() {
        const item = this.props.instrument;

        if (!item) {
            return <Card hoverable
                         onClick={() => this.props.onItemSelected === undefined ? "" : this.props.onItemSelected(item)}>
                Bitte auswählen.
            </Card>;
        }

        return <Card hoverable={this.props.hoverable}
                     size="small"
                     style={{marginBottom: "10px", marginTop: "10px"}}
                     title={<b>{item.officialName}</b>}
                     onClick={() => this.props.onItemSelected === undefined ? "" : this.props.onItemSelected(item)}
        >

            {InstrumentRenderingHelper.renderWknIsin(item)}<br/>
            <i>Herausgeber: {item.issuer} </i><br/>
            <i>Letzter Preis: {Formatter.formatCurrency(item.lastPrice)}</i><br/>
            <a onClick={event => event.stopPropagation()} href={item.detailsWebPage} target="_blank" rel="noreferrer">Weitere
                Infos</a>
        </Card>
    }

}