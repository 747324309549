import {message} from "antd";

export class Utils {
    public static toClipboard(value: string) {
        navigator.clipboard.writeText(value)
            .then(message.success("Kopiert!"));
    }

    public static round(num: number, places: number) {
        const factor = Math.pow(10, places - 1);
        return Math.round((num + Number.EPSILON) * factor) / factor;
    }

}