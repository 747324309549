import Rest from "./Rest";
import {File, FileDetails, FilesSize} from "../data/File";

class MyFileApi {

    public static query(query: string): Promise<File[]> {
        return Rest.getMultiple(`/files/me?query=${query}`);
    }

    public static getCurrentSizeConsumption(): Promise<FilesSize> {
        return Rest.getSpecific(`/files/me/all/size`);
    }

    public static getSpecific(id: string): Promise<FileDetails> {
        return Rest.getSpecific(`/files/me/${id}`);
    }

    public static updateFile(id: string, file: File): Promise<File> {
        return Rest.put(file, `/file/${id}`);
    }

    public static deleteFile(id: string): Promise<any> {
        return Rest.delete(`/file/${id}`);
    }
}

export default MyFileApi;