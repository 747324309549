import React from "react";
import {CryptoCurrencyEntry} from "../../../data/Crypto";
import Formatter from "../../../global/Formatter";
import Meta from "antd/es/card/Meta";
import TreePartStatisticsView from "../../../frame/components/TreePartStatisticsView";
import {OverviewState, OverviewView} from "../../../frame/components/base/OverviewView";
import CryptoApi from "../../../apis/CryptoApi";

interface State extends OverviewState {
    entries: CryptoCurrencyEntry[],
}

interface Props {

}

export default class CryptoCurrencyEntriesView extends OverviewView<Props, State, CryptoCurrencyEntry> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            query: "",
            entries: this.generateDummyDataCustom()
        }
    }

    async componentDidMount() {
        await this.loadBaseQueryFromUrl();
        await this.onSearch();
    }

    protected async onSearch() {
        this.setState({entries: this.generateDummyDataCustom(), loading: false});
        this.pushBasicStateToUrl();
        const entries = await CryptoApi.queryEntries(this.state.query);
        this.setState({entries, loading: false});
    }

    protected renderList() {
        return this.renderBasicCardList(this.state.entries, item => CryptoCurrencyEntriesView.renderItem(item));
    }

    private static renderItem(item: CryptoCurrencyEntry) {
        return <Meta
            title={<>{item.currency.currencyName} - {Formatter.formatDate(item.date)}</>}
            description={<>
                Kaufpreis:
                {Formatter.formatCurrencyWithDigits(item.buyInPrice, 10)}
                (x{Formatter.stringToNumber(item.amount, 10)})
                {" => "} {Formatter.formatCurrencyWithDigits(item.buyInPrice * item.amount, 10)}
                <br/>
                Aktueller
                Wert: {Formatter.formatCurrencyWithDigits(item.amount * item.currency.price, 10)}
            </>}
        />;
    }


    protected getBasePath(): string {
        return "/crypto/entries";
    }

    protected renderAdditionalContent() {

        return <TreePartStatisticsView firstLabel="Gesamter Kaufpreis: "
                                       secondLabel="Aktueller Gesamtwert: "
                                       thirdLabel="Aktuelle Bilanz: "
                                       first={this.getPurchaseValueTotal()}
                                       second={this.getCurrentValueTotal()}
                                       third={this.getCurrentValueTotal() - this.getPurchaseValueTotal()}
                                       loading={this.state.loading}
        />;
    }

    private getPurchaseValueTotal(): number {

        return this.state.entries?.map(value => value.buyInPrice * value.amount)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0) || 0;
    }

    private getCurrentValueTotal(): number {

        return this.state.entries?.map(value => value.currency.price * value.amount)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0) || 0;
    }

    private generateDummyDataCustom(): CryptoCurrencyEntry[] {
        return this.generateDummyData((item: any) => {
            item.currency = {}
            return item;
        })
    }

}

