import React from "react";
import AdminApi from "../../../apis/AdminApi";
import {Level, LogEntry} from "../../../data/Admin";
import {Card, Collapse, Select} from "antd";
import {DatePicker} from "antd/es";
import Formatter from "../../../global/Formatter";
import Icon from "../../../frame/components/Icon";
import {faBug} from "@fortawesome/free-solid-svg-icons/faBug";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import {faQuestion} from "@fortawesome/free-solid-svg-icons/faQuestion";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {Option} from "antd/es/mentions";
import {Config} from "../../../frame/Config";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import DateHelper from "../../../global/DateHelper";
import moment from "moment";
import {OverviewState, OverviewView} from "../../../frame/components/base/OverviewView";

interface State extends OverviewState {
    logs: LogEntry[],
    from?: number,
    to?: number,
    allowedLevels: Level[]
}

interface Props {

}

export class AdminLogsView extends OverviewView<Props, State, LogEntry> {

    constructor(props: Props) {
        super(props);
        this.state = {
            logs: [],
            allowedLevels: ["WARN", "ERROR"],
            query: "",
            from: DateHelper.getCurrentMonthRangeDateTime().from,
            to: DateHelper.getCurrentMonthRangeDateTime().to,
            loading: true
        }
    }

    async componentDidMount() {
        await this.loadQueryParams();
        await this.onSearch();
    }

    protected renderAdditionalContent() {
        const levels: Level[] = ["DEBUG", "INFO", "WARN", "ERROR"];
        return <Collapse style={{margin: "10px"}}
                         defaultActiveKey={Config.budgetBookDetailsOpenedByDefault() ? 1 : undefined}>
            <CollapsePanel key={1} header="Filter">
                <Select
                    mode="multiple"
                    allowClear
                    style={{width: '100%'}}
                    placeholder="Zu suchende Level"
                    value={this.state.allowedLevels}
                    onChange={value => this.onLevelChangeSearch(value)}
                >
                    {levels.map(value => <Option key={value} value={value}><>{value}</>
                    </Option>)}
                </Select>

                <DatePicker value={DateHelper.toMoment(this.state.from)}
                            onChange={ev => this.onRangeSearchFrom(ev || undefined)}
                            format={"DD.MM.yyyy"}/>
                <DatePicker value={DateHelper.toMoment(this.state.to)}
                            onChange={ev => this.onRangeSearchTo(ev || undefined)}
                            format={"DD.MM.yyyy"}/>

            </CollapsePanel>
        </Collapse>

    }

    protected renderList() {
        return this.renderBasicCardList(this.state.logs, item => AdminLogsView.renderItem(item));
    }

    private static renderItem(item: LogEntry) {
        return <Card.Meta
            avatar={<Icon color={AdminLogsView.getColorForLevel(item.level)}
                          icon={AdminLogsView.getIconForLevel(item.level)}/>}
            title={`${item.className} - ${Formatter.formatDateTime(item.date)}`}
            description={<>
                {item.method} : {item.line} <br/> {item.message}
            </>}
        />;
    }

    private async onLevelChangeSearch(allowedLevels: Level []) {
        await this.setState({allowedLevels});
        await this.onSearch();
    }

    private async onRangeSearchFrom(from?: moment.Moment) {
        await this.setState({from: DateHelper.fromMoment(from)});
        await this.onSearch();
    }

    private async onRangeSearchTo(to?: moment.Moment) {
        await this.setState({to: DateHelper.fromMoment(to)});
        await this.onSearch();
    }

    protected async onSearch() {
        await this.setState({logs: this.generateDummyData(), loading: true});
        await this.pushQueryParams();
        let logs = await AdminApi.queryLogs(this.state.allowedLevels, this.state.query, this.state.from, this.state.to);
        this.setState({logs, loading: false});
    }

    protected getBasePath(): string {
        return "/admin/logs";
    }

    protected showAddButton(): boolean {
        return false;
    }

    private async loadQueryParams() {
        const params = await this.loadBaseQueryFromUrl();
        await this.setState({from: parseInt(params.get("from") || "" + DateHelper.getCurrentMonthRangeDateTime().from)})
        await this.setState({to: parseInt(params.get("to") || "" + DateHelper.getCurrentMonthRangeDateTime().to)})
        const levelsString = params.get("levels") || "WARN,ERROR";
        const allowedLevels: Level[] = levelsString.split(",") as Level[];
        await this.setState({allowedLevels})
    }

    private async pushQueryParams() {
        window.history.pushState("", "Query", `${this.getBasePath()}?query=${this.state.query}&from=${this.state.from}&to=${this.state.to}&levels=${this.state.allowedLevels.join(",")}`);
    }

    private static getIconForLevel(level: Level) {
        switch (level) {
            case "DEBUG":
                return faBug
            case "INFO":
                return faInfoCircle
            case "WARN":
                return faExclamationCircle
            case "ERROR":
                return faExclamationTriangle
            default:
                return faQuestion
        }
    }

    private static getColorForLevel(level: Level) {
        switch (level) {
            case "DEBUG":
                return undefined
            case "INFO":
                return "darkBLue"
            case "WARN":
                return "yellow"
            case "ERROR":
                return "red"
            default:
                return undefined
        }
    }
}

