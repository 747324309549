import React from "react";
import {OverviewProps, OverviewState, OverviewView} from "../../frame/components/base/OverviewView";
import {Instrument, InstrumentTransaction} from "../../data/Instrument";
import {InstrumentApi} from "../../apis/InstrumentApi";
import Formatter from "../../global/Formatter";
import {Button, Card, Collapse, Radio, Tag} from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import {TreeSlotGrid} from "../../frame/components/TreeSlotGrid";
import {Config} from "../../frame/Config";
import {ChartHelper} from "../../frame/charts/ChartHelper";
import {ChartPlugins} from "../../frame/charts/ChartPlugins";
import {Doughnut} from "react-chartjs-2";
import {ChartData} from "chart.js";
import {InstrumentRenderingHelper} from "./InstrumentRenderingHelper";
import {Link} from "react-router-dom";
import Icon from "../../frame/components/Icon";
import {faMinusCircle} from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import {FourSlotGrid} from "../../frame/components/FourSlotGrid";

type GroupingType = "BY_DATE" | "BY_INSTRUMENT" | "FLAT";


interface State extends OverviewState {
    transactions: InstrumentTransaction[],
    grouping: GroupingType,
    fullDetails: boolean,
}

interface Props extends OverviewProps {
}

export class InstrumentTransactionsView extends OverviewView<Props, State, InstrumentTransaction> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            query: "",
            grouping: "BY_DATE",
            transactions: this.generateDummyData(),
            fullDetails: false
        }
    }

    async componentDidMount() {
        await this.loadBaseQueryFromUrl();
        await this.onSearch();
    }


    protected renderList(): React.ReactNode {
        switch (this.state.grouping) {
            case "BY_DATE":
                return this.renderListGroupedByDate();
            case "BY_INSTRUMENT":
                return this.renderListGroupedByInstrument();
            case "FLAT":
                return this.renderBasicCardList(this.state.transactions, item => this.renderItem(item));
            default:
                return <></>
        }

    }

    private renderListGroupedByDate() {
        return this.renderTimeline(this.groupAndTransformTimelineData(this.state.transactions), item => this.renderItemAdapter(item),
            (a, b) => 1);
    }

    private renderListGroupedByInstrument() {

        if (this.state.loading) {
            return this.renderBasicCardList(this.generateDummyData(), item => this.renderItem(item));
        }
        return this.renderBasicCardListBase(this.generateGroupedPseudoElements(), item => this.renderItemGrouped(item), item => "");
    }


    private renderPieChart() {

        if (this.state.loading) {
            return this.renderBasicCardList(this.state.transactions, item => this.renderItem(item));
        }

        const groupedValues = this.generateGroupedPseudoElements();

        const data: ChartData<"doughnut"> = {
            labels: groupedValues.map(value => value.instrument.shortName),
            datasets: [{
                data: groupedValues.map(value => value.amount * value.instrument.lastPrice),
                backgroundColor: ChartHelper.hardCodedPalette(groupedValues.length, "light"),
                borderWidth: 2
            }]
        }

        return <Doughnut data={data}
                         options={ChartHelper.getDoughnutOptions()}
                         plugins={[ChartPlugins.getDoughnutInnerRendererPlugin("Asset allocation")]}/>;
    }

    private generateGroupedPseudoElements() {
        const groupedPseudoElements: InstrumentTransaction[] = [];
        const map: Map<string, InstrumentTransaction[]> = new Map();

        this.state.transactions.filter(value => value.type === "BUY").forEach(value => {
            if (!map.has(value.instrument.id)) {
                map.set(value.instrument.id, []);
            }
            map.get(value.instrument.id)?.push(value);
        });

        map.forEach((value, key) => {
            const element = this.generateInstrumentGroupedPseudoTransaction(value);
            if (element) {
                groupedPseudoElements.push(element);
            }
        });
        return groupedPseudoElements;
    }

    private generateInstrumentGroupedPseudoTransaction(transactions: InstrumentTransaction[]): InstrumentTransaction | undefined {
        if (transactions.length === 0) {
            return undefined;
        }
        const instrument: Instrument = transactions[0].instrument;
        const amount = transactions.map(value => value.amount).reduce((a, b) => a + b, 0);
        const buyInPrice = transactions.reduce((a, b) => a + b.amount * b.buyInPrice, 0) / amount;

        return {
            id: Math.random() + "",
            instrument: instrument,
            date: "-",
            amount: amount,
            buyInPrice: buyInPrice,
            type: "BUY",
            description: ""
        };
    }

    private renderItemAdapter(item: InstrumentTransaction) {
        return this.loadableMargin(<Card hoverable
                                         size="small"
                                         style={{margin: "10px"}}
                                         onClick={() => item.type === "BUY" ? this.onItemSelected(item) : ""}
        >
            {this.renderItem(item)}
        </Card>, 100);
    }

    private renderItem(item: InstrumentTransaction) {

        if (!item.instrument) {
            return <></>;
        }

        if (item.type === "BUY" || item.type === "INACTIVE_BUY") {
            let tag = null;
            const buyIn = item.buyInPrice * item.amount;
            const current = item.instrument.lastPrice * item.amount;

            if (item.type === "INACTIVE_BUY") {
                tag = <Tag color="default">Liquidiert</Tag>;
            } else {
                tag = <Tag color="green">Kauf</Tag>;
            }

            return <>
                {tag} <b>{item.instrument.officialName}</b><br/>
                {InstrumentRenderingHelper.renderWknIsin(item.instrument)}<br/>
                <i> x{item.amount.toFixed(4)} Stück zu {Formatter.formatCurrency(item.buyInPrice)} gekauft.</i><br/>
                <i> Bilanz: {this.formatCurrencyColored(current - buyIn)} ({this.formatPercentColored(((current / buyIn) - 1) * 100)})</i>
            </>
        } else {
            const buyIn = item.buyInPrice * item.amount;
            const sellOut = (item.sellOutPrice || 0) * item.amount;
            return <>
                <Tag color="orange">Verkauf</Tag><b>{item.instrument.officialName}</b><br/>
                {InstrumentRenderingHelper.renderWknIsin(item.instrument)}<br/>
                <i> x{item.amount.toFixed(4)} Stück zu {Formatter.formatCurrency(item.sellOutPrice)} verkauft.</i><br/>
                <i> Realisierte
                    Bilanz: {this.formatCurrencyColored(sellOut - buyIn)} ({this.formatPercentColored(((sellOut / buyIn) - 1) * 100)})</i>

            </>
        }


    }


    private renderItemGrouped(item: InstrumentTransaction) {

        if (!item.instrument) {
            return <></>;
        }
        const buyIn = item.buyInPrice * item.amount;
        const current = item.instrument.lastPrice * item.amount;

        return <>
            <b>{item.instrument.officialName}</b><br/>
            {InstrumentRenderingHelper.renderWknIsin(item.instrument)}<br/>
            <i> Stückzahl: {item.amount.toFixed(4)} </i><br/>
            <i> Gesamtkosten: {Formatter.formatCurrency(item.amount * item.buyInPrice)}</i><br/>
            <i> Gesamtwert: {Formatter.formatCurrency(item.amount * item.instrument.lastPrice)}</i><br/>
            <i> Bilanz: {this.formatCurrencyColored(current - buyIn)} ({this.formatPercentColored(((current / buyIn) - 1) * 100)})</i>
        </>
    }

    protected getBasePath(): string {
        return "/instruments/transactions";
    }

    protected async onSearch() {
        this.setState({loading: true})
        const transactions = await InstrumentApi.queryTransactions(this.state.query);
        this.setState({loading: false, transactions})
        this.pushBasicStateToUrl();
    }


    protected renderAdditionalContent() {

        const purchaseValue = this.getPurchaseValueTotal();
        const currentValue = this.getCurrentValueTotal();
        return <>

            <Collapse style={{margin: "10px"}}
                      defaultActiveKey={Config.budgetBookDetailsOpenedByDefault() ? 1 : undefined}>
                <CollapsePanel key={1}
                               header={<div>Filter / Details
                                   (Bilanz: {this.formatCurrencyColored(currentValue - purchaseValue)})</div>}>


                    <FourSlotGrid className="price-style-statistics" firstElement={<>Kosten: {Formatter.formatCurrency(purchaseValue)}</>}
                                  secondElement={<>Wert: {Formatter.formatCurrency(currentValue)}</>}
                                  thirdElement={<>Bilanz: {this.formatCurrencyColored(currentValue - purchaseValue)} ({this.formatPercentColored(((currentValue / purchaseValue) - 1) * 100)})</>}
                                  fourthElement={<>Realisiert: {this.formatCurrencyColored(this.getRealisedYield())}</>}/>


                    <TreeSlotGrid firstElement={<>
                        Gruppierung: <br/>
                        {this.renderGroupingSelect()}
                    </>} secondElement={<></>} thirdElement={<></>}/>

                </CollapsePanel>
            </Collapse>
        </>
    }

    private renderGroupingSelect() {
        return <Radio.Group onChange={value => this.setState({grouping: value.target.value})}
                            value={this.state.grouping}
        >
            <Radio value={"BY_DATE"}>Nach Datum</Radio>
            <Radio value={"BY_INSTRUMENT"}>Nach Instrument</Radio>
            <Radio value={"FLAT"}>Keine</Radio>

        </Radio.Group>;
    }

    private getRealisedYield() {
        if (this.state.loading) {
            return 0;
        }

        return this.state.transactions.filter(value => value.type === "SELL").reduce((previousValue, currentValue) => {
            const buyIn = currentValue.buyInPrice * currentValue.amount;
            const current = currentValue.instrument.lastPrice * currentValue.amount;
            return previousValue + (current - buyIn);
        }, 0);
    }

    private getPurchaseValueTotal() {
        if (this.state.loading) {
            return 0;
        }

        return this.state.transactions.filter(value => value.type === "BUY").reduce((previousValue, currentValue) => {
            return previousValue + (currentValue.buyInPrice * currentValue.amount);
        }, 0);
    }

    private getCurrentValueTotal() {
        if (this.state.loading) {
            return 0;
        }

        return this.state.transactions.filter(value => value.type === "BUY").reduce((previousValue, currentValue) => {
            return previousValue + (currentValue.instrument.lastPrice * currentValue.amount);
        }, 0);
    }



    protected renderAdditionalButtons(): React.ReactNode {
        return <>
            <Button style={{marginLeft: "10px"}}><Link to={"/instruments/sell"}><Icon
                icon={faMinusCircle}/> Verkauf</Link></Button>
            <Button style={{marginLeft: "10px"}}><Link to={"/instruments/scalable"}>Scalable-Import</Link></Button>
        </>;
    }
}