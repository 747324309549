import React from "react";
import MetaApi from "../../apis/MetaApi";
import {Card, ConfigProvider, List} from "antd";
import NoData from "../../global/NoData";


interface State {
    list: string[]
}

interface Props {

}

export default class AdminView extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            list: []
        }
    }

    async componentDidMount() {
        let values = await MetaApi.getSystemStatus();
        this.setState({list: values.values});
    }

    render() {
        return <>
            <ConfigProvider renderEmpty={NoData.getNoDataDisplay()}>
                <List dataSource={this.state.list}
                      bordered
                      style={{margin: "20px"}}
                      header="Systemparamter"
                      renderItem={(item: any) => (
                          <Card size="small"
                                style={{margin: "10px"}}
                          >
                              {item}
                          </Card>
                      )}
                >
                </List>
            </ConfigProvider>;
        </>;
    }
}

