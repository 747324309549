import moment from 'moment';
import Comp from "./Comp";

export interface Range {
    from: string,
    to: string
}

export interface RangeDateTime {
    from: number,
    to: number
}

class DateHelper {

    private static DATE_FORMAT = "YYYY-MM-DD";

    public static toMomentDate(value?: string): moment.Moment | undefined {
        return DateHelper.toMomentDateWithFormat(this.DATE_FORMAT, value);
    }

    public static toMomentDateWithFormat(format: string, value?: string): moment.Moment | undefined {
        if (Comp.nill(value)) {
            return undefined;
        }
        // @ts-ignore
        return moment(value, format)
    }


    public static fromMomentDate(value?: moment.Moment): string | undefined {
        if (Comp.nill(value)) {
            return undefined;
        }
        return value?.format(this.DATE_FORMAT);
    }


    public static fromMoment(value?: moment.Moment): number | undefined {

        if (Comp.nill(value)) {
            return undefined;
        }

        // @ts-ignore
        return value.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        }).unix() * 1000;
    }


    public static toMomentFromString(value?: string): moment.Moment | undefined {
        if (Comp.nill(value)) {
            return undefined;
        }
        // @ts-ignore
        return moment.unix(value / 1000);
    }

    public static toMoment(value?: number): moment.Moment | undefined {
        if (Comp.nill(value)) {
            return undefined;
        }
        // @ts-ignore
        return moment.unix(value / 1000);
    }

    static todayAsMoment(): moment.Moment | undefined {
        return DateHelper.toMoment(moment.now());
    }

    static nowAtDay(day: number): string | undefined {
        let lastDayOfMonth: number = moment().daysInMonth();
        if (day > lastDayOfMonth) {
            day = lastDayOfMonth;
        }
        return this.fromMomentDate(moment({day}));
    }

    public static getCurrentMonthRangeDateTime(): RangeDateTime {
        const from = moment().startOf("month");
        const to = moment().endOf("month");
        return {to: this.fromMoment(to) || 0, from: this.fromMoment(from) || 0}
    }

    public static getCurrentMonthRange(): Range {
        const from = moment().startOf("month");
        const to = moment().endOf("month");
        return {to: this.fromMomentDate(to) || "0", from: this.fromMomentDate(from) || "0"}
    }

    public static getNextMonth(range: Range, delta: number): Range {
        const from = this.toMomentDate(range.from)?.add({month: delta}).startOf("month");
        const to = this.toMomentDate(range.from)?.add({month: delta}).endOf("month");
        return {to: this.fromMomentDate(to) || "0", from: this.fromMomentDate(from) || "0"}
    }


    public static getCurrentYearRange(): Range {
        const from = moment().startOf("year");
        const to = moment().endOf("year");
        return {to: this.fromMomentDate(to) || "0", from: this.fromMomentDate(from) || "0"}
    }

    public static getNextYear(range: Range, delta: number): Range {
        const from = this.toMomentDate(range.from)?.add({year: delta}).startOf("year");
        const to = this.toMomentDate(range.from)?.add({year: delta}).endOf("year");
        return {to: this.fromMomentDate(to) || "0", from: this.fromMomentDate(from) || "0"}
    }


}

export default DateHelper;