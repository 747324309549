import React, {ReactNode} from "react";
import {Form, FormInstance} from "antd";
import SaveButton from "../SaveButton";
import DeleteButton from "../DeleteButton";
import {BaseResponse} from "../../../data/BaseResponse";
import 'react-loading-skeleton/dist/skeleton.css'
import {DetailView, DetailViewProps, DetailViewState} from "./DetailView";
import {Constants} from "../../Constants";

export interface DetailEditViewState extends DetailViewState {

}

export interface DetailEditViewProps extends DetailViewProps {

}


export abstract class DetailEditView<PROPS extends DetailEditViewProps, STATE extends DetailEditViewState, DETAIL_TYPE extends BaseResponse> extends DetailView<PROPS, STATE> {

    protected formRef = React.createRef<FormInstance>();

    protected constructor(props: PROPS) {
        super(props);
    }

    renderContent(): ReactNode {
        return <>
            {this.renderHeader()}

            <Form
                ref={this.formRef}
                name="basic"
                labelCol={Constants.LABEL_COL}
                wrapperCol={Constants.WRAPPER_COL}
            >
                {this.renderForm()}
                <SaveButton onClick={() => this.handleSave()} loading={this.state.loading}/>
                {this.isNew() ? null : <DeleteButton onClick={() => this.handleDelete()} loading={this.state.loading}/>}

                {this.additionalButtons()}
            </Form>
        </>

    }

    protected abstract renderForm(): ReactNode;

    protected abstract deleteHandler(): (id: string) => Promise<any>;

    protected handleDelete() {
        this.deleteHandler()(this.state.entryId)
            .then(() => this.setState({redirectOverview: true}));
    }

    protected async handleSave() {
        let entry = await this.formRef.current!.validateFields() as DETAIL_TYPE
        this.prePersistActions(entry);
        this.setState({loading: true});
        let response: DETAIL_TYPE;

        if (this.isNew()) {
            response = await this.createHandler()(entry);
            this.formRef.current!.setFieldsValue(response);
            if (response.id) {
                window.location.replace(`${this.redirectTargetEditPage()}/${response.id}`);
            } else {
                this.setState({entryId: "new"});
            }
        } else {
            response = await this.updateHandler()(this.state.entryId, entry)
            this.formRef.current!.setFieldsValue(response);
        }
        this.setState({loading: false});
    }

    protected prePersistActions(value: DETAIL_TYPE): void {
    }

    protected abstract createHandler(): (value: DETAIL_TYPE) => Promise<DETAIL_TYPE>;

    protected abstract updateHandler(): (id: string, value: DETAIL_TYPE) => Promise<DETAIL_TYPE>;

    protected abstract update(): any;

    protected additionalButtons(): ReactNode {
        return <></>;
    }
}