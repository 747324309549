import React, {ReactNode} from "react";
import {DetailViewProps, DetailViewState} from "../../frame/components/base/DetailView";
import {File} from "../../data/File";
import {BudgetBookEntry} from "../../data/BudgetBookEntry";
import {PreciousMetal} from "../../data/PreciousMetal";
import MyFileApi from "../../apis/MyFileApi";
import {Card, Collapse, Form, Input, List} from "antd";
import Formatter from "../../global/Formatter";
import {Link} from "react-router-dom";
import {BaseResponse} from "../../data/BaseResponse";
import {DetailEditView} from "../../frame/components/base/DetailEditView";
import {FilePreview} from "../../frame/components/FilePreview";
import Button from "antd/es/button";
import Icon from "../../frame/components/Icon";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import {FileUtils} from "../../global/FileUtils";


interface State extends DetailViewState {
    file: File,
    budgetBookEntries: BudgetBookEntry[],
    preciousMetalEntries: PreciousMetal[],
    redirectTarget?: string,
}

interface Props extends DetailViewProps {

}

export class MyFilesDetailView extends DetailEditView<Props, State, File> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            entryId: this.props.match.params.id,
            file: {
                filename: "test",
                id: "new",
                createdAt: 0,
                fileExtension: ""
            },
            budgetBookEntries: [],
            preciousMetalEntries: []
        }
    }

    async componentDidMount() {
        await this.update();
    }

    protected async update() {
        const data = await MyFileApi.getSpecific(this.state.entryId);
        this.setState({
            file: data.file,
            budgetBookEntries: data.budgetBookEntries,
            preciousMetalEntries: data.preciousMetalEntries,
            loading: false
        });
        this.formRef.current!.setFieldsValue(data.file);
    }

    renderForm() {
        return <>
            <Form.Item
                label="Dateiname"
                name="filename"
                rules={[{required: true, message: 'Bitte Bezeichnung ausfüllen!'}]}
            >
                {this.loadable(<Input autoFocus/>)}
            </Form.Item>
            <Form.Item
                label="Hochgeladen am"
            >
                {this.loadable(<>{Formatter.formatDateTime(this.state.file.createdAt)}</>)}
            </Form.Item>
            <Form.Item
                label="Dateigröße"
            >
                {this.loadable(<>{`${Formatter.formatAsMb(this.state.file.sizeInBytes || 0)} MB`}</>)}
            </Form.Item>
            <Form.Item
                label="Dateivorschau"
            >
                {this.loadable(<FilePreview file={this.state.file} previewable={true} width={300}/>)}
            </Form.Item>
            <Form.Item
                label="Angehängt an"
            >
                {this.state.loading ? this.renderAttachmentOverviewLoading() : this.renderAttachmentOverview()}
            </Form.Item>
        </>;
    }

    private renderAttachmentOverviewLoading() {
        return <>
            {this.loadable(null, 64)}
            {this.loadable(null, 64)}
        </>
    }

    private renderAttachmentOverview() {
        return <Collapse>
            <Collapse.Panel header={`Edelmetalle (${this.state.preciousMetalEntries.length})`} key="1">
                {this.renderList(
                    this.state.preciousMetalEntries,
                    "precious-metals",
                    item => <>{item.designation} - [{Formatter.formatPreciousMetalType(item.type)}]</>
                )}
            </Collapse.Panel>
            <Collapse.Panel header={`Haushaltsbucheinträge (${this.state.budgetBookEntries.length})`} key="2">
                {this.renderList(
                    this.state.budgetBookEntries,
                    "budget-book",
                    item => <>{item.designation} {item.category ? ` - [${item.category.designation}]` : ""}</>
                )}
            </Collapse.Panel>
        </Collapse>
    }

    private renderList<T extends BaseResponse>(data: T[], targetPage: string, contentRenderer: (data: T) => ReactNode) {
        return <List dataSource={data}
                     renderItem={(item: T) => (
                         <Link to={`/${targetPage}/${item.id}`} target="_blank">
                             <Card hoverable
                                   size="small"
                                   style={{margin: "10px"}}
                             >
                                 {contentRenderer(item)}
                             </Card>
                         </Link>
                     )}
        >
        </List>;
    }

    protected additionalButtons(): ReactNode {
        return <Button icon={<Icon icon={faDownload}/>} style={ {marginLeft: 6} }
                       onClick={() => FileUtils.downloadFile(this.state.file)}>Download</Button>;
    }

    protected redirectTarget(): string {
        return "/files";
    }

    protected createHandler(): (value: File) => Promise<File> {
        throw new Error("Not implemented!");
    }

    protected deleteHandler(): (id: string) => Promise<any> {
        return MyFileApi.deleteFile;
    }

    protected updateHandler(): (id: string, value: File) => Promise<File> {
        return MyFileApi.updateFile;
    }
}

