import Rest from "./Rest";
import {Budget} from "../data/Budget";

export class BudgetsApi {

    public static getAll(query: string, parentCategoryId: string): Promise<Budget[]> {
        return Rest.getMultiple(`/budgets?query=${query}&parentCategoryId=${parentCategoryId}`);
    }
    public static postEntry(entry: Budget): Promise<Budget> {
        return Rest.post(entry, `/budgets`);
    }

    public static putEntry(id: string, entry: Budget): Promise<Budget> {
        return Rest.put(entry, `/budgets/${id}`);
    }

    static async getEntry(id: string) {
        return Rest.getSpecific(`/budgets/${id}`);
    }

    static async deleteEntry(id: string) {
        return Rest.delete(`/budgets/${id}`);
    }
}