import React, {CSSProperties} from 'react';
import Button from "antd/es/button";

import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import Icon from "./Icon";

interface State {
}

interface Props {
    onClick?: (ev: any) => void
    loading: boolean
    icon?: any
    titel?: string
    style?: CSSProperties
}

export default class DeleteButton extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {}
    }

    render() {
        return <Button icon={<Icon icon={this.props.icon ? this.props.icon : faTrash}/>}
                       loading={this.props.loading}
                       onClick={ev => {
                           if (this.props.onClick) {
                               this.props.onClick(ev)
                           }
                       }}
                       danger
                       style={this.props.style ? this.props.style : {}}
        >
            {this.props.titel ? this.props.titel : "Löschen"}
        </Button>
    }

}
