import React from 'react';
import StatisticApi from "../../apis/StatisticApi";
import {TotalIncomeExpense} from "../../data/statistics/TotalIncomeExpense";
import TreePartStatisticsView from "../../frame/components/TreePartStatisticsView";
import Formatter from "../../global/Formatter";
import {TotalAssetValues} from "../../data/statistics/TotalAssetValues";
import {WidgetBase} from "./WidgetBase";

interface State {
    loading: boolean,
    totalBalance: TotalIncomeExpense,
    totalAssetValues: TotalAssetValues
}

interface Props {
    from: string,
    to: string
}

export class TotalsWidget extends WidgetBase<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            totalBalance: {
                id: "-",
                income: 0,
                expense: 0
            },
            totalAssetValues: {
                id: "",
                preciousMetals: 0,
                cryptos: 0,
                instruments: 0
            }
        }
    }

    async componentDidMount() {
        await this.update();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.from !== prevProps.from || this.props.to !== prevProps.to) {
            await this.update();
        }
    }

    private async update() {
        this.setState({loading: true})
        const totalBalance = await StatisticApi.getTotalIncomesExpenses(this.props.from, this.props.to);
        const totalAssetValues = await StatisticApi.getTotalAssetValues(this.props.to);
        this.setState({totalBalance, totalAssetValues, loading: false})
    }

    render() {
        return <>

            <h2>Vermögensübersicht im Zeitraum</h2>

            {this.loadable(<TreePartStatisticsView firstLabel="Gesamte Einnahmen: "
                                                   secondLabel="Gesamte Ausgaben: "
                                                   thirdLabel="Gesamte Bilanz: "
                                                   first={this.state.totalBalance.income}
                                                   second={this.state.totalBalance.expense}
                                                   third={this.state.totalBalance.income - this.state.totalBalance.expense}
            />, 50)}

            {this.loadable(<TreePartStatisticsView firstLabel="Edelmetalle: "
                                                   secondLabel="Krypto: "
                                                   thirdLabel="Finanzinstrumente: "
                                                   first={this.state.totalAssetValues.preciousMetals}
                                                   second={this.state.totalAssetValues.cryptos}
                                                   third={this.state.totalAssetValues.instruments}
            />, 50)}


            {this.loadable(<div className="price-style-statistics" style={{margin: "10px"}}>
                {"Aktuelles Gesamtvermögen: "}
                {" " + Formatter.formatCurrency(this.state.totalAssetValues.preciousMetals
                    + this.state.totalAssetValues.cryptos + this.state.totalAssetValues.instruments)}
            </div>, 50)}
        </>
    }

}