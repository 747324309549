import React from "react";
import {Account, Role} from "../../data/Account";
import {Button, Checkbox, Divider, Form, FormInstance, Input, message, PageHeader, Select} from "antd";
import {Redirect, RouteComponentProps} from "react-router-dom";
import AccountApi from "../../apis/AccountApi";
import {Option} from "antd/es/mentions";
import RoleApi from "../../apis/RoleApi";
import SaveButton from "../../frame/components/SaveButton";
import DeleteButton from "../../frame/components/DeleteButton";
import Icon from "../../frame/components/Icon";
import {faKey} from "@fortawesome/free-solid-svg-icons/faKey";
import MyAccountApi from "../../apis/MyAccountApi";


interface State {
    loading: boolean
    rolesLoading: boolean
    redirect: boolean
    accountId: string,
    account?: Account,
    roles?: Role[],
    passwordTyped: boolean
}

interface Props extends RouteComponentProps<{ id: string }> {

}

class AccountsDetailView extends React.Component<Props, State> {

    private formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            rolesLoading: true,
            redirect: false,
            passwordTyped: false,
            accountId: props.match.params.id
        }
    }

    componentDidMount() {
        if (this.isNew()) {
            this.setState({loading: false});
        } else {
            AccountApi.get(this.state.accountId)
                .then(value => this.setState({account: value}))
                .then(() => this.formRef.current?.setFieldsValue(this.state.account))
                .then(() => this.setState({loading: false}))
        }

        RoleApi.getAll().then(value => this.setState({roles: value, rolesLoading: false}))

    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={`/accounts`} push/>
        }

        return <>

            <PageHeader
                onBack={() => this.setState({redirect: true})}
                title={this.isNew() ? "Account erstellen" : "Account bearbeiten"}
            />

            <Form
                ref={this.formRef}
                name="basic"
                labelCol={{xs: 24, sm: 6, md: 4, lg: 4, xl: 3, xxl: 2}}
                wrapperCol={{xs: 24, sm: 18, md: 16, lg: 16, xl: 14, xxl: 12}}
            >
                <Form.Item
                    label="Nutzername"
                    name="username"
                    rules={[{required: true, message: 'Bitte Nutzername ausfüllen'}]}
                >
                    <Input autoFocus/>
                </Form.Item>

                <Form.Item
                    label="E-Mail"
                    name="email"
                    rules={[{required: true, type: "email", message: 'Bitte eine valide E-Mail eintragen'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Administrator"
                    name="admin"
                    valuePropName="checked"
                >
                    <Checkbox/>
                </Form.Item>

                <Form.Item
                    label="Login gesperrt"
                    name="blocked"
                    valuePropName="checked"
                >
                    <Checkbox/>
                </Form.Item>

                <Form.Item
                    label="Role"
                    name="roleId"
                >
                    <Select
                        loading={this.state.rolesLoading}
                        disabled={this.state.rolesLoading}
                        showSearch
                        optionFilterProp="children"
                    >
                        <Option value={undefined}>keine</Option>
                        {this.state.roles?.map(value => <Option value={value.id}>{value.name}</Option>)}
                    </Select>
                </Form.Item>

                <Divider orientation="left">Persönliche Informationen</Divider>

                <Form.Item
                    label="Geschlecht"
                    name="gender"
                    rules={[{required: true, message: "Geschlecht muss angegeben werden!"}]}
                >
                    <Select style={{width: "100%"}} defaultValue="UNKNOWN">
                        <Option value="MALE">Männlich</Option>
                        <Option value="FEMALE">Weiblich</Option>
                        <Option value="OTHER">Divers</Option>
                        <Option value="UNKNOWN">Keine Angabe</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Vorname"
                    name="firstname"
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Nachname"
                    name="lastname"
                >
                    <Input/>
                </Form.Item>

                <SaveButton onClick={() => this.handleSave()} loading={this.state.loading}/>
                <DeleteButton onClick={() => this.handleDelete()} loading={this.state.loading}/>
                <Button icon={<Icon icon={faKey}/>} onClick={e => this.handlePasswordChange()}>Passwort ändern</Button>

            </Form>
        </>;
    }


    private handleSave() {
        this.formRef.current!.validateFields()
            .then(value => value as Account)
            .then(value => {
                this.setState({loading: true});
                if (this.isNew()) {
                    AccountApi.postEntry(value).then(response => {
                        this.formRef.current!.setFieldsValue(response);
                        this.setState({accountId: response.id ? response.id : "new"})
                    });
                } else {
                    AccountApi.putEntry(this.state.accountId, value).then(response => {
                        this.formRef.current!.setFieldsValue(response);
                    })
                }
                this.setState({loading: false});
            });
    }


    private handleDelete() {
        AccountApi.deleteEntry(this.state.accountId)
            .then(() => this.setState({redirect: true}));
    }

    private isNew() {
        return this.state.accountId === 'new';
    }

    private async handlePasswordChange() {
        await MyAccountApi.requestNewPassword(this.state.account?.username || "-");
        message.success("Passwort-Email angefordert.");
    }
}

export default AccountsDetailView;
