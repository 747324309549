import React from 'react';
import './App.css';
import {Alert, Button, Form, Input, message, Modal} from "antd";
import Security from "../global/Security";
import AccountModal from "./AccountModal";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import Icon from "./components/Icon";
import MyAccountApi from "../apis/MyAccountApi";


type Props = {}

type State = {
    loggedIn: boolean,
    loginDialogVisible: boolean,
    loginRequestOngoing: boolean,
    username: string,
    password: string,
    showAccountModal: boolean,
    hint?: string
}

class LoginMenu extends React.Component<Props, State> {

    state: State = {
        loggedIn: Security.get().isLoggedIn(),
        loginDialogVisible: false,
        username: Security.get().getUsername(),
        password: "",
        loginRequestOngoing: false,
        showAccountModal: false,
    }

    form: any;

    layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };

    tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    };

    componentDidMount() {
        Security.get().registerCallback(loggedIn => {
            this.setState({loginRequestOngoing: false, loginDialogVisible: !loggedIn, loggedIn: loggedIn})
        });

        if (!Security.get().isLoggedIn()) {
            //this.setState({loginDialogVisible: true})
        }

    }

    render() {
        return this.state.loggedIn ? <>
                <Button
                    type="text" className="spaced-button"
                    onClick={() => this.showUserProfile()}
                    icon={<Icon className="white-font" icon={faUser}/>}
                    title={this.state.username}
                />
                <Button
                    type="text" className="spaced-button"
                    onClick={() => Security.get().logout()}
                    icon={<Icon className="white-font" icon={faSignOutAlt}/>}
                    title="Logout"
                />
                {this.state.showAccountModal ? <AccountModal parent={this}/> : null}
            </>
            :
            <>
                <Button className="spaced-button white-font" type="text"
                        onClick={() => this.setState({loginDialogVisible: true})}>Login</Button>
                {this.renderModal()}
            </>;
    }

    login = () => {
        this.setState({loginRequestOngoing: true})
        Security.get().login(this.state.username, this.state.password, this.onFailedLogin);
    }

    onFailedLogin = (errorMessage: string | null) => {
        errorMessage = errorMessage === null ? "Fehler!" : errorMessage;
        message.error(errorMessage);
        this.setState({loginRequestOngoing: false});
    }

    private renderModal() {
        return (
            <Modal
                visible={this.state.loginDialogVisible}
                title="Login"
                footer={null}
                centered={true}
                maskClosable={false}
                closable={true}
                onCancel={() => this.setState({loginDialogVisible: false})}
            >
                <Form
                    {...(this.layout)}
                    name="basic"
                    initialValues={{remember: true}}
                    onFinish={this.login}
                >
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[{required: true, message: 'Username erforderlich!'}]}
                    >
                        <Input autoFocus
                               value={this.state.username}
                               onChange={event => this.setState({username: event.target.value})}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{required: true, message: 'Passwort erforderlich!'}]}
                    >
                        <Input.Password
                            value={this.state.password}
                            onChange={event => this.setState({password: event.target.value})}
                        />
                    </Form.Item>

                    {this.state.hint ? <Form.Item {...(this.tailLayout)} >
                        <Alert type="warning" message={this.state.hint}/>
                    </Form.Item> : null}

                    <Form.Item {...(this.tailLayout)} >
                        <Button type="primary" htmlType="submit" loading={this.state.loginRequestOngoing}>
                            Login
                        </Button>
                        <Button type="link" loading={this.state.loginRequestOngoing}
                                onClick={e => this.sendPasswordChangeRequest()}>
                            Passwort vergessen
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }

    private showUserProfile() {
        this.setState({showAccountModal: true});
    }

    private sendPasswordChangeRequest() {
        this.setState({hint: "Es wurde eine Email zur Passwortänderung versendet."});
        MyAccountApi.requestNewPassword(this.state.username);
    }
}

export default LoginMenu;
