import React from 'react';
import './App.css';
import 'antd/dist/antd.less';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {BackTop, Button, Col, Layout, Row} from "antd";
import {Content, Header} from "antd/es/layout/layout";
import LoginMenu from "./LoginMenu"
import NotFound from "../pages/NotFound";

import ResponsiveSidebar from "./menu/ResponsiveSidebar";
import {Navigation} from "./menu/Navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import {Config} from "./Config";
import {LocalSettingsView} from "../pages/localsettings/LocalSettingsView";
import MetaApi from "../apis/MetaApi";
import {PasswordReset} from "../pages/external/PasswordReset";

type Props = {}

type State = {
    drawerOpened: boolean,
    drawSize: number,
    versionString: string
}

class App extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            drawerOpened: Config.getBool("sider-default-open"),
            drawSize: window.innerHeight,
            versionString: "-"
        }

        window.onresize = () => this.setState({drawSize: window.innerHeight});
    }

    async componentDidMount() {
        const status = await MetaApi.getGitStatus();
        this.setState({versionString: `${status.buildVersion} (${status.idShort})`})
    }

    //https://www.youtube.com/watch?v=rFnfvhtrNbQ
    render() {
        return (
            <BrowserRouter>
                <Layout>
                    <Header className="app-bar green-background" title="Test">
                        <Row>
                            <Col>
                                <Button type="text" className="header-button green-background" onClick={this.toggle}>
                                    <FontAwesomeIcon icon={faBars} className="white-font drawer-icon"/>
                                </Button>
                            </Col>

                            <Col flex="auto">
                                <p className="white-font header-title" onClick={this.close}>Your Finanz-Manager</p>
                            </Col>
                            <Col>
                                <LoginMenu/>
                            </Col>
                        </Row>
                    </Header>

                    <Layout>

                        <ResponsiveSidebar drawerOpened={this.state.drawerOpened}
                                           parent={this} versionString={this.state.versionString}/>

                        <Content className="main-content" style={{
                            height: window.innerHeight - 64,
                            overflow: "auto"
                        }}>
                            <Switch>
                                {
                                    Navigation.getAccessible(true).map(value => {
                                        return <Route key={value.orderKey} exact path={value.path}
                                                      component={value.view}/>
                                    })
                                }
                                <Route exact path="/local-settings" component={LocalSettingsView}/>
                                <Route exact path="/external/password-reset" component={PasswordReset}/>
                                <Route component={NotFound}/>
                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
                <BackTop/>
            </BrowserRouter>
        );

    }

    toggle = () => {
        this.setState({
            drawerOpened: !this.state.drawerOpened,
        });
    };

    close = () => {
        this.setState({
            drawerOpened: false,
        });
    };
}

export default App;
