import React, {CSSProperties, ReactNode} from "react";
import {Redirect} from "react-router-dom";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Button from "antd/es/button";
import Icon from "../Icon";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import Search from "antd/es/input/Search";
import {BaseResponse} from "../../../data/BaseResponse";
import {LoadingView} from "./LoadingView";
import {Timeline} from "antd";
import TimelineItem from "antd/es/timeline/TimelineItem";
import Formatter from "../../../global/Formatter";
import moment from "moment";
import DateHelper from "../../../global/DateHelper";


export type DateGroupable = {
    date: string,
    dateAsMoment?: moment.Moment
}

export interface TimelineGroup<TYPE extends BaseResponse> {
    date: string,
    dateAsMoment: moment.Moment
    entries: TYPE[]
}

export interface OverviewState {
    loading: boolean
    selectedId?: string
    query: string
}

export interface OverviewProps {

}

export abstract class OverviewView<PROPS extends OverviewProps, STATE extends OverviewState, TYPE extends BaseResponse> extends LoadingView<PROPS, STATE> {

    private cardStyle: CSSProperties = {textAlign: "center", fontSize: "16px", padding: "4px"};

    render() {

        if (this.state.selectedId) {
            return <Redirect to={`${this.getBasePath()}/${this.state.selectedId}`} push/>
        }

        return <>
            <div className="sticky">
                {
                    this.showAddButton() ?
                        <Row style={{margin: "10px"}}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                <Button onClick={() => this.onAddButton()} loading={this.state.loading}>
                                    <Icon icon={faPlus}/> Hinzufügen
                                </Button>
                                {this.renderAdditionalButtons()}
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={16} xxl={16}>
                                {this.renderSearchInput()}
                            </Col>
                        </Row>
                        :
                        <Row style={{margin: "10px"}}>
                            {this.renderSearchInput()}
                        </Row>
                }
                {this.renderAdditionalContent()}
            </div>
            {this.renderList()}
        </>;
    }

    private renderSearchInput() {
        return <Search
            size="middle"
            disabled={this.state.loading}
            enterButton
            onChange={event => {
                this.setState({query: event.target.value})
            }}
            onSearch={() => this.onSearch()}
            placeholder="Suche"
            value={this.state.query}
            loading={this.state.loading}
        />;
    }

    protected abstract getBasePath(): string;

    protected abstract renderList(): ReactNode;

    protected abstract onSearch(): any;

    protected showAddButton(): boolean {
        return true;
    }

    protected onAddButton(): void {
        this.setState({selectedId: "new"})
    }

    protected onItemSelected(item: TYPE): void {
        this.setState({selectedId: item.id});
    }

    protected renderAdditionalButtons(): ReactNode {
        return <></>;
    }

    protected renderAdditionalContent(): ReactNode {
        return <></>;
    }

    protected pushBasicStateToUrl() {
        window.history.pushState("", "Query", `${this.getBasePath()}?query=${this.state.query}`);
    }

    protected async loadBaseQueryFromUrl(): Promise<URLSearchParams> {
        const params = new URLSearchParams(window.location.search);
        await this.setState({query: params.get("query") || ""})
        return params;
    }

    //Komponentenrenderer
    protected renderBasicCardList(data: TYPE[], bodyRenderer: (item: TYPE) => ReactNode, skeletonHeight?: number) {
        return this.renderBasicCardListBase(data, bodyRenderer, (item) => this.onItemSelected(item), skeletonHeight);
    }

    protected generateDummyData(customGenerator?: (item: any) => any): TYPE[] {
        const data: TYPE[] = [];
        for (let i = 0; i < 10; i++) {
            //Juckt uns nicht, da wir eh keine Pseudodaten render, sonder nur skeletons...
            if (customGenerator) {
                // @ts-ignore
                data.push(customGenerator({id: "" + i}));
            } else {
                // @ts-ignore
                data.push({id: "" + i});

            }
        }
        return data;
    }

    //Timeline:
    protected renderTimeline(data: TimelineGroup<TYPE>[], itemRenderer: (item: TYPE) => React.ReactNode, comparator: (a: TYPE, b: TYPE) => number): React.ReactNode {
        return <Timeline mode="left" style={{paddingTop: "10px"}}>
            {data.map(value => this.renderTimelineElement(value, itemRenderer, comparator))}
        </Timeline>;
    }

    private renderTimelineElement(value: TimelineGroup<TYPE>, itemRenderer: (item: TYPE) => React.ReactNode, comparator: (a: TYPE, b: TYPE) => number): React.ReactNode {
        return <TimelineItem>
            <p style={{fontSize: "17px"}}><b>{Formatter.formatDate(value.date)}</b></p>
            {value.entries.sort(comparator).map(entry => itemRenderer(entry))}
        </TimelineItem>
    }

    protected groupAndTransformTimelineData(list: (TYPE & DateGroupable)[], filter?: (item: TYPE) => boolean): TimelineGroup<TYPE>[] {
        const groupedData: TimelineGroup<TYPE>[] = [];
        list.map(value => value.dateAsMoment = DateHelper.toMomentDate(value.date));
        list.forEach(value => {
            if ((!filter || filter(value)) && !this.timelineContains(groupedData, value.date)) {
                groupedData.push({
                    date: value.date,
                    dateAsMoment: DateHelper.toMomentDate(value.date) || moment(),
                    entries: []
                });
            }
            this.timelineGet(groupedData, value.date)?.push(value);
        })
        return this.timelineSort(groupedData);
    }

    private timelineContains(data: TimelineGroup<TYPE>[], key: string) {
        return data.filter(value => value.date === key).length > 0;
    }

    private timelineGet(data: TimelineGroup<TYPE>[], key: string): TYPE[] | undefined {
        const value = data.filter(value => value.date === key);
        if (value.length === 1) {
            return value[0].entries;
        }
        return undefined;
    }

    private timelineSort(data: TimelineGroup<TYPE>[]) {
        return data.sort((a, b) => b.dateAsMoment.diff(a.dateAsMoment));
    }
}