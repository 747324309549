import React, {CSSProperties} from 'react';
import Formatter from "../../../global/Formatter";
import {PreciousMetalPrices, PreciousMetalType} from "../../../data/PreciousMetal";
import {Button, Card, Col, Modal, Row} from "antd";
import PreciousMetalApi from "../../../apis/PreciousMetalApi";
import PreciousMetalPriceWidget from "./PreciousMetalPriceWidget";
import Security from "../../../global/Security";
import {faSync} from "@fortawesome/free-solid-svg-icons/faSync";
import Icon from "../../../frame/components/Icon";

interface State {
    currentPrices?: PreciousMetalPrices
    pricesLoading: boolean
    showPriceHistory: boolean
    priceHistoryType: PreciousMetalType
}

interface Props {
}

class PreciousMetalPriceOverviewWidget extends React.Component<Props, State> {

    private cardStyle: CSSProperties = {textAlign: "center", fontSize: "20px"};

    constructor(props: Props) {
        super(props);
        this.state = {
            pricesLoading: true,
            showPriceHistory: false,
            priceHistoryType: PreciousMetalType.GOLD
        }
    }

    componentDidMount() {
        this.update();
    }

    render() {

        return <>
            <h2>Aktuelle Edelmetallpreise</h2>

            {
                this.state.showPriceHistory ?
                    <Modal
                        visible
                        title="Edelmetallpreise (oz)"
                        onCancel={() => this.setState({showPriceHistory: false})}
                        footer={null}
                        bodyStyle={{margin: 5, padding: 5}}
                        width={window.innerWidth < 600 ? "100%" : "75%"}
                        centered
                    >
                        <PreciousMetalPriceWidget displayedType={this.state.priceHistoryType}/>
                    </Modal> :
                    null
            }


            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                    <Card hoverable size="small" loading={this.state.pricesLoading} style={this.cardStyle}
                          onClick={() => this.showPriceHistory(PreciousMetalType.GOLD)}>
                        <span>Gold: {Formatter.formatCurrency(this.state.currentPrices?.priceGold)}</span>
                    </Card>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                    <Card hoverable size="small" loading={this.state.pricesLoading} style={this.cardStyle}
                          onClick={() => this.showPriceHistory(PreciousMetalType.SILVER)}>
                        <span>Silber: {Formatter.formatCurrency(this.state.currentPrices?.priceSilver)}</span>
                    </Card>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                    <Card hoverable size="small" loading={this.state.pricesLoading} style={this.cardStyle}
                          onClick={() => this.showPriceHistory(PreciousMetalType.PLATINUM)}>
                        <span>Platin: {Formatter.formatCurrency(this.state.currentPrices?.pricePlatinum)}</span>
                    </Card>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                    <Card hoverable size="small" loading={this.state.pricesLoading} style={this.cardStyle}
                          onClick={() => this.showPriceHistory(PreciousMetalType.PALLADIUM)}>
                        <span>Palladium: {Formatter.formatCurrency(this.state.currentPrices?.pricePalladium)}</span>
                    </Card>
                </Col>
            </Row>
            <p><i style={{color: "gray"}}>Letztes
                update: {Formatter.formatTime(this.state.currentPrices?.timestamp)}
                <Button onClick={() => this.update()} type="link"><Icon icon={faSync}/> Aktualisieren</Button>
            </i></p>
        </>
    }

    async update() {
        this.setState({pricesLoading: true})
        const value = await PreciousMetalApi.getCurrentPrice()
        this.setState({
                currentPrices: value,
                pricesLoading: false
            }
        );
    }

    private showPriceHistory(type: PreciousMetalType) {
        if (Security.get().isLoggedIn()) {
            this.setState({priceHistoryType: type, showPriceHistory: true});
        }
    }
}

export default PreciousMetalPriceOverviewWidget;