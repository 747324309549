import {Role} from "../data/Account";
import Rest from "./Rest";

class AccountApi {

    public static getAll(): Promise<Role[]> {
        return Rest.getMultiple(`/roles`);
    }

}

export default AccountApi;