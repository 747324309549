import React from 'react';
import StatisticApi from "../../apis/StatisticApi";
import {ChartData} from "chart.js";
import {ChartHelper} from "../../frame/charts/ChartHelper";
import {Line} from "react-chartjs-2";
import Formatter from "../../global/Formatter";
import {WidgetBase} from "./WidgetBase";

interface State {
    loading: boolean,
    data: ChartData<"line">,
    averageBalance: number
}

interface Props {
    from: string,
    to: string
}

class TotalIncomePerMontWidget extends WidgetBase<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            data: {
                labels: [],
                datasets: []
            },
            loading: true,
            averageBalance: 0
        }
    }

    async componentDidMount() {
        await this.update();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.from !== prevProps.from || this.props.to !== prevProps.to) {
            await this.update();
        }
    }

    private async update() {
        this.setState({loading: true});
        const rawData = await StatisticApi.getAmountsPerMonth(this.props.from, this.props.to);
        let data: ChartData<"line"> = {
            labels: [],
            datasets: [],
        };

        data.labels = rawData.map(value => value.label);
        const colors = ChartHelper.hardCodedPalette(3, "light");

        data.datasets.push({
            label: 'Einnahmen',
            data: rawData.map(value => value.income),
            fill: false,
            backgroundColor: colors[0],
            borderColor: colors[0],
            borderWidth: 4
        });

        data.datasets.push({
            label: 'Ausgaben',
            data: rawData.map(value => value.expenses),
            fill: false,
            backgroundColor: colors[1],
            borderColor: colors[1],
            borderWidth: 4
        });

        data.datasets.push({
            label: 'Bilanz',
            data: rawData.map(value => value.balance),
            fill: false,
            backgroundColor: colors[2],
            borderColor: colors[2],
            borderWidth: 4,
        });
        const averageBalance = rawData.map(value => value.balance).reduce((a, b) => a + b, 1) / rawData.length;
        this.setState({data, averageBalance, loading: false});
    }

    render() {
        return <>
            {this.loadable(<h2>Bilanz je Monat (⌀ {Formatter.formatCurrency(this.state.averageBalance)})</h2>, 50)}
            {this.loadable(<Line data={this.state.data} options={ChartHelper.BALANCE_SETTINGS}/>, 500)}
        </>
    }

}

export default TotalIncomePerMontWidget;