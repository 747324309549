import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface State {
}

interface Props {
    icon: IconProp
    className?: string
    color?: string
}

export default class Icon extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {}
    }

    render() {
        return <FontAwesomeIcon color={this.props.color} className={this.props.className} style={{marginRight: 5}} icon={this.props.icon}/>;
    }

}
