import Rest from "./Rest";
import {PreciousMetal, PreciousMetalPrices} from "../data/PreciousMetal";
import {PricePrecision} from "../data/PricePrecision";

class PreciousMetalApi {

    public static async getCurrentPrice(): Promise<PreciousMetalPrices> {
        let result = await Rest.getSpecific<PreciousMetalPrices>("/precious-metals/prices/current");
        localStorage.setItem("precious-metal-prices", JSON.stringify(result));
        return result;
    }

    public static getAllPrices(precision: PricePrecision): Promise<PreciousMetalPrices[]> {
        return Rest.getMultiple(`/precious-metals/prices/query?precision=${precision}`);
    }

    public static query(query?: string): Promise<PreciousMetal[]> {
        return Rest.getMultiple(`/precious-metals?query=${query}`);
    }

    public static get(id?: string): Promise<PreciousMetal> {
        return Rest.getSpecific(`/precious-metals/${id}`);
    }

    public static put(id: string, value: PreciousMetal): Promise<PreciousMetal> {
        return Rest.put(value, `/precious-metals/${id}`);
    }

    public static post(value: PreciousMetal): Promise<PreciousMetal> {
        return Rest.post(value, `/precious-metals`);
    }

    public static delete(id?: string): Promise<any> {
        return Rest.delete(`/precious-metals/${id}`);
    }


}

export default PreciousMetalApi;