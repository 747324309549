import {Instrument} from "../../data/Instrument";
import {Button, Modal} from "antd";
import Icon from "../../frame/components/Icon";
import {faClipboard} from "@fortawesome/free-solid-svg-icons/faClipboard";
import React from "react";
import {Utils} from "../../frame/Utils";
import {InstrumentSearchView} from "./InstrumentSearchView";
import {faLink} from "@fortawesome/free-solid-svg-icons/faLink";

//https://www.finanzfluss.de/informer/etf/ie00b6r52259/
export class InstrumentRenderingHelper {

    public static renderWknIsin(instrument: Instrument) {
        const buttonStyle = {paddingLeft: 0, fontSize: "13px"};
        return <>
            <Button size="small" style={buttonStyle} type="text" onClick={e => {
                Utils.toClipboard(instrument.wkn);
                e.stopPropagation();
            }}>
                <Icon icon={faClipboard} color="grey"/> {instrument.wkn}
            </Button>
            <Button size="small" style={buttonStyle} type="text" onClick={e => {
                Utils.toClipboard(instrument.isin);
                e.stopPropagation();
            }}>
                <Icon icon={faClipboard} color="grey"/> {instrument.isin}
            </Button>
            <Button size="small" style={buttonStyle} type="text" onClick={e => {
                window.open(`https://www.finanzfluss.de/informer/etf/${instrument.isin}`, "_blank");
                e.stopPropagation();
            }}>
                <Icon icon={faLink} color="grey"/> Details
            </Button>
        </>
    }

    public static renderSelectionModal(onCancel: () => void, onItemSelected: (item?: Instrument) => void, defaultQuery?: string) {
        return <Modal
            visible={true}
            title="Instrument wählen"
            footer={null}
            centered={true}
            maskClosable={false}
            closable={true}
            onCancel={onCancel}
        >
            <InstrumentSearchView onItemSelected={onItemSelected} defaultQuery={defaultQuery}/>
        </Modal>;
    }


}