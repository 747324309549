import React from "react";
import {DatePicker, Form, InputNumber, Modal, Select} from "antd";
import PreciousMetalApi from "../../apis/PreciousMetalApi";
import {Option} from "antd/es/mentions";
import {PreciousMetal, PreciousMetalType} from "../../data/PreciousMetal";
import DateHelper from "../../global/DateHelper";
import {File} from "../../data/File";
import {DetailEditView, DetailEditViewProps, DetailEditViewState} from "../../frame/components/base/DetailEditView";
import PreciousMetalPriceWidget from "../home/widgets/PreciousMetalPriceWidget";
import Button from "antd/es/button";
import Icon from "../../frame/components/Icon";
import {faChartLine} from "@fortawesome/free-solid-svg-icons/faChartLine";
import {FilePreview} from "../../frame/components/FilePreview";

interface State extends DetailEditViewState {
    files: File[],
    entry?: PreciousMetal,
    showValueHistory: boolean
    currentTitlePicture?: File
}

interface Props extends DetailEditViewProps {

}

export default class PreciousMetalDetailView extends DetailEditView<Props, State, PreciousMetal> {

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            entryId: this.props.match.params.id,
            files: [],
            showValueHistory: false
        }
    }

    async componentDidMount() {
        this.update();
    }

    protected async update() {
        if (this.isNew()) {
            this.formRef.current!.setFieldsValue({
                type: PreciousMetalType.GOLD,
                weightGram: 0,
                purchasePrice: 0,
                amount: 1,
                dateAsMoment: DateHelper.toMoment(Date.now())
            });
        } else {
            const preciousMetal = await PreciousMetalApi.get(this.state.entryId);
            preciousMetal.dateAsMoment = DateHelper.toMomentDate(preciousMetal.date);
            this.formRef.current!.setFieldsValue(preciousMetal);
            this.setState({files: preciousMetal.files, entry: preciousMetal});
            this.updatePreview(preciousMetal.titlePictureId);
        }
        this.setState({loading: false});
    }


    renderForm() {
        return <>
            {this.renderDesignation()}
            {this.loadable(
                <Form.Item
                    label="Metal"
                    name="type"
                    rules={[{required: true, message: 'Bitte Metal ausfüllen!'}]}
                >
                    <Select>
                        <Option key="1" value="GOLD">Gold</Option>
                        <Option key="2" value="SILVER">Silber</Option>
                        <Option key="3" value="PLATINUM">Platin</Option>
                        <Option key="4" value="PALLADIUM">Palladium</Option>
                    </Select>
                </Form.Item>
            )}


            <Form.Item
                label="Gewicht [g]"
                name="weightGram"
                rules={[{required: true, message: 'Bitte Gewicht ausfüllen!'}]}
            >
                {this.loadable(<InputNumber style={{width: "100%"}} step={0.001} decimalSeparator=","/>)}
            </Form.Item>

            <Form.Item
                label="Kaufpreis [€]"
                name="purchasePrice"
                rules={[{required: true, message: 'Bitte Kaufpreis ausfüllen!'}]}
            >
                {this.loadable(<InputNumber style={{width: "100%"}} step={0.01} decimalSeparator=","/>)}
            </Form.Item>

            <Form.Item
                label="Anzahl"
                name="amount"
                rules={[{required: true, message: 'Bitte Anzahl ausfüllen!'}]}
            >
                {this.loadable(<InputNumber style={{width: "100%"}}/>)}
            </Form.Item>

            <Form.Item
                label="Kaufdatum"
                name="dateAsMoment"
                rules={[{type: "date"}]}
            >
                {this.loadable(<DatePicker style={{width: "100%"}} format="DD.MM.yyyy"/>)}
            </Form.Item>

            <Form.Item
                label="Prägejahr"
                name="mintingYear"
            >
                {this.loadable(<InputNumber style={{width: "100%"}}/>)}

            </Form.Item>

            <Form.Item
                label="Titelbild"
                name="titlePictureId"
                rules={[{required: false}]}
            >
                {this.loadable(
                    <Select onChange={e => this.updatePreview(e)}>
                        <Option key="1" value={"null"}> </Option>
                        {this.state.files.map(value => <Option key={value.id}
                                                               value={value.id}>{value.filename}</Option>)}
                    </Select>
                )}
            </Form.Item>

            <Form.Item
                label="Titelbild-Vorschau"
            >
                {this.loadable(this.state.currentTitlePicture ?
                    <FilePreview file={this.state.currentTitlePicture} previewable={true} width={300}/> : null)}

            </Form.Item>

            {this.renderAppendedFiles(this.state.files, this)}
            {this.renderDescription()}
            {this.renderValueHistoryModal()}
        </>;
    }

    private updatePreview(id?: string) {
        if (!this.state.files) {
            return;
        }
        let titlePictureFile = this.state.files.filter(value => value.id === id);
        if (titlePictureFile && titlePictureFile.length === 1) {
            this.setState({currentTitlePicture: titlePictureFile[0]});
        }else {
            this.setState({currentTitlePicture: undefined});
        }
    }

    private renderValueHistoryModal() {
        return this.state.showValueHistory ?
            <Modal
                visible
                title="Wertverlauf"
                onCancel={() => this.setState({showValueHistory: false})}
                footer={null}
                bodyStyle={{margin: 5, padding: 5}}
                width={window.innerWidth < 600 ? "100%" : "75%"}
                centered
            >
                <PreciousMetalPriceWidget displayedType={this.state.entry?.type || PreciousMetalType.GOLD}
                                          multiplier={this.calculateMultiplier()} alternativeTitle={"Wert"}/>
            </Modal> :
            null
    }

    private calculateMultiplier() {
        const amount = this.state.entry?.amount || 1;
        const oz = (this.state.entry?.weightGram || 1) / 31.1;
        return amount * oz;
    }


    //Overrides:
    protected prePersistActions(value: PreciousMetal): void {
        value.date = DateHelper.fromMomentDate(value.dateAsMoment) || "0";
        value.files = this.state.files;
    }

    protected createHandler() {
        return PreciousMetalApi.post;
    }

    protected deleteHandler() {
        return PreciousMetalApi.delete;
    }

    protected redirectTarget(): string {
        return "/precious-metals";
    }

    protected updateHandler() {
        return PreciousMetalApi.put;
    }

    protected additionalButtons() {
        return <Button onClick={() => this.setState({showValueHistory: true})}
                       style={{marginLeft: "6px"}}
                       loading={this.state.loading}
                       icon={<Icon icon={faChartLine}/>}>
            Wertverlauf anzeigen
        </Button>;
    }


}
