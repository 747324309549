import React from "react";
import {CryptoCurrencyHistory} from "../../../data/Crypto";
import CryptoApi from "../../../apis/CryptoApi";
import {Line} from "react-chartjs-2";
import {ChartHelper} from "../../../frame/charts/ChartHelper";
import Formatter from "../../../global/Formatter";
import {Radio} from "antd";

import {PricePrecision} from "../../../data/PricePrecision";
import {LoadingView} from "../../../frame/components/base/LoadingView";
import CryptoCurrencyDetailsView from "./CryptoCurrencyDetailsView";

interface State {
    history?: CryptoCurrencyHistory,
    currentPrice?: any,
    currentDiff?: any,
    borderColor: string,
    backgroundColor: string,
    loading: boolean,
    redirect: boolean,
    precision: PricePrecision
}

interface Props {
    currencyId: string,
    multiplier: number,
    parent?: CryptoCurrencyDetailsView
}

export default class CryptoCurrencyPriceWidget extends LoadingView<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            redirect: false,
            precision: "DAY",
            backgroundColor: "",
            borderColor: ""
        }
    }

    async componentDidMount() {
        this.fetchData(this.state.precision);
    }

    render() {

        const data = {
            labels: this.state.history?.prices.map(value => Formatter.formatDateTime(value.timestamp)),
            datasets: [{
                label: this.state.history?.currencyName,
                data: this.state.history ? this.state.history.prices.map(value => value.price * this.props.multiplier) : [],
                fill: true,
                backgroundColor: this.state.backgroundColor,
                borderColor: this.state.borderColor,
                borderWidth: 1
            }]
        }


        return <>
            {this.loadable(this.state.currentPrice)}
                    {this.loadable(this.state.currentDiff)}
            {this.loadable(
                <Radio.Group onChange={e => this.fetchData(e.target.value)} value={this.state.precision}>
                    <Radio value={"DAY"}>Tag</Radio>
                    <Radio value={"WEEK"}>Woche</Radio>
                    <Radio value={"MONTH"}>Monat</Radio>
                    <Radio value={"YEAR"}>Jahr</Radio>
                    <Radio value={"FOREVER"}>Gesamt</Radio>
                </Radio.Group>
            )}
                    {this.loadable(<Line data={data} options={ChartHelper.getPriceSettings(10)}/>, 500)}
        </>
    }

    private setHeaderAttributes(field: string) {
        ChartHelper.setHeaderAttributes(field, this.state.history?.prices || [], this, this.props.multiplier);
    }

    private async fetchData(value: PricePrecision) {
        this.setState({loading: true, precision: value});
        const history = await CryptoApi.getEntityDetails(this.props.currencyId, value);
        await this.setState({history, loading: false});
        this.setHeaderAttributes("price");
        this.props.parent?.setState({loading: false, title: history.currencyName});
    }
}

