import React from 'react';
import {Col, Row} from "antd";

interface State {

}

interface Props {
    firstElement: React.ReactNode,
    secondElement: React.ReactNode,
    thirdElement: React.ReactNode,
    className?: string
}

export class TreeSlotGrid extends React.Component<Props, State> {

    render() {
        return <Row style={{margin: "10px"}}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                <div className={this.getClassName()} style={this.getStyle()}>
                    {this.props.firstElement}
                </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                <div className={this.getClassName()} style={this.getStyle()}>
                    {this.props.secondElement}
                </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                <div className={this.getClassName()} style={this.getStyle()}>
                    {this.props.thirdElement}
                </div>
            </Col>
        </Row>;
    }

    private getStyle() {
        return {margin: "2px"};
    }

    private getClassName() {
        return this.props.className ? this.props.className : "";
    }
}
