import React from "react";
import {LoadingView, LoadingViewProps, LoadingViewState} from "../../frame/components/base/LoadingView";
import {ChartData} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import {ChartHelper} from "../../frame/charts/ChartHelper";
import {ChartPlugins} from "../../frame/charts/ChartPlugins";

export abstract class WidgetBase<PROPS extends LoadingViewProps, STATE extends LoadingViewState> extends LoadingView<PROPS, STATE> {
    protected renderDoughnut(assetData: ChartData<"doughnut">, title: string) {
        return this.loadable(
            <Doughnut data={assetData}
                      options={ChartHelper.getDoughnutOptions()}
                      plugins={[ChartPlugins.getDoughnutInnerRendererPlugin(title)]}
            />, 400
        );
    }

}