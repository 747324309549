import React from "react";
import {Account} from "../../data/Account";
import AccountApi from "../../apis/AccountApi";
import {OverviewState, OverviewView} from "../../frame/components/base/OverviewView";


interface State extends OverviewState {
    data: Account[]
}

interface Props {

}

class AccountsView extends OverviewView<Props, State, Account> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            query: "",
            data: this.generateDummyData()
        }
    }

    async componentDidMount() {
        await this.loadBaseQueryFromUrl();
        await this.onSearch();
    }

    protected renderList() {
        return this.renderBasicCardList(this.state.data, item => AccountsView.renderItem(item));
    }

    private static renderItem(item: Account) {
        return <b>{item.username}</b>;
    }

    protected getBasePath(): string {
        return "/accounts";
    }

    protected async onSearch() {
        await this.setState({loading: true, data: this.generateDummyData()})
        this.pushBasicStateToUrl();
        const accounts = await AccountApi.getAll(this.state.query);
        this.setState({loading: false, data: accounts});
    }
}

export default AccountsView;