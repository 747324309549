import HomeView from "../../pages/home/HomeView";
import React from "react";

import RecurringTransactionsView from "../../pages/recurringtransactions/RecurringTransactionsView";
import BudgetBookView from "../../pages/budgetbook/BudgetBookView";
import PreciousMetalView from "../../pages/preciousmetal/PreciousMetalView";
import CategoriesView from "../../pages/categories/CategoriesView";
import AccountsView from "../../pages/accounts/AccountsView";
import AccountsDetailView from "../../pages/accounts/AccountsDetailView";
import RecurringTransactionDetailView from "../../pages/recurringtransactions/RecurringTransactionDetailView";
import BudgetBookDetailView from "../../pages/budgetbook/BudgetBookDetailView";
import CategoriesDetailsView from "../../pages/categories/CategoriesDetailsView";
import PreciousMetalDetailView from "../../pages/preciousmetal/PreciousMetalDetailView";
import Security from "../../global/Security";
import CryptoCurrenciesView from "../../pages/crypto/currencies/CryptoCurrenciesView";
import {faHome} from "@fortawesome/free-solid-svg-icons/faHome";
import {faBtc} from "@fortawesome/free-brands-svg-icons/faBtc";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import Icon from "../components/Icon";
import CryptoCurrencyEntriesView from "../../pages/crypto/entries/CryptoCurrencyEntriesView";
import CryptoCurrencyEntryDetailView from "../../pages/crypto/entries/CryptoCurrencyEntryDetailView";
import CryptoCurrencyDetailsView from "../../pages/crypto/currencies/CryptoCurrencyDetailsView";
import AdminView from "../../pages/admin/AdminView";
import {faCogs} from "@fortawesome/free-solid-svg-icons/faCogs";
import {faBook} from "@fortawesome/free-solid-svg-icons/faBook";
import {AdminLogsView} from "../../pages/admin/logs/AdminLogsView";
import StatisticsOverviewPage from "../../pages/statistics/StatisticsOverviewPage";
import {faChartLine} from "@fortawesome/free-solid-svg-icons/faChartLine";
import {EnvironmentHandler} from "../env/EnvironmentHandler";
import {faFolder} from "@fortawesome/free-solid-svg-icons/faFolder";
import {AdminLogsDetailView} from "../../pages/admin/logs/AdminLogsDetailView";
import {MyFilesView} from "../../pages/files/MyFilesView";
import {MyFilesDetailView} from "../../pages/files/MyFilesDetailView";
import ConnectedInstitutionsOverview from "../../pages/bankingapi/ConnectedInstitutionsOverview";
import AddInstitutionView from "../../pages/bankingapi/AddInstitutionView";
import {ConnectedInstitutionDetailView} from "../../pages/bankingapi/ConnectedInstitutionDetailView";
import {AdoptEntriesView} from "../../pages/bankingapi/AdoptEntriesView";
import BudgetsDetailView from "../../pages/recurringtransactions/BudgetsDetailView";
import {faTools} from "@fortawesome/free-solid-svg-icons/faTools";
import {FuelcostCalculator} from "../../pages/tools/FuelcostCalculator";
import {ExternalTools} from "../../pages/tools/ExternalTools";
import {faChartPie} from "@fortawesome/free-solid-svg-icons/faChartPie";
import {InstrumentSearchView} from "../../pages/instruments/InstrumentSearchView";
import {InstrumentTransactionsView} from "../../pages/instruments/InstrumentTransactionsView";
import {InstrumentTransactionsDetailView} from "../../pages/instruments/InstrumentTransactionsDetailView";
import {InstrumentPlanView} from "../../pages/instruments/plan/InstrumentPlanView";
import {InstrumentQuickAdd} from "../../pages/instruments/InstrumentQuickAdd";
import {DatabaseView} from "../../pages/admin/DatabaseView";
import {InstrumentsSellView} from "../../pages/instruments/InstrumentsSellView";
import {BudgetBookHanseaticView} from "../../pages/budgetbook/BudgetBookHanseaticView";
import {InstrumentTransactionsScalable} from "../../pages/instruments/InstrumentTransactionsScalable";

export interface NavigationEntry {

    //Any, da ich keine Ahnung habe, was der typ ist...
    view?: any
    icon?: any
    path?: string
    orderKey: number
    label?: string
    urlMatcher?: RegExp
    mustBeAdmin: boolean
    menuEntry: boolean
    permission?: string[]
    devFeature?: boolean
    betaFeature?: boolean
    subEntries?: NavigationEntry []
}

export class Navigation {

    //Hint: Detailseiten können mit demselben orderKey markiert werden.
    private static ENTRIES: NavigationEntry[] = [
        {
            view: HomeView,
            icon: <Icon icon={faHome}/>,
            path: "/",
            orderKey: 100,
            label: "Home",
            urlMatcher: new RegExp("^/$"),
            mustBeAdmin: false,
            menuEntry: true
        },
        {
            label: "Haushaltsbuch",
            icon: <Icon icon={faBook}/>,
            mustBeAdmin: false,
            menuEntry: true,
            orderKey: 200,
            subEntries: [
                {
                    view: BudgetBookView,
                    path: "/budget-book",
                    orderKey: 210,
                    label: "Einträge",
                    urlMatcher: new RegExp("^/budget-book$"),
                    mustBeAdmin: false,
                    menuEntry: true
                },
                {
                    view: BudgetBookDetailView,
                    path: "/budget-book/:id",
                    orderKey: 210,
                    urlMatcher: new RegExp("^/budget-book/.*$"),
                    mustBeAdmin: false,
                    menuEntry: false
                },
                {
                    view: BudgetBookHanseaticView,
                    path: "/budget-book-hanseatic",
                    orderKey: 210,
                    urlMatcher: new RegExp("^/budget-book.*$"),
                    mustBeAdmin: false,
                    menuEntry: false
                },
                {
                    view: RecurringTransactionsView,
                    path: "/recurring-transactions",
                    orderKey: 220,
                    label: "Wiederkehrende",
                    urlMatcher: new RegExp("^/recurring-transactions$"),
                    mustBeAdmin: false,
                    menuEntry: true
                },
                {
                    view: RecurringTransactionDetailView,
                    path: "/recurring-transactions/:id",
                    orderKey: 220,
                    urlMatcher: new RegExp("^/recurring-transactions/.*$"),
                    mustBeAdmin: false,
                    menuEntry: false
                },
                {
                    view: BudgetsDetailView,
                    path: "/budgets/:id",
                    orderKey: 220,
                    urlMatcher: new RegExp("^/recurring-transactions/.*$"),
                    mustBeAdmin: false,
                    menuEntry: false
                },
                {
                    view: CategoriesView,
                    path: "/categories",
                    orderKey: 230,
                    label: "Kategorien",
                    urlMatcher: new RegExp("^/categories$"),
                    mustBeAdmin: false,
                    menuEntry: true
                },
                {
                    view: CategoriesDetailsView,
                    path: "/categories/:id",
                    orderKey: 230,
                    urlMatcher: new RegExp("^/categories/.*$"),
                    mustBeAdmin: false,
                    menuEntry: false
                },
                {
                    view: ConnectedInstitutionsOverview,
                    path: "/banking-api/connected",
                    orderKey: 240,
                    label: "Bankanbindung",
                    urlMatcher: new RegExp("^/banking-api/connected$"),
                    mustBeAdmin: false,
                    menuEntry: true,
                    betaFeature: true
                },
                {
                    view: ConnectedInstitutionDetailView,
                    path: "/banking-api/connected/:id",
                    orderKey: 240,
                    urlMatcher: new RegExp("^/banking-api/connected/.*$"),
                    mustBeAdmin: false,
                    menuEntry: false
                },
                {
                    view: AddInstitutionView,
                    path: "/banking-api/add-new",
                    orderKey: 240,
                    urlMatcher: new RegExp("^/banking-api/add-new$"),
                    mustBeAdmin: false,
                    menuEntry: false
                },
                {
                    view: AdoptEntriesView,
                    path: "/banking-api/adopt",
                    orderKey: 240,
                    urlMatcher: new RegExp("^/banking-api/adopt$"),
                    mustBeAdmin: false,
                    menuEntry: false
                }
            ]
        },
        {
            label: "Finanzinstrumente",
            icon: <Icon icon={faChartLine}/>,
            mustBeAdmin: false,
            menuEntry: true,
            orderKey: 250,
            subEntries: [
                {
                    view: InstrumentSearchView,
                    path: "/instruments",
                    orderKey: 260,
                    label: "Instrumente",
                    urlMatcher: new RegExp("^/instruments$"),
                    mustBeAdmin: true,
                    devFeature: true,
                    menuEntry: true
                },
                {
                    view: InstrumentPlanView,
                    path: "/instruments/plan",
                    orderKey: 270,
                    label: "Investitionsplan",
                    urlMatcher: new RegExp("^/instruments/plan$"),
                    mustBeAdmin: false,
                    menuEntry: true,
                    betaFeature: true
                },
                {
                    view: InstrumentTransactionsView,
                    path: "/instruments/transactions",
                    orderKey: 280,
                    label: "Transaktionen",
                    urlMatcher: new RegExp("^/instruments/transactions$"),
                    mustBeAdmin: false,
                    menuEntry: true,
                    betaFeature: true
                },
                {
                    view: InstrumentTransactionsDetailView,
                    path: "/instruments/transactions/:id",
                    orderKey: 290,
                    urlMatcher: new RegExp("^/instruments/transactions/.*$"),
                    mustBeAdmin: false,
                    menuEntry: false
                },
                {
                    view: InstrumentQuickAdd,
                    path: "/instruments/quick",
                    orderKey: 291,
                    urlMatcher: new RegExp("^/instruments/quick$"),
                    mustBeAdmin: false,
                    menuEntry: false
                },
                {
                    view: InstrumentsSellView,
                    path: "/instruments/sell",
                    orderKey: 292,
                    urlMatcher: new RegExp("^/instruments/sell$"),
                    mustBeAdmin: false,
                    menuEntry: false
                },
                {
                    view: InstrumentTransactionsScalable,
                    path: "/instruments/scalable",
                    orderKey: 293,
                    urlMatcher: new RegExp("^/instruments/scalable$"),
                    mustBeAdmin: false,
                    menuEntry: false
                }
            ]
        },
        {
            view: PreciousMetalView,
            icon: <Icon icon={faCoins}/>,
            path: "/precious-metals",
            orderKey: 300,
            label: "Edelmetalle",
            urlMatcher: new RegExp("^/precious-metals$"),
            mustBeAdmin: false,
            menuEntry: true
        },
        {
            view: PreciousMetalDetailView,
            path: "/precious-metals/:id",
            orderKey: 300,
            urlMatcher: new RegExp("^/precious-metals/.*$"),
            mustBeAdmin: false,
            menuEntry: false
        },

        {
            label: "Krypto",
            icon: <Icon icon={faBtc}/>,
            orderKey: 400,
            mustBeAdmin: false,
            menuEntry: true,
            subEntries: [
                {
                    view: CryptoCurrenciesView,
                    path: "/crypto/currencies",
                    orderKey: 410,
                    label: "Preise",
                    urlMatcher: new RegExp("^/crypto/currencies$"),
                    mustBeAdmin: false,
                    menuEntry: true
                },
                {
                    view: CryptoCurrencyDetailsView,
                    path: "/crypto/currencies/:id",
                    orderKey: 410,
                    urlMatcher: new RegExp("^/crypto/currencies/.*$"),
                    mustBeAdmin: false,
                    menuEntry: false
                },
                {
                    view: CryptoCurrencyEntriesView,
                    path: "/crypto/entries",
                    orderKey: 420,
                    label: "Portfolio",
                    urlMatcher: new RegExp("^/crypto/entries$"),
                    mustBeAdmin: false,
                    menuEntry: true
                },
                {
                    view: CryptoCurrencyEntryDetailView,
                    path: "/crypto/entries/:id",
                    orderKey: 420,
                    urlMatcher: new RegExp("^/crypto/entries/.*$"),
                    mustBeAdmin: false,
                    menuEntry: false
                }
            ]
        },
        {
            view: StatisticsOverviewPage,
            icon: <Icon icon={faChartPie}/>,
            path: "/statics",
            orderKey: 500,
            label: "Statistiken",
            urlMatcher: new RegExp("^/statics$"),
            mustBeAdmin: false,
            menuEntry: true
        },
        {
            view: MyFilesView,
            icon: <Icon icon={faFolder}/>,
            path: "/files",
            orderKey: 600,
            label: "Dateien",
            urlMatcher: new RegExp("^/files$"),
            mustBeAdmin: false,
            menuEntry: true
        },
        {
            view: MyFilesDetailView,
            path: "/files/:id",
            orderKey: 600,
            urlMatcher: new RegExp("^/files/.*$"),
            mustBeAdmin: false,
            menuEntry: false
        },
        {
            label: "Tools",
            icon: <Icon icon={faTools}/>,
            orderKey: 650,
            mustBeAdmin: false,
            menuEntry: true,
            subEntries: [
                {
                    view: FuelcostCalculator,
                    path: "/tools/fuel",
                    orderKey: 660,
                    label: "Benzinkostenrechner",
                    urlMatcher: new RegExp("^/tools/fuel$"),
                    mustBeAdmin: false,
                    devFeature: true,
                    menuEntry: true
                },
                {
                    view: ExternalTools,
                    path: "/tools/external",
                    orderKey: 670,
                    label: "Externe Tools",
                    urlMatcher: new RegExp("^/tools/external$"),
                    mustBeAdmin: false,
                    menuEntry: true
                }
            ]
        },
        {
            view: AccountsView,
            icon: <Icon icon={faUser}/>,
            path: "/accounts",
            orderKey: 700,
            label: "Accounts",
            urlMatcher: new RegExp("^/accounts$"),
            mustBeAdmin: true,
            menuEntry: true
        },
        {
            view: AccountsDetailView,
            path: "/accounts/:id",
            orderKey: 700,
            urlMatcher: new RegExp("^/accounts/.*$"),
            mustBeAdmin: true,
            menuEntry: false
        },
        {
            label: "Monitoring",
            icon: <Icon icon={faCogs}/>,
            orderKey: 800,
            mustBeAdmin: true,
            menuEntry: true,
            subEntries: [
                {
                    view: AdminView,
                    path: "/admin/panel",
                    orderKey: 820,
                    label: "Systemzustand",
                    urlMatcher: new RegExp("^/admin/panel$"),
                    mustBeAdmin: true,
                    menuEntry: true
                },
                {
                    view: AdminLogsView,
                    path: "/admin/logs",
                    orderKey: 840,
                    label: "Logs",
                    urlMatcher: new RegExp("^/admin/logs$"),
                    mustBeAdmin: true,
                    menuEntry: true
                },
                {
                    view: AdminLogsDetailView,
                    path: "/admin/logs/:id",
                    orderKey: 840,
                    urlMatcher: new RegExp("^/admin/logs/.*$"),
                    mustBeAdmin: true,
                    menuEntry: false
                },
                {
                    view: DatabaseView,
                    path: "/admin/db",
                    orderKey: 850,
                    label: "Datenbankzustand",
                    urlMatcher: new RegExp("^/admin/db$"),
                    mustBeAdmin: true,
                    menuEntry: true
                }
            ]
        }
    ];

    public static getAccessible(flatMap: boolean, base?: NavigationEntry[]): NavigationEntry[] {

        let entries = base || Navigation.ENTRIES;

        if (!Security.get().isLoggedIn()) {
            return [Navigation.ENTRIES[0]];
        }

        if (!Security.get().isAdmin()) {
            entries = entries.filter(value => !value.mustBeAdmin);
        }

        if (EnvironmentHandler.isProd()) {
            entries = entries.filter(value => !value.devFeature);
        }

        if (flatMap) {
            return entries.flatMap(value => {
                if (value.subEntries) {
                    return value.subEntries;
                } else {
                    return value;
                }
            });
        }

        return entries;
    }

    public static getAccessibleInMenu(base?: NavigationEntry[]): NavigationEntry[] {
        return Navigation.getAccessible(false, base).filter(value => value.menuEntry);
    }

    public static getSelectedByLocation(): string[] {
        const currentPath = window.location.pathname;
        const res = Navigation.getAccessible(true).filter(value => currentPath.match(value.urlMatcher || "NONE"));
        if (res.length !== 1) {
            return [];
        }
        return [res[0].orderKey + ""];
    }

    public static getOpenedByLocation(): string[] | undefined {
        let selected = this.getSelectedByLocation()[0];
        if (selected) {
            const matches = Navigation.ENTRIES.filter(value => {
                return value.subEntries && value.subEntries.filter(subEntry => subEntry.orderKey + "" === selected).length > 0;
            });

            if (matches[0]) {
                return [matches[0].orderKey + ""];
            }
        }
    }


}