import React from 'react';
import QuickAddView from "../../localsettings/QuickAddView";
import Button from "antd/es/button";
import {Category} from "../../../data/Category";
import CategoryApi from "../../../apis/CategoryApi";
import {BudgetBookEntry} from "../../../data/BudgetBookEntry";
import DateHelper from "../../../global/DateHelper";
import {InputNumber} from "antd";
import BudgetBookApi from "../../../apis/BudgetBookApi";

interface State {
    categories: Category[]
    amount: number
}

interface Props {
}

export default class QuickAddWidget extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            categories: [],
            amount: 0
        }
    }

    async componentDidMount() {
        const categories = await CategoryApi.getAll("");
        this.setState({categories})
    }

    render() {
        return <>

            <h2>Schnelles hinzufügen</h2>

            <p>Betrag: <InputNumber value={this.state.amount} step={0.01} decimalSeparator="," style={{width: "100%"}}
                                    onChange={value => this.setNumber(value)}/></p>

            {QuickAddView.loadQuickAdds().map(value => {
                return <p><Button onClick={() => this.add(value.categoryId, value.designation)}>
                    Kategorie: {this.toCategoryString(value.categoryId)}</Button> Bezeichnung: {value.designation}</p>
            })}
        </>
    }

    private toCategoryString(categoryId: string) {
        const results = this.state.categories.filter(value => value.id === categoryId);

        if (results.length === 0) {
            return categoryId;
        } else {
            return results[0].designation;
        }

    }

    private add(categoryId: string, designation?: string) {

        let entry: BudgetBookEntry = {
            id: "",
            amount: this.state.amount,
            monthlyAmount: this.state.amount,
            date: DateHelper.fromMomentDate(DateHelper.todayAsMoment()) || "",
            designation: designation || "",
            category: {id: categoryId, designation: ""},
            description: "",
            files: []
        }
        BudgetBookApi.post(entry);
    }

    private setNumber(value: any) {
        this.setState({amount: value as number});
    }
}