import React from "react";
import {Institution, TransactionItem} from "../../data/bankingapi/BankingApiModel";
import {BankingApi} from "../../apis/BankingApi";
import {LoadingView, LoadingViewProps, LoadingViewState} from "../../frame/components/base/LoadingView";
import Row from "antd/es/row";
import Col from "antd/es/col";
import {Collapse} from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import Formatter from "../../global/Formatter";
import Button from "antd/es/button";
import Icon from "../../frame/components/Icon";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import DateHelper from "../../global/DateHelper";
import moment from "moment";
import BudgetBookApi from "../../apis/BudgetBookApi";
import {Redirect} from "react-router-dom";

interface State extends LoadingViewState {
    data: Map<Institution, TransactionItem[]>;
    selected: TransactionItem[];
    redirect: boolean;
}

interface Props extends LoadingViewProps {

}

export class AdoptEntriesView extends LoadingView<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            data: new Map(),
            selected: [],
            redirect: false
        }
    }

    async componentDidMount() {
        const institutions = await BankingApi.getMyInstitutions("");
        const data: Map<Institution, TransactionItem[]> = new Map();
        for (const value of institutions) {
            const accounts = await BankingApi.getInstitutionDetails(value.id);
            const entries: TransactionItem[] = [];
            for (const account of accounts.accounts) {
                const transactions = await BankingApi.getAccountTransactions(account.accountId);
                entries.push(...transactions.booked);
            }
            entries.forEach(entry => entry.institution = value);
            entries.forEach(entry => entry.bookingDateAsMoment = DateHelper.toMomentDate(entry.bookingDate) || moment());

            data.set(value, this.sortList(entries));
        }
        this.setState({data, loading: false});
    }

    render() {

        if(this.state.redirect) {
            return <Redirect to="/budget-book" push />
        }

        return <Row style={{margin: "10px"}}>

            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <h2>Gefundene Einträge</h2>
                {this.loadable(this.renderSelectGroupList(), 500)}
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <h2>Ausgewählte Einträge {this.renderAddButton()}</h2>
                {this.loadable(this.renderSelectedList(), 500)}
            </Col>
        </Row>;
    }

    private renderSelectGroupList() {
        const panels: React.ReactNode[] = [];
        this.state.data.forEach((value, key) => {
            panels.push(this.renderPane(key, value));
        });
        return <Collapse defaultActiveKey={[]}>
            {panels}
        </Collapse>
    }

    private renderPane(institution: Institution, value: TransactionItem[]) {
        return <CollapsePanel key={institution.id} header={institution.name}
                              extra={<img alt={institution.id} src={institution.logo} height={25}/>}>
            {this.renderBasicCardListBase(value,
                (item) => AdoptEntriesView.bodyRenderer(item),
                (item) => this.onItemSelected(item, institution)
            )}
        </CollapsePanel>
    }

    private renderSelectedList() {
        return this.renderBasicCardListBase(this.state.selected,
            (item) => AdoptEntriesView.bodyRenderer(item),
            (item: TransactionItem) => this.handleRemoveFromSelectedList(item)
        );
    }


    private handleRemoveFromSelectedList(item: TransactionItem) {
        const institution = item.institution;

        if (institution) {
            const list = this.state.data.get(institution)
            if (list) {
                list.push(item);
                AdoptEntriesView.removeItem(item, this.state.selected);
                this.state.data.set(institution, this.sortList(list));
            }
        }

        this.setState({selected: this.state.selected, data: this.state.data})
    }

    private static bodyRenderer(item: TransactionItem) {
        return <div className="low-margin">
            <p> {this.getDesignation(item)}</p>
            <p> {`Betrag: ${Formatter.formatCurrency(item.transactionAmount.amount)}`} </p>
            <p className="std-description"> {item.remittanceInformationUnstructured}</p>
        </div>;
    }

    public static getDesignation(item: TransactionItem) {
        return `${item.debtorName} -> ${item.creditorName} (${Formatter.formatDate(item.bookingDate)})`;
    }

    private onItemSelected(item: TransactionItem, key: Institution) {
        this.state.selected.push(item);
        AdoptEntriesView.removeItem(item, this.state.data.get(key));
        this.setState({selected: this.sortList(this.state.selected), data: this.state.data})
    }

    private static removeItem(item: TransactionItem, transactionItems: TransactionItem[] | undefined) {
        if (transactionItems) {
            const index = transactionItems.indexOf(item);
            if (index > -1) {
                transactionItems.splice(index, 1);
            }
        }

    }

    private renderAddButton() {
        return <Button onClick={() => this.onAddButton()} loading={this.state.loading}>
            <Icon icon={faPlus}/> Übernehmen
        </Button>
    }

    private onAddButton() {
        this.state.selected.forEach(value => {
            BudgetBookApi.addFromBankingEntry(value);
        });
        this.setState({redirect: true});
    }

    private sortList(list: TransactionItem[]): TransactionItem[] {
        return list.sort((a, b) => b.bookingDateAsMoment.diff(a.bookingDateAsMoment));
    }
}

