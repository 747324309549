import {LoginResponse} from "./LoginResponse";
import {OnFailCallback, SecurityCallback} from "./Callbacks";
import Rest from "../apis/Rest";
import {Config} from "../frame/Config";

//TODO: refactor...
class Security {

    private static instance: Security | null = null;
    private callBacks: SecurityCallback[] = [];
    private state?: LoginResponse;

    private constructor() {
        const valueFromStore = localStorage.getItem("loginState");
        if (valueFromStore) {
            this.state = JSON.parse(valueFromStore);
        }
    }

    public static get(): Security {
        if (this.instance === null) {
            this.instance = new Security();
        }
        return this.instance;
    }

    private static handleFailure(response: Response, onFail: OnFailCallback) {
        console.log(response);
        let message: string | null = response.headers.get("errors");
        onFail(message, response.status);
    }

    public login(username: string, password: string, onFail: OnFailCallback) {
        const url = Config.getBaseURL() + "/auth";
        const body = {username: username, password: password};


        fetch(url, {method: 'POST', body: JSON.stringify(body)})
            .then(response => response.ok ?
                this.handleSuccess(response) :
                Security.handleFailure(response, onFail))
            .catch(reason => Security.handleError(reason, onFail));
    }

    loginByGoogle(tokenId: string, onFail: OnFailCallback) {
        const url = Config.getBaseURL() + "/auth";
        const body = {googleAuthToken: tokenId};

        fetch(url, {method: 'POST', body: JSON.stringify(body)})
            .then(response => response.ok ?
                this.handleSuccess(response) :
                Security.handleFailure(response, onFail))
            .catch(reason => Security.handleError(reason, onFail));
    }

    public logout() {
        Rest.delete("/auth").then(() => {
            this.state = undefined;
            localStorage.removeItem("loginState");
            this.callBacks.forEach(value => value(false));
        })
    }

    public registerCallback(callback: SecurityCallback) {
        this.callBacks.push(callback);
    }

    private static handleError(reason: any, onFail: OnFailCallback) {
        console.log(reason);
        onFail("Verbindung konnte nicht aufgebaut werden!", 401);
    }

    private handleSuccess(response: Response) {
        console.log(response.headers.get("errors"));
        response.json()
            .then(data => data as LoginResponse)
            .then(data => this.setState(data))
            .then(() => this.callBacks.forEach(value => value(true)));
    }

    private setState(data: LoginResponse) {
        this.state = data;
        localStorage.setItem("loginState", JSON.stringify(data));
        window.location.href = "/";
    }

    getToken() : string {
        if (this.state) {
            return this.state.token;
        }
        return "";
    }

    getUsername() : string {
        if (this.state) {
            return this.state.username;
        }
        return "";
    }

    isAdmin() : boolean {
        return (this.isLoggedIn() && this.state?.admin) || false;
    }

    isLoggedIn() : boolean {
        return this.state !== undefined;
    }
}

export default Security;