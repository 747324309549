import React from "react";
import {Table} from "antd";
import Column from "antd/es/table/Column";

interface State {

}

interface Props {

}

export class DatabaseView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {}
    }

    render() {
        return <Table
            pagination={false}
            bordered
            size="small"
        >

            <Column title="Schema" dataIndex="schema" key="schema"/>
            <Column title="Table" dataIndex="table" key="table"/>
            <Column title="Anzahl" dataIndex="count" key="count"/>

        </Table>;
    }

}