import React from "react";
import {Checkbox, DatePicker, Form, InputNumber, Select} from "antd";
import {Option} from "antd/es/mentions";
import {Category} from "../../data/Category";
import CategoryApi from "../../apis/CategoryApi";
import moment from "moment";
import DateHelper from "../../global/DateHelper";
import RecurringTransactionsApi from "../../apis/RecurringTransactionsApi";
import RecurringTransactionEntry from "../../data/RecurringTransactionEntry";
import {DetailEditView, DetailEditViewProps, DetailEditViewState} from "../../frame/components/base/DetailEditView";
import Button from "antd/es/button";
import BudgetBookApi from "../../apis/BudgetBookApi";
import Icon from "../../frame/components/Icon";
import {faExchangeAlt} from "@fortawesome/free-solid-svg-icons/faExchangeAlt";


interface State extends DetailEditViewState {
    categoriesLoading: boolean
    categories?: Category[]
}

interface Props extends DetailEditViewProps {

}

export default class RecurringTransactionDetailView extends DetailEditView<Props, State, RecurringTransactionEntry> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            categoriesLoading: true,
            entryId: props.match.params.id
        }
    }

    componentDidMount() {
        this.update();
    }

    protected async update() {
        if (this.isNew()) {
            this.formRef.current?.setFieldsValue({
                beginDateMoment: DateHelper.toMoment(moment.now()),
                amount: 0,
                executionDay: 1,
                active: true
            });
            this.setState({loading: false});
        } else {
            let recurringTransactionEntry = await RecurringTransactionsApi.getEntry(this.state.entryId);
            recurringTransactionEntry.beginDateMoment = DateHelper.toMomentDate(recurringTransactionEntry.beginDate)
            this.formRef.current?.setFieldsValue(recurringTransactionEntry);
            this.setState({loading: false})
        }
        let categories = await CategoryApi.getAll("");
        this.setState({categories, categoriesLoading: false});
    }

    renderForm() {
        return <>
            {this.renderDesignation()}

            <Form.Item
                label="Ausführungstag"
                name="executionDay"
                required
            >
                {this.loadable(<InputNumber style={{width: "100%"}} step={1} min={1} max={31}/>)}
            </Form.Item>

            <Form.Item
                label="Beginndatum"
                name="beginDateMoment"
                required
            >
                {this.loadable(<DatePicker style={{width: "100%"}} format="DD.MM.yyyy"/>
                )}

            </Form.Item>

            <Form.Item
                label="Betrag"
                name="amount"
                required
            >
                {this.loadable(<InputNumber step={0.01} decimalSeparator="," style={{width: "100%"}}/>)}
            </Form.Item>

            <Form.Item
                label="Kategorie"
                name={["category", "id"]}
            >
                {this.loadable(
                    <Select
                        loading={this.state.categoriesLoading}
                        disabled={this.state.categoriesLoading}
                        showSearch
                        optionFilterProp="children"
                    >
                        <Option value={undefined}>keine</Option>
                        {this.state.categories?.map(value => <Option value={value.id}>{value.designation}</Option>)}
                    </Select>
                )}
            </Form.Item>
            <Form.Item
                label="Aktiv"
                name="active"
                valuePropName="checked"
            >
                {this.loadable(<Checkbox/>)}
            </Form.Item>



            {this.renderDescription()}
        </>;
    }


    protected additionalButtons() {

        let handler = async () => {
            let entry = await this.formRef.current!.validateFields() as RecurringTransactionEntry;
            BudgetBookApi.addFromRecurring(entry);
        };

        return <Button onClick={handler}
                       style={{marginLeft: "6px"}}
                       loading={this.state.loading}
                       icon={<Icon icon={faExchangeAlt}/>}>
            Übertragen
        </Button>;

    }


    protected deleteHandler() {
        return RecurringTransactionsApi.deleteEntry;
    }

    protected redirectTarget(): string {
        return "/recurring-transactions";
    }

    protected prePersistActions(value: RecurringTransactionEntry): void {
        value.beginDate = DateHelper.fromMomentDate(value.beginDateMoment) || "";
    }

    protected createHandler() {
        return RecurringTransactionsApi.postEntry;
    }

    protected updateHandler() {
        return RecurringTransactionsApi.putEntry;
    }


}
