import React from "react";
import {Constants} from "../../frame/Constants";
import {Button, Form, FormInstance, Input} from "antd";
import Icon from "../../frame/components/Icon";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import MyAccountApi from "../../apis/MyAccountApi";

export interface PasswordChange {
    password: string,
    password2: string,
    token: string
}

interface State {
    passwordTyped: boolean
}

interface Props {

}

export class PasswordReset extends React.Component<Props, State> {

    private formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {
            passwordTyped: false
        }
    }

    render() {
        return <>
            <h1>Passwort ändern</h1>
            <Form
                ref={this.formRef}
                name="basic"
                labelCol={Constants.LABEL_COL}
                wrapperCol={Constants.WRAPPER_COL}
            >
                <Form.Item
                    label="Passwort"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Passwort muss angegeben werden!"
                        },
                        {
                            type: "string",
                            pattern: new RegExp(".*[A-Z]+.*"),
                            message: "Passwort muss mindestens einen Großbuchstaben enthalten!"
                        },
                        {
                            type: "string",
                            pattern: new RegExp(".*[0-9]+.*"),
                            message: "Passwort muss mindestens eine Zahl enthalten!"
                        },
                        {
                            type: "string",
                            pattern: /.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+.*/gm,
                            message: "Passwort muss mindestens ein Sonderzeichen enthalten!"
                        },
                        {type: "string", min: 8, message: "Minimale Länge ist 8"}
                    ]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item
                    label="Wiederholen"
                    name="password2"
                    dependencies={["password"]}
                    rules={[
                        {
                            required: true,
                            message: "Bitte wiederholen sie ihr Passwort"
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Die Passwörter müssen gleich sein!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password/>
                </Form.Item>

                <Button icon={<Icon icon={faSave}/>} onClick={e => this.handleSave()}>Speichern</Button>

            </Form>
        </>
    }


    private async handleSave() {
        const values = await this.formRef.current?.validateFields() as PasswordChange;
        const params = new URLSearchParams(window.location.search);
        values.token = params.get("token") || "-";
        MyAccountApi.changePassword(values);
    }
}