import React from "react";
import {Category} from "../../data/Category";
import CategoryApi from "../../apis/CategoryApi";
import {OverviewProps, OverviewState, OverviewView} from "../../frame/components/base/OverviewView";
import {Card, Tree, TreeDataNode} from "antd";
import {Key} from "antd/lib/table/interface";

interface State extends OverviewState {
    expandedKey: Key[],
    treeData: TreeDataNode[]
}

interface Props extends OverviewProps {
}

class CategoriesView extends OverviewView<Props, State, Category> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            query: "",
            expandedKey: [],
            treeData: []
        }
    }

    async componentDidMount() {
        await this.loadBaseQueryFromUrl();
        await this.onSearch();
    }

    renderList() {
        return <>
            <Tree
                treeData={this.state.treeData}
                style={{margin: "10px", width: "100%", cursor: "default"}}
                selectable={false}
                blockNode
                onExpand={e => this.setState({expandedKey: e})}
                expandedKeys={this.state.expandedKey}
            />
        </>;
    }

    protected getBasePath(): string {
        return "/categories";
    }

    protected async onSearch() {
        this.setState({loading: true})
        await this.pushBasicStateToUrl();
        const categories = await CategoryApi.getAll(this.state.query);
        const parents = await CategoryApi.getAllParents();

        const treeData: TreeDataNode[] = parents.map(value => {
            return {
                title: value.designation,
                key: value.id,
                children: this.buildChildren(value, categories),
            };
        }).filter(value => value.children.length > 0);

        this.setState({treeData, expandedKey: parents.map(p => p.id), loading: false})

    }

    private buildChildren(parent: Category, categories: Category[]): TreeDataNode[] {
        return categories.filter(value => value.parent?.id === parent.id).map(value => {
            return {
                title: <Card hoverable
                             size="small"
                             style={{marginLeft: "10px", marginRight: "10px"}}
                             onClick={() => this.onItemSelected(value)}
                >
                    {value.designation}
                </Card>,
                key: value.id,
                isLeaf: true,
                style: {cursor: "pointer"}
            }
        })
    }
}

export default CategoriesView;