import React from 'react';

interface Props {
}

export class ExternalTools extends React.Component<Props> {

    render() {
        // {this.renderItem("", "")}
        return <div style={{padding: "10px"}}>
            <h2>Rechner</h2>
            <ul>
                {this.renderItem("Verschiedene Rechner", "https://www.finanzfluss.de/rechner/")}
                {this.renderItem("Brutto-Netto Rechner", "https://www.brutto-netto-rechner.info/")}
            </ul>
            <h2>Graphiken</h2>
            <ul>
                {this.renderItem("Altersspezifische Verteilung der Haushaltsnettovermögen", "https://www.finanzfluss.de/blog/wieviel-geld-sollte-man-gespart-haben/")}
                {this.renderItem("Einkommensvergleich", "https://www.iwkoeln.de/fileadmin/user_upload/HTML/2019/einkommensverteilung/index.html")}
                {this.renderItem("Furcht vs. Gier Index", "https://edition.cnn.com/markets/fear-and-greed")}
            </ul>
            <h2>Artikel</h2>
            <ul>
                {this.renderItem("Vorschlag zur Einkommensverteilung: 50-30-20 Regel", "https://www.weltsparen.de/sparen/50-30-20-regel/")}
            </ul>
        </div>
    }

    private renderItem(title: string, path: string) {
        return <li><a href={path} target="_blank" rel="noreferrer">{title}</a></li>

    }

}
