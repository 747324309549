import React from "react";
import DateHelper from "../../global/DateHelper";
import {Card, Col, Collapse, Progress, Row, Select} from "antd";
import {BudgetBookEntry, BudgetBookEntryGroup, ConsumedBudget} from "../../data/BudgetBookEntry";
import Formatter from "../../global/Formatter";
import BudgetBookApi from "../../apis/BudgetBookApi";
import {Option} from "antd/es/mentions";
import {Category} from "../../data/Category";
import CategoryApi from "../../apis/CategoryApi";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import {Config} from "../../frame/Config";
import {BalanceStatisticsPanel} from "../../frame/components/BalanceStatisticsPanel";
import {RangePicker} from "../../frame/components/RangePicker";
import {OverviewState, OverviewView} from "../../frame/components/base/OverviewView";
import moment from "moment";
import Checkbox from "antd/es/checkbox";
import {TreeSlotGrid} from "../../frame/components/TreeSlotGrid";
import Button from "antd/es/button";
import Icon from "../../frame/components/Icon";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons/faCreditCard";
import {Link} from "react-router-dom";

interface State extends OverviewState {
    groupedData: BudgetBookEntryGroup[],
    budgetConsumptions: ConsumedBudget[],
    showFutureEntries: boolean,
    from: string,
    to: string,
    categoriesLoading: boolean,
    categories?: Category[],
    selectedCategoryId: string,
    totalIncome: number,
    totalExpenses: number
}

interface Props {

}

const testData: BudgetBookEntry[] = [
    {
        id: "1",
        amount: -14.13,
        category: {
            id: "id",
            designation: "Test"
        },
        designation: "B",
        date: "2000-01-01",
        dateAsMoment: DateHelper.toMoment(0),
        files: [],
        monthlyAmount: 0
    },
    {
        id: "2",
        amount: 10.13,
        category: {
            id: "id",
            designation: "Test"

        },
        designation: "A",
        date: "2000-01-01",
        dateAsMoment: DateHelper.toMoment(0),
        files: [],
        monthlyAmount: 0
    }
]

export default class BudgetBookView extends OverviewView<Props, State, BudgetBookEntry> {

    constructor(props: Props) {
        super(props);
        this.state = {
            query: "",
            budgetConsumptions: [],
            groupedData: this.groupAndTransformTimelineData(testData),
            showFutureEntries: Config.getBool("show-future-entries-by-default"),
            from: DateHelper.getCurrentMonthRange().from,
            to: DateHelper.getCurrentMonthRange().to,
            loading: false,
            categoriesLoading: true,
            selectedCategoryId: "",
            totalExpenses: 0,
            totalIncome: 0
        }
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevState.from !== this.state.from || prevState.to !== this.state.to
            || prevState.selectedCategoryId !== this.state.selectedCategoryId) {
            await this.onSearch();
        }
    }

    async componentDidMount() {
        await this.loadQueryParams();
        const categories = await CategoryApi.getAll("");
        this.setState({categories, categoriesLoading: false});
    }

    protected async onSearch() {
        await this.setState({
            groupedData: this.groupAndTransformTimelineData(testData),
            totalIncome: 0,
            totalExpenses: 0,
            loading: true
        });
        this.pushQueryParams();
        const response = await BudgetBookApi.query(this.state.query, this.state.from, this.state.to, this.state.selectedCategoryId);
        const values = response.entries;
        this.setState({budgetConsumptions: this.preprocess(response.budgetConsumptions)});

        if (values.length > 0) {
            const totalIncome = values.filter(value => value.amount > 0).map(value => value.amount)
                .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
            let totalExpenses = values.filter(value => value.amount < 0).map(value => value.amount)
                .reduce((previousValue, currentValue) => previousValue + currentValue, 0) * -1;
            if (totalExpenses === -0) {
                totalExpenses = 0;
            }
            await this.setState({totalIncome, totalExpenses});
        }
        await this.setState({
            loading: false,
            groupedData: this.groupAndTransformTimelineData(values, value => this.getTimelineFilter(value))
        });
    }

    protected renderAdditionalButtons() {
        return <>
            <Button onClick={ev => this.handleImportHanseatic()}
                    style={{marginLeft: "6px"}}
                    loading={this.state.loading}
                    icon={<Icon icon={faCreditCard}/>}>
                <Link to={"/budget-book-hanseatic"}>Hanseatic-Import</Link>
            </Button>
        </>
    }


    private getTimelineFilter(value: BudgetBookEntry) {
        return !value.dateAsMoment?.isAfter(moment()) || this.state.showFutureEntries;
    }

    protected renderList(): React.ReactNode {
        return this.renderTimeline(this.state.groupedData, item => this.renderItem(item),
            (a, b) => a.designation.localeCompare(b.designation));
    }

    private renderItem(entry: BudgetBookEntry) {
        const color = entry.amount >= 0 ? "green" : "red";
        return <Card loading={this.state.loading} hoverable size="small" style={{margin: "10px"}}
                     key={entry.id} onClick={() => this.setState({selectedId: entry.id})}>
            <p style={{
                margin: 0,
                fontSize: "17px"
            }}>{entry.designation}{entry.category ? ` - [${entry.category.designation}]` : ""}</p>
            <p style={{margin: 0, fontSize: "15px", color}}>{Formatter.formatCurrency(entry.amount)}</p>
        </Card>
    }

    protected renderAdditionalContent() {
        const balance = this.state.totalIncome - this.state.totalExpenses;
        return <Collapse style={{margin: "10px"}} accordion={true}
                         defaultActiveKey={Config.budgetBookDetailsOpenedByDefault() ? 1 : undefined}>
            <CollapsePanel key={1} header={
                <span style={{padding: "1px"}}>Filter / Details (<span
                    className={`${balance > 0 ? "income-color" : "expenses-color"}`}>
                    Bilanz: {" " + Formatter.formatCurrency(balance)}</span>) </span>
            }>
                {this.renderFilter()} {<BalanceStatisticsPanel totalIncome={this.state.totalIncome}
                                                               totalExpenses={this.state.totalExpenses}/>}
            </CollapsePanel>
            <CollapsePanel key={2} header={this.renderBudgetsHeader()}>
                {this.state.budgetConsumptions.map(e => BudgetBookView.renderBudgetConsumptionComponent(e))}
            </CollapsePanel>


        </Collapse>
    }

    private preprocess(budgetConsumptions: ConsumedBudget[]) {

        budgetConsumptions.forEach(value => {
            value.consumptionPercent = (value.totalExpenses / value.totalBudget) * 100;
            if (value.consumptionPercent > 100) {
                value.color = "red";
            } else if (value.consumptionPercent >= 75) {
                value.color = "orange";
            } else {
                value.color = "green";
            }
        });

        return budgetConsumptions;
    }


    private renderBudgetsHeader() {
        const numberRed = this.state.budgetConsumptions.filter(value => value.color === "red").length;
        const numberOrange = this.state.budgetConsumptions.filter(value => value.color === "orange").length;
        return <span>Budgets (<span style={{color: "red"}}>{numberRed}</span>, <span
            style={{color: "orange"}}>{numberOrange}</span>)</span>;
    }

    private static renderBudgetConsumptionComponent(budget: ConsumedBudget) {
        return <Row style={{margin: "10px"}} gutter={[16, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={4}>
                {budget.budget.designation}
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={16}>
                <Progress showInfo={false} percent={budget.consumptionPercent} type="line" strokeColor={budget.color}/>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={4}>
                {Formatter.formatCurrency(budget.totalExpenses)} / {Formatter.formatCurrency(budget.totalBudget)} ({Formatter.formatPercent(budget.consumptionPercent)})
            </Col>
        </Row>;
    }

    private renderFilter() {
        return <TreeSlotGrid firstElement={<RangePicker parent={this}/>}
                             secondElement={this.renderSecondColumnFilter()}
                             thirdElement={<></>}/>
    }

    protected getBasePath(): string {
        return "/budget-book";
    }

    private pushQueryParams() {
        window.history.pushState("", "Query", `${this.getBasePath()}?query=${this.state.query}&from=${this.state.from}&to=${this.state.to}&category=${this.state.selectedCategoryId}`);
    }

    private async loadQueryParams() {
        const params = await this.loadBaseQueryFromUrl();
        await this.setState({
            from: params.get("from") || "" + DateHelper.getCurrentMonthRange().from,
            to: params.get("to") || "" + DateHelper.getCurrentMonthRange().to,
            selectedCategoryId: params.get("category") || "ALL"
        });
    }

    //Helper

    private renderSecondColumnFilter() {
        return <>
            <span>Kategorie:</span>
            <Select
                loading={this.state.categoriesLoading}
                disabled={this.state.categoriesLoading}
                showSearch
                optionFilterProp="children"
                value={this.state.selectedCategoryId}
                style={{width: "100%"}}
                onChange={async ev => {
                    await this.setState({selectedCategoryId: ev});
                    await this.onSearch();
                }}
            >
                <Option value={"ALL"}>Alle</Option>
                {this.state.categories?.map(value => <Option value={value.id}>{value.designation}</Option>)}
            </Select>
            <Checkbox checked={this.state.showFutureEntries} onChange={async e => {
                await this.setState({showFutureEntries: e.target.checked})
                await this.onSearch();
            }}>Zukünftige Einträge mit anzeigen.</Checkbox>
        </>;
    }

    private handleImportHanseatic() {

    }
}