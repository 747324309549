import React from "react";
import {DatePicker, Form, InputNumber, Modal, Select} from "antd";
import {Option} from "antd/es/mentions";
import CryptoApi from "../../../apis/CryptoApi";
import {CryptoCurrency, CryptoCurrencyEntry} from "../../../data/Crypto";
import DateHelper from "../../../global/DateHelper";
import TextArea from "antd/es/input/TextArea";
import Formatter from "../../../global/Formatter";
import {DetailEditView, DetailEditViewProps, DetailEditViewState} from "../../../frame/components/base/DetailEditView";
import Button from "antd/es/button";
import Icon from "../../../frame/components/Icon";
import {faChartLine} from "@fortawesome/free-solid-svg-icons/faChartLine";
import CryptoCurrencyPriceWidget from "../currencies/CryptoCurrencyPriceWidget";

interface State extends DetailEditViewState {
    currenciesLoading: boolean,
    cryptoCurrencies: CryptoCurrency[],
    showValueHistory: boolean,
    entry?: CryptoCurrencyEntry,

}

interface Props extends DetailEditViewProps {

}

export default class CryptoCurrencyEntryDetailView extends DetailEditView<Props, State, CryptoCurrencyEntry> {

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            entryId: this.props.match.params.id,
            currenciesLoading: true,
            cryptoCurrencies: [],
            showValueHistory: false
        }
    }

    componentDidMount() {
        this.update();
    }

    protected async update() {
        const currencies = await CryptoApi.queryCurrencies("");
        await this.setState({cryptoCurrencies: currencies, currenciesLoading: false});

        if (this.isNew()) {
            this.formRef.current!.setFieldsValue({
                description: "",
                dateAsMoment: DateHelper.toMoment(Date.now()),
                currency: this.state.cryptoCurrencies[0]
            });
        } else {
            const entry = await CryptoApi.getEntry(this.state.entryId);
            entry.dateAsMoment = DateHelper.toMomentDate(entry.date);
            this.formRef.current!.setFieldsValue(entry);
            this.setState({entry});
        }

        this.setState({loading: false});
    }

    renderForm() {
        return <>
            <Form.Item
                label="Währung"
                name={["currency", "id"]}
                rules={[{required: true, message: 'Bitte Währung ausfüllen!'}]}
            >

                {this.loadable(
                    <Select showSearch optionFilterProp="children">
                        {this.state.cryptoCurrencies.map(value =>
                            <Option key={value.id} value={value.id}>
                                {value.currencyName} ({Formatter.formatCurrencyWithDigits(value.price, 10)})
                            </Option>)
                        }
                    </Select>
                )}

            </Form.Item>

            <Form.Item
                label="Anzahl"
                name="amount"
                rules={[{required: true, message: 'Bitte Anzahl ausfüllen!'}]}
            >
                {this.loadable(<InputNumber style={{width: "100%"}} min={0} step={1E-10} decimalSeparator=","/>)}
            </Form.Item>

            <Form.Item
                label="Preis pro Einheit (€)"
                name="buyInPrice"
                rules={[{required: true, message: 'Bitte Preis ausfüllen!'}]}
            >
                {this.loadable(<InputNumber style={{width: "100%"}} min={0} step={1E-10} decimalSeparator=","/>)}
            </Form.Item>

            <Form.Item
                label="Datum"
                name="dateAsMoment"
                required
            >
                {this.loadable(<DatePicker style={{width: "100%"}} format="DD.MM.yyyy"/>)}
            </Form.Item>

            <Form.Item
                label="Details"
                name="description"
            >
                {this.loadable(<TextArea rows={5}/>)}
            </Form.Item>
            {this.renderValueHistoryModal()}
        </>;
    }

    private renderValueHistoryModal() {

        return this.state.showValueHistory && this.state.entry ?
            <Modal
                visible
                title="Wertverlauf"
                onCancel={() => this.setState({showValueHistory: false})}
                footer={null}
                bodyStyle={{margin: 5, padding: 5}}
                width={window.innerWidth < 600 ? "100%" : "75%"}
                centered
            >
                <CryptoCurrencyPriceWidget currencyId={this.state.entry?.currency.id}
                                           multiplier={this.state.entry?.amount || 0}/>
            </Modal> :
            null
    }


//Overrides:
    protected deleteHandler() {
        return CryptoApi.deleteEntry;
    }

    protected redirectTarget(): string {
        return "/crypto/entries";
    }

    protected prePersistActions(value: CryptoCurrencyEntry): void {
        value.date = DateHelper.fromMomentDate(value.dateAsMoment) || "0";
    }

    protected createHandler() {
        return CryptoApi.postEntry;
    }

    protected updateHandler() {
        return CryptoApi.putEntry;
    }

    protected additionalButtons() {
        return this.state.entry ? <Button onClick={() => this.setState({showValueHistory: true})}
                                          style={{marginLeft: "6px"}}
                                          loading={this.state.loading}
                                          icon={<Icon icon={faChartLine}/>}>
            Wertverlauf anzeigen
        </Button> : super.additionalButtons();
    }
}
