import React, {ReactNode} from 'react';
import {Col, Row} from "antd";
import Formatter from "../../global/Formatter";
import Skeleton from "react-loading-skeleton";

interface State {

}

interface Props {
    firstLabel: string,
    secondLabel: string,
    thirdLabel: string,
    first: number,
    second: number,
    third: number,
    loading?: boolean
}

export class TreePartStatisticsView extends React.Component<Props, State> {
    render() {
        return <Row style={{margin: "10px"}}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                <div className="price-style-statistics ">
                    {this.loadable(`${this.props.firstLabel} ${Formatter.formatCurrency(this.props.first)}`)}
                </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                <div className="price-style-statistics ">
                    {this.loadable(`${this.props.secondLabel} ${Formatter.formatCurrency(this.props.second)}`)}
                </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                <div className="price-style-statistics ">
                    {this.loadable(`${this.props.thirdLabel} ${Formatter.formatCurrency(this.props.third)}`)}
                </div>
            </Col>
        </Row>;
    }

    private loadable(node: ReactNode, height?: number): ReactNode {
        return this.props.loading ?
            <Skeleton enableAnimation height={height ? height : 32} inline={true}/> : node;
    }


}

export default TreePartStatisticsView;