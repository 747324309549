import React from "react";
import {Category} from "../../data/Category";
import CategoryApi from "../../apis/CategoryApi";
import {DetailEditView, DetailEditViewProps, DetailEditViewState} from "../../frame/components/base/DetailEditView";
import {Form} from "antd";
import {Select} from "antd/es";
import {Option} from "antd/es/mentions";
import TextArea from "antd/es/input/TextArea";

interface State extends DetailEditViewState {
    parents: Category[]
}


export default class CategoriesDetailsView extends DetailEditView<DetailEditViewProps, State, Category> {

    constructor(props: DetailEditViewProps) {
        super(props);
        this.state = {
            loading: true,
            entryId: this.props.match.params.id,
            parents: []
        }
    }

    componentDidMount() {
        this.update();
    }

    protected async update() {
        this.setState({parents: await CategoryApi.getAllParents()});

        if (!this.isNew()) {
            const category = await CategoryApi.get(this.state.entryId);
            this.formRef.current!.setFieldsValue(category);
        } else {
            this.formRef.current!.setFieldsValue({
                parent: {id: "dd179989-cac5-4c6d-9c32-abcdef012345"}
            });
        }
        this.setState({loading: false});
    }

    renderForm() {
        return <>
            {this.renderDesignation()}
            <Form.Item
                label="Übergeordnet"
                name={["parent", "id"]}
                rules={[{required: true, message: 'Bitte Übergeordnet ausfüllen!'}]}
            >
                {this.loadable(
                    <Select>
                        {this.state.parents.map(value => <Option value={value.id}>{value.designation}</Option>)}
                    </Select>
                )}
            </Form.Item>
            <Form.Item
                label="Tags"
                name={["tags"]}
            >
                {this.loadable(
                    <TextArea></TextArea>
                )}
            </Form.Item>

        </>;
    }

    protected createHandler() {
        return CategoryApi.post;
    }

    protected deleteHandler() {
        return CategoryApi.delete;
    }

    protected redirectTarget(): string {
        return "/categories";
    }

    protected updateHandler() {
        return CategoryApi.put;
    }


}