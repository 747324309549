import {Instrument} from "../../data/Instrument";
import React from "react";
import {Button, Checkbox, DatePicker, Form, FormInstance, InputNumber, PageHeader} from "antd";
import {Constants} from "../../frame/Constants";
import {InstrumentCard} from "./InstrumentCard";
import {InstrumentRenderingHelper} from "./InstrumentRenderingHelper";
import {InstrumentApi} from "../../apis/InstrumentApi";
import {LoadingView, LoadingViewState} from "../../frame/components/base/LoadingView";
import {BaseResponse} from "../../data/BaseResponse";
import {Redirect} from "react-router-dom";
import moment from "moment";
import DateHelper from "../../global/DateHelper";

export interface SellOrder extends BaseResponse {
    instrument?: Instrument
    soldShares?: number
    sellOutPrice?: number
    fifo: boolean
    qualifyingDateMoment?: moment.Moment,
    qualifyingDate?: string
}

export interface Profit extends BaseResponse {
    totalProfit: number
}


interface State extends LoadingViewState {
    instrument?: Instrument
    renderModal: boolean
    totalProfit: number
    redirect: boolean
}

interface Props {
}

export class InstrumentsSellView extends LoadingView<Props, State> {

    private formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {
            renderModal: false,
            loading: false,
            totalProfit: 0,
            redirect: false
        }
    }


    render() {
        return <>
            {this.state.redirect ? <Redirect to={"/instruments/transactions"}/> : null}
            <PageHeader title="Instrumente verkaufen" onBack={e => this.setState({redirect: true})}/>
            <Form
                ref={this.formRef}
                name="basic"
                labelCol={Constants.LABEL_COL}
                wrapperCol={Constants.WRAPPER_COL}
                onChange={event => this.reCheck()}
                initialValues={{fifo: true, qualifyingDateMoment: DateHelper.todayAsMoment()}}
            >
                {this.state.renderModal ? this.renderModal() : null}
                <Form.Item
                    label="Instrument"
                >
                    <InstrumentCard hoverable instrument={this.state.instrument}
                                    onItemSelected={item => this.setState({renderModal: true})}/>
                </Form.Item>
                <Form.Item
                    label="Verkaufspreis (€)"
                    name="sellOutPrice"
                    shouldUpdate={(prevValues, nextValues) => prevValues?.sellOutPrice !== nextValues?.sellOutPrice}
                    rules={[{required: true, message: 'Bitte Verkaufspreis ausfüllen!'}]}
                >
                    <InputNumber style={{width: "100%"}} min={0} step={1E-4} decimalSeparator=","/>
                </Form.Item>

                <Form.Item
                    label="Verkaufte Anteile"
                    name="soldShares"
                    rules={[{required: true, message: 'Bitte Verkaufte Anteile ausfüllen!'}]}
                >
                    <InputNumber style={{width: "100%"}} min={0} step={1E-4} decimalSeparator=","/>
                </Form.Item>

                <Form.Item
                    label="Stichtag"
                    name="qualifyingDateMoment"
                    rules={[{required: true, message: 'Bitte Stichtag ausfüllen!'}]}
                >
                    <DatePicker onChange={e => this.reCheck()} style={{width: "100%"}} format="DD.MM.yyyy"/>
                </Form.Item>

                <Form.Item
                    label="Älteste zuerst (FIFO)?"
                    name="fifo"
                    valuePropName="checked"
                >
                    <Checkbox value={true}/>
                </Form.Item>

                <Form.Item
                    label="Gesamt-Profit"
                >
                    {this.formatCurrencyColored(this.state.totalProfit)}
                </Form.Item>
                <Button onClick={event => this.anlegen()}>Anlegen</Button>
            </Form>
        </>

    }

    private renderModal() {
        return InstrumentRenderingHelper.renderSelectionModal(() => this.setState({renderModal: false}),
            item => {
                this.setState({instrument: item, renderModal: false})
                this.formRef.current!.setFieldsValue({sellOutPrice: item?.lastPrice})
            }, this.state.instrument?.isin);
    }

    private async anlegen() {
        const data: SellOrder = await this.formRef.current!.validateFields() as SellOrder;
        this.normalizeSellOrder(data);
        await InstrumentApi.sellTransactions(data);
    }

    private async reCheck() {
        if (!this.state.instrument) {
            return;
        }

        const data: SellOrder = await this.formRef.current!.validateFields() as SellOrder;
        this.normalizeSellOrder(data);
        const result = await InstrumentApi.checkSellTransactions(data);
        this.setState({totalProfit: result.totalProfit});
    }

    private normalizeSellOrder(data: SellOrder) {
        data.instrument = this.state.instrument;
        data.qualifyingDate = DateHelper.fromMomentDate(data.qualifyingDateMoment);
        data.qualifyingDateMoment = undefined;
    }
}