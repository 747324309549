import React from 'react';
import Button from "antd/es/button";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import Icon from "./Icon";

interface State {
}

interface Props {
    onClick: (ev: any) => void
    loading: boolean
}

export default class SaveButton extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {}
    }

    render() {
        return <Button icon={<Icon icon={faSave}/>} loading={this.props.loading}
                       onClick={ev => this.props.onClick(ev)} style={{marginRight: "6px"}}>Speichern</Button>
    }

}
