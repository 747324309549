import Rest from "./Rest";
import {GitStatus, SystemStatus} from "../data/Meta";


export default class MetaApi {
    public static async getSystemStatus(): Promise<SystemStatus> {
        return Rest.getSpecific(`/meta/system-status`);
    }

    public static async getGitStatus(): Promise<GitStatus> {
        return Rest.getSpecific(`/meta/git/reduced`);
    }

}