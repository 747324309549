import React from "react";
import {Redirect, RouteComponentProps} from "react-router-dom";
import {Col, PageHeader, Row} from "antd";
import {LoadingView, LoadingViewState} from "../../../frame/components/base/LoadingView";
import CryptoCurrencyPriceWidget from "./CryptoCurrencyPriceWidget";

interface State extends LoadingViewState {
    redirect: boolean
    title?: string
}

interface Props extends RouteComponentProps<{ id: string }> {

}

export default class CryptoCurrencyDetailsView extends LoadingView<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            redirect: false
        }
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={`/crypto/currencies`} push/>
        }

        return <>

            {this.loadable(<PageHeader onBack={() => this.setState({redirect: true})}
                                       title={"Preishistorie " + this.state.title}/>, 50)}

            <Row gutter={[16, 16]} style={{padding: "25px"}}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                    <CryptoCurrencyPriceWidget currencyId={this.props.match.params.id} multiplier={1} parent={this}/>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                </Col>
            </Row>
        </>
    }

}

