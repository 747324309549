import {File} from "../data/File";
import {Config} from "../frame/Config";
import Rest from "../apis/Rest";

export class FileUtils {

    public static async getObjectUrlForFile(item: File) {
        return this.getObjectUrlForFileId(item.id);
    }

    public static async getObjectUrlForFileId(item: string) {
        const response = await fetch(this.getUrl(item),
            {method: 'GET', headers: Rest.getBasicHeaders()})

        return window.URL.createObjectURL(await response.blob());
    }

    public static async downloadFile(item: File) {
        const link = document.createElement('a');
        link.href = await this.getObjectUrlForFile(item);
        link.setAttribute('download', item.filename);
        document.body.appendChild(link);
        link.click();
    }

    public static isPicture(item: File): boolean {
        return ["png", "jpg", "jpeg", "gif", "webp"].includes(item.fileExtension)
    }

    public static isVideo(item: File) {
        return ["mp4", "webm"].includes(item.fileExtension)
    }

    public static getUrl(id: string) {
        return `${Config.getBaseURL()}/file/${id}`;
    }

}