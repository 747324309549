import {message} from 'antd';
import Security from "../global/Security";
import {BaseResponse} from "../data/BaseResponse";
import EasyLogger from "../frame/EasyLogger";
import ErrorBody from "../data/ErrorBody";
import {Config} from "../frame/Config";

class Rest {

    public static async getMultiple<Type extends BaseResponse>(relativeUrl: string): Promise<Type[]> {
        return await this.get(relativeUrl) || [];
    }

    public static async getSpecific<Type extends BaseResponse>(relativeUrl: string): Promise<Type> {
        return await this.get(relativeUrl) || {} as Type;
    }

    private static async get<Type extends BaseResponse | BaseResponse[]>(relativeUrl: string): Promise<Type | undefined> {
        const url = Config.getBaseURL() + relativeUrl;
        const now = Date.now();

        try {
            let response: Response = await fetch(url, {method: 'GET', headers: Rest.getBasicHeaders()})
            EasyLogger.DEBUG(`GET Request against ${relativeUrl} took ${Date.now() - now} ms`)
            if (response.status !== 200) {
                await this.handleError(response);
            }
            return await response.json() as Type;
        } catch (e) {
            console.log("Request failed!", e);
            return undefined;
        }
    }

    public static put<Type>(value: Type, relativeUrl: string): Promise<Type> {
        return this.save('PUT', value, relativeUrl);
    }

    public static post<Type>(value: Type | null, relativeUrl: string): Promise<Type> {
        return this.save('POST', value, relativeUrl);
    }

    public static async save<Request, Response>(method: 'PUT' | 'POST', value: Request | null, relativeUrl: string, hideMessages?: boolean): Promise<Response> {
        const url = Config.getBaseURL() + relativeUrl;

        try {
            let response = await fetch(url, {
                method: method, body: JSON.stringify(value),
                headers: Rest.getBasicHeaders()
            });
            await Rest.handleSaveResponse(response, hideMessages)
            return await response.json() as Response;
        } catch (e) {
            console.log("I failed you master!", e);
            return {} as Response;
        }
    }

    private static async handleSaveResponse(response: Response, hideMessages: boolean | undefined) {
        if (hideMessages) {
            return;
        }
        if (response.status !== 200) {
            await this.handleError(response);
        } else {
            message.info("Speichern erfolgreich!");
        }
    }

    public static delete(relativeUrl: string): Promise<any> {
        const url = Config.getBaseURL() + relativeUrl;
        return fetch(url, {method: 'DELETE', headers: Rest.getBasicHeaders()})
            .then(response => Rest.handleDeleteResponse(response));
    }

    static handleDeleteResponse(response: Response): Response {
        if (response.status !== 200) {
            Rest.handleError(response);
        } else {
            message.info("Löschen erfolgreich!");
        }
        return response;
    }

    private static async handleError(response: Response) {
        let errorBody: ErrorBody | undefined = await response.json() as ErrorBody;
        const error: string = errorBody.error || "Fataler Serverfehler";
        message.error(error);
        throw new Error(error);
    }

    public static getBasicHeaders() {
        return {
            'Authorization': Security.get().getToken(),
            "Selected-Language": "de"
        };
    }
}

export default Rest;