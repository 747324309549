import React from "react";
import {File} from "../../data/File";
import {Card, Collapse, List, message, Modal, Upload, UploadProps} from "antd";
import Button from "antd/es/button";
import Icon from "./Icon";
import {faUpload} from "@fortawesome/free-solid-svg-icons/faUpload";
import Rest from "../../apis/Rest";
import {Config} from "../Config";
import {UploadChangeParam} from "antd/es/upload";
import {faFolderOpen} from "@fortawesome/free-solid-svg-icons/faFolderOpen";
import {MyFilesView} from "../../pages/files/MyFilesView";
import {FilePreview} from "./FilePreview";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";


interface State {
    showAssignDialog: boolean,
    files?: File[],
}

interface Props {
    files: File[],
    parent: React.Component<any, { files: File[] }>
}

export default class AppendedFiles extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            showAssignDialog: false
        }
    }

    private fileUploadProps: UploadProps = {
        name: 'file',
        headers: Rest.getBasicHeaders(),
        action: Config.getBaseURL() + "/file/simple",
        showUploadList: false,
        multiple: false,
        onChange: info => this.handleUploadUpdate(info)
    };

    async componentDidMount() {
        this.setState({files: this.props.files});
    }

    render() {
        return <Collapse>
            <Collapse.Panel header={`Dateianhänge (${this.props.files.length})`} key="1">

                <Upload {...this.fileUploadProps}>
                    <Button icon={<Icon icon={faUpload}/>}>Hochladen</Button>
                </Upload>

                <Button icon={<Icon icon={faFolderOpen}/>}
                        onClick={() => this.setState({showAssignDialog: true})}>Zuweisen</Button>
                {this.state.showAssignDialog ? this.renderFileChooseDialog() : null}

                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 1,
                        lg: 2,
                        xl: 2,
                        xxl: 3
                    }}
                    dataSource={this.props.files}
                    renderItem={item => (
                        <List.Item>
                            <Card
                                actions={[
                                    <FontAwesomeIcon color="red" icon={faTimes} onClick={() => this.delete(item.id)}/>,
                                    <FontAwesomeIcon icon={faEdit} onClick={() => AppendedFiles.openEdit(item.id)}/>
                                ]}
                            >
                                <FilePreview file={item} previewable={true}/>
                            </Card>
                        </List.Item>
                    )}
                />
            </Collapse.Panel>
        </Collapse>;
    }

    private delete(id: string) {
        const files = this.props.files.filter(value => value.id !== id);
        this.props.parent.setState({files});
    }


    private handleUploadUpdate(info: UploadChangeParam) {
        if (info.file.status === 'done') {
            this.pushFile({
                id: info.file.response.id,
                filename: info.file.response.filename,
                createdAt: info.file.response.createdAt,
                fileExtension: info.file.response.fileExtension
            });
            message.success(`${info.file.name} erfolgreich hochgeladen.`);

        } else if (info.file.status === 'error') {
            message.error(`Hochladen fehlgeschlagen.`);
        }
    }

    public pushFile(newFile: File) {
        const files = this.props.parent.state.files;
        files.push(newFile);
        files.sort((a, b) => b.createdAt - a.createdAt)
        this.props.parent.setState({files});
        this.setState({showAssignDialog: false})
    }

    private renderFileChooseDialog() {
        return <Modal
            title="Datei auswählen"
            visible={true}
            footer={null}
            width={"80%"}
            onCancel={() => this.setState({showAssignDialog: false})}
        >
            <MyFilesView parent={this}/>
        </Modal>
    }

    private static openEdit(id: string) {
        window.open(`/files/${id}`, '_blank')
    }
}
