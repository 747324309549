import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.less';
import App from "./frame/App";
import locale from 'antd/lib/locale/de_DE';
import moment from "moment";
import {ConfigProvider} from "antd";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
moment.locale("de");
ReactDOM.render(
    <ConfigProvider locale={locale}>
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    </ConfigProvider>,

    document.getElementById('root')
);