import {format} from 'date-fns'
import {de} from 'date-fns/locale'
import Comp from "./Comp";
import {PreciousMetalType} from "../data/PreciousMetal";
import DateHelper from "./DateHelper";


class Formatter {

    public static stringToNumber(value: number | undefined, fractionDigits: number): string {
        if (Comp.nill(value)) {
            return "-";
        }
        let format: Intl.NumberFormat = new Intl.NumberFormat('de-DE', {maximumFractionDigits: fractionDigits});
        // @ts-ignore
        return format.format(value);
    }

    public static formatCurrencyCustom(value: number | undefined, customPlaceHolder: string, fractionDigits: number): string {
        if (Comp.nill(value)) {
            return customPlaceHolder;
        }
        let format: Intl.NumberFormat = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: fractionDigits
        });
        // @ts-ignore
        return format.format(value);
    }

    //maximumFractionDigits
    public static formatCurrencyWithDigits(value: number | undefined, fractionDigits: number): string {
        return this.formatCurrencyCustom(value, "-", fractionDigits);
    }

    public static formatCurrency(value: number | undefined): string {
        return this.formatCurrencyCustom(value, "-", 2);
    }




    public static formatDate(value: string | undefined): string {

        if (Comp.nill(value)) {
            return "-";
        }

        // @ts-ignore
        return format(DateHelper.toMomentDate(value).toDate(), "dd.MM.yyyy", {locale: de});
    }

    public static formatTime(value: number | undefined): string {

        if (Comp.nill(value)) {
            return "-";
        }

        // @ts-ignore
        return format(value, "HH:mm:ss", {locale: de});
    }



    public static formatDateTime(value: number | undefined): string {

        if (Comp.nill(value)) {
            return "-";
        }

        // @ts-ignore
        return format(value, "dd.MM.yyyy HH:mm:ss", {locale: de});
    }


    public static formatWeight(value: number | undefined): string {
        if (Comp.nill(value)) {
            return "-";
        }
        let format: Intl.NumberFormat = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 3
        });
        // @ts-ignore
        return format.format(value) + " g";
    }

    static formatPercent(value: number) {

        if (Comp.nill(value)) {
            return "-";
        }
        let format: Intl.NumberFormat = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        });
        // @ts-ignore
        return format.format(value) + " %"
    }

    static formatAsMb(value: number) {
        return this.stringToNumber(value / 1024 / 1024, 2);
    }

    public static formatPreciousMetalType(type: PreciousMetalType): string {
        switch (type) {
            case PreciousMetalType.GOLD:
                return "Gold";
            case PreciousMetalType.SILVER:
                return "Silber";
            case PreciousMetalType.PLATINUM:
                return "Platin";
            case PreciousMetalType.PALLADIUM:
                return "Palladium";
            default:
                return "-";
        }
    }
}

export default Formatter;