import React from "react";
import {LoadingView} from "../../frame/components/base/LoadingView";
import TextArea from "antd/es/input/TextArea";
import {BudgetBookEntry} from "../../data/BudgetBookEntry";
import BudgetBookApi from "../../apis/BudgetBookApi";
import DateHelper from "../../global/DateHelper";
import {Select, Table} from "antd";
import Column from "antd/es/table/Column";
import Formatter from "../../global/Formatter";
import Button from "antd/es/button";
import CategoryApi from "../../apis/CategoryApi";
import {Category} from "../../data/Category";
import {Option} from "antd/es/mentions";
import Checkbox, {CheckboxChangeEvent} from "antd/es/checkbox";

interface ImportElement {
    description: string,
    amount: number,
    transactionDate: string,
    transactionId: string
}

interface SelectableBudgetBookEntry extends BudgetBookEntry {
    selected: boolean
}

interface State {
    loading: boolean,
    categories: Category[],
    reference: BudgetBookEntry[],
    currentImport: SelectableBudgetBookEntry[]
}


interface Props {
}

export class BudgetBookHanseaticView extends LoadingView<Props, State> {


    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            categories: [],
            reference: [],
            currentImport: [],
        }
    }


    async componentDidMount() {
        const currentMonthRange = DateHelper.getCurrentYearRange();
        const entries = await BudgetBookApi.query("", currentMonthRange.from,
            currentMonthRange.to, "ALL");

        const categories = await CategoryApi.getAll("");

        this.setState({loading: false, reference: entries.entries, categories});

    }

    render() {
        return <div style={{margin: "10px"}}>
            <h1>Hanseatic-Json Import</h1>
            <Button onClick={() => this.handleSave()}>Save</Button>
            <TextArea onChange={e => this.onChange(e.target.value)}/>
            <Table
                loading={this.state.loading}
                dataSource={this.state.currentImport}
                pagination={false}
                bordered
                size="small"
                style={{marginBottom: "10px"}}
            >
                {this.renderColumns()}
            </Table>
        </div>;
    }


    private async onChange(value: string) {

        const values: ImportElement[] = JSON.parse(value).transactions;
        let translated: SelectableBudgetBookEntry[] = [];

        values.forEach(value => {
            var date = DateHelper.toMomentDateWithFormat("DD.MM.YYYY", value.transactionDate);
            translated.push({
                selected: true,
                id: value.transactionId,
                designation: value.description,
                amount: value.amount,
                monthlyAmount: 0,
                date: DateHelper.fromMomentDate(date) || "",
                bankingId: value.transactionId,
                files: []
            })
        });

        translated = translated.filter(t => {
            const find = this.state.reference.find(ref => ref.bankingId === t.bankingId);
            return !find;
        });

        for (const value of translated) {
            let ca: Category[] = await CategoryApi.tryToFind(value.designation);
            if (ca.length > 0) {
                value.category = ca[0];
            }
        }

        this.setState({currentImport: translated});
    }

    private handleSave() {
        //  InstrumentApi.putPlan(this.state.plan);
        this.state.currentImport
            .filter(value => value.selected)
            .forEach(value => BudgetBookApi.post(value))

    }

    private renderColumns() {
        return <>

            <Column title="" dataIndex={["selected"]} key="selected"
                    render={(value, record: BudgetBookEntry) => {
                        return <>
                            <Checkbox checked={value} onChange={e => this.onSelectionChanged(record.id, e)}/>
                        </>
                    }}/>

            <Column title="Bezeichnung" dataIndex={["designation"]} key="designation"
                    render={(value, record) => {
                        return <>
                            {value}
                        </>
                    }}/>
            <Column title="Betrag" dataIndex={["amount"]} key="amount"
                    render={(value, record) => {
                        return <>
                            {Formatter.formatCurrency(value)}
                        </>
                    }}/>
            <Column title="Datum" dataIndex={["date"]} key="date"
                    render={(value, record) => {
                        return <>
                            {Formatter.formatDate(value)}
                        </>
                    }}/>
            <Column title="Kategorie" dataIndex={["category"]} key="category"
                    render={(value, record: BudgetBookEntry) => {
                        return <>
                            <Select
                                onChange={e => this.onCategoryChange(record.id, e)}
                                value={value ? value.id : undefined}
                                showSearch
                                optionFilterProp="children"
                                style={{width: "100%"}}
                            >
                                <Option value={undefined}>keine</Option>
                                {this.state.categories?.map(value => <Option
                                    value={value.id}>{value.designation}</Option>)}
                            </Select>
                        </>
                    }}/>
        </>;
    }


    private onCategoryChange(id: string, categoryId: string) {
        let category = this.state.categories.find(c => c.id === categoryId);
        let budgetBookEntry = this.state.currentImport.find(ref => ref.id === id);

        if (budgetBookEntry) {
            budgetBookEntry.category = category;
        }

        this.setState({currentImport: this.state.currentImport});

    }

    private onSelectionChanged(id: string, e: CheckboxChangeEvent) {
        const checked: boolean = e.target.checked;
        let budgetBookEntry = this.state.currentImport.find(ref => ref.id === id);
        if (budgetBookEntry) {
            budgetBookEntry.selected = checked;
        }
        this.setState({currentImport: this.state.currentImport});

    }
}