import React from "react";

class NoData {
    public static getNoDataDisplay() {
        return () => <><p>Noch keine Daten.</p><p>Mit Hinzufügen können neue erstellt werden.</p></>;
    }

    public static getNoDataDisplayNoAdd() {
        return () => <><p>Noch keine Daten.</p></>;
    }
}

export default NoData;
