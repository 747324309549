import React from 'react';
import {Col, Row} from "antd";

interface State {

}

interface Props {
    className?: string,
    firstElement: React.ReactNode,
    secondElement: React.ReactNode,
    thirdElement: React.ReactNode,
    fourthElement: React.ReactNode

}

export class FourSlotGrid extends React.Component<Props, State> {

    render() {
        return <Row style={{margin: "10px"}}>
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div style={this.getStyle()} className={this.getClassName()}>
                    {this.props.firstElement}
                </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div style={this.getStyle()} className={this.getClassName()}>
                    {this.props.secondElement}
                </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div style={this.getStyle()} className={this.getClassName()}>
                    {this.props.thirdElement}
                </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                <div style={this.getStyle()} className={this.getClassName()}>
                    {this.props.fourthElement}
                </div>
            </Col>
        </Row>;
    }


    private getStyle() {
        return {margin: "2px"};
    }

    private getClassName() {
        return this.props.className ? this.props.className : "";
    }
}
