import {ColProps} from "antd/lib/grid/col";

export class Constants {

    public static LABEL_COL: ColProps = {xs: 24, sm: 6, md: 4, lg: 4, xl: 3, xxl: 2};
    public static WRAPPER_COL: ColProps = {xs: 24, sm: 18, md: 16, lg: 16, xl: 14, xxl: 12};

    public static FORM_FULL_WIDTH: ColProps = {xs: 24, sm: 24, md: 20, lg: 20, xl: 17, xxl: 14};


}