import React from "react";
import {Button, Col, FormInstance, InputNumber, Row, Space, Table} from "antd";
import {InstrumentPlan, InstrumentPlanElement} from "../../../data/Instrument";
import Icon from "../../../frame/components/Icon";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import Formatter from "../../../global/Formatter";
import {faMinusCircle} from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Column from "antd/es/table/Column";
import {InstrumentRenderingHelper} from "../InstrumentRenderingHelper";
import {InstrumentPlanTableBase, InstrumentPlanTableBaseProps} from "./InstrumentPlanTableBase";

interface State {
    renderModal: boolean
}

interface Props extends InstrumentPlanTableBaseProps {

}

export class InstrumentPlanAllocationTab extends InstrumentPlanTableBase<Props, State> {

    protected formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {
            renderModal: false
        }
    }

    render() {
        return <div style={{margin: "10px"}}>
            {this.state.renderModal ? this.renderModal() : null}
            <Space style={{marginBottom: "10px"}}>
                {this.renderTopButton()}
            </Space>
            {this.renderTotalInput()}
            {this.renderList()}
        </div>;
    }

    private renderTopButton() {
        return <Button onClick={() => this.onAddButton()} loading={this.props.loading}>
            <Icon icon={faPlus}/> Hinzufügen
        </Button>
    }

    renderTotalInput() {
        return <Row gutter={[16, 16]} style={{padding: "10px"}}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                Investitionssumme (€):
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                <InputNumber value={this.props.plan.totalPerMonth} style={{width: "100%"}} min={0} step={1E-2}
                             decimalSeparator="," onChange={value => {
                    this.props.parent.updateTotalPerMonth(value);
                }}/>
            </Col>
        </Row>
    }

    protected renderColumns() {
        return <>
            {this.renderNameColumn()}
            {this.renderMetricColumns()}
            {this.renderDeleteColumn()}
        </>;
    }

    private renderMetricColumns() {

        return <>
            <Column title="Soll (Relativ)" dataIndex="percent" key="percent'" render={(value, record, index) => {
                return <><InputNumber width={"100%"} bordered={false} value={value} min={0} step={1E-2}
                                      decimalSeparator=","
                                      onChange={value => this.handleOnPercentChange(value, index)}/> %</>
            }}/>
            <Column title="Soll (Absolut)" key="a" render={(value, record, index) => {
                return <span>{Formatter.formatCurrency(this.props.plan.totalPerMonth * (this.props.plan.instruments[index].percent / 100))}</span>
            }}/>
            <Column title="Ist (Relativ)" key="b" render={(value, record, index) => {
                return <span>{Formatter.formatPercent(this.props.plan.instruments[index].currentPercent)}</span>
            }}/>
            <Column title="Abweichung" key="c" render={(value, record, index) => {
                return <span>{Formatter.formatPercent(this.calculateAbweichung(this.props.plan.instruments[index]))}</span>
            }}/>
            <Column title="Soll-Gesamt" key="de" render={(value, record, index) => {
                return <span>{Formatter.formatCurrency(this.props.plan.totalAlreadyInvested * (this.props.plan.instruments[index].percent / 100))}</span>
            }}/>
            <Column title="Ist-Gesamt" key="e" render={(value, record, index) => {
                return <span>{Formatter.formatCurrency(this.props.plan.instruments[index].currentValue)}</span>
            }}/>
            <Column title="Abweichung-Gesamt" key="f" render={(value, record, index) => {
                return <span>{Formatter.formatCurrency(this.calculateAbweichungTotal(this.props.plan.instruments[index]))}</span>
            }}/>

        </>;

    }

    private calculateAbweichung(instrument: InstrumentPlanElement) {
        return instrument.currentPercent - instrument.percent;
    }

    private calculateAbweichungTotal(instrument: InstrumentPlanElement) {
        return instrument.currentValue - this.props.plan.totalAlreadyInvested * (instrument.percent / 100);
    }

    protected renderSummaryRow(data: Readonly<InstrumentPlanElement[]>) {

        const totalPercentSoll = data.reduce((a, b) => a + b.percent, 0);
        const totalPercentIst = data.reduce((a, b) => a + b.currentPercent, 0);

        return <Table.Summary.Row style={{fontWeight: "bold"}}>
            <Table.Summary.Cell index={0}>Gesamt</Table.Summary.Cell>
            <Table.Summary.Cell index={2}>{Formatter.formatPercent(totalPercentSoll)}</Table.Summary.Cell>
            <Table.Summary.Cell
                index={3}>{Formatter.formatCurrency((totalPercentSoll / 100) * (this.props.plan.totalPerMonth))}</Table.Summary.Cell>
            <Table.Summary.Cell index={4}>{Formatter.formatPercent(totalPercentIst)}</Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
                {/*TODO: ICh denke das lässt sich nicht sinnvoll quantifizieren... Formatter.formatCurrency(abweichung)*/}
            </Table.Summary.Cell>
            <Table.Summary.Cell
                index={5}>{Formatter.formatCurrency(this.props.plan.totalAlreadyInvested)}</Table.Summary.Cell>
            <Table.Summary.Cell
                index={6}>{Formatter.formatCurrency(this.props.plan.instruments.reduce((a, b) => a + b.currentValue, 0))}</Table.Summary.Cell>
            <Table.Summary.Cell index={8}>
                {/*TODO: ICh denke das lässt sich nicht sinnvoll quantifizieren... Formatter.formatCurrency(totalAbweichung)*/}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={7}></Table.Summary.Cell>
        </Table.Summary.Row>
    }

    private renderDeleteColumn() {
        return <Column width={40} key="instrument.total" render={(value, record, index) => {
            return <Button type="text" onClick={event => {
                const plan = this.props.plan;
                plan.instruments.splice(index, 1);
                this.setListState(plan);
            }
            }><FontAwesomeIcon icon={faMinusCircle}/></Button>
        }}/>
    }

    private renderModal() {
        return InstrumentRenderingHelper.renderSelectionModal(() => this.setState({renderModal: false}),
            item => {
                this.props.plan.instruments.push({instrument: item, percent: 0, id: ""} as InstrumentPlanElement)
                this.setListState(this.props.plan as InstrumentPlan);
                this.setState({
                    renderModal: false,
                })
            });
    }

    private handleOnPercentChange(value: number, index: number) {
        const plan = this.props.plan;
        plan.instruments[index].percent = value
        this.setListState(plan);
    }

    private onAddButton() {
        this.setState({renderModal: true})
    }


}