import React from "react";
import Formatter from "../../global/Formatter";
import RecurringTransactionEntry from "../../data/RecurringTransactionEntry";
import RecurringTransactionsApi from "../../apis/RecurringTransactionsApi";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Button from "antd/es/button";
import Icon from "../../frame/components/Icon";
import {faExchangeAlt} from "@fortawesome/free-solid-svg-icons/faExchangeAlt";
import {OverviewState, OverviewView} from "../../frame/components/base/OverviewView";
import {BalanceStatisticsPanel} from "../../frame/components/BalanceStatisticsPanel";
import {BudgetsApi} from "../../apis/BudgetsApi";
import {TransactionResponse} from "../../data/BaseResponse";
import {Budget, getIntervalTranslation} from "../../data/Budget";
import BudgetBookApi from "../../apis/BudgetBookApi";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {Collapse, Popconfirm, Select, Switch, Tag} from "antd";
import {Config} from "../../frame/Config";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import {Category} from "../../data/Category";
import CategoryApi from "../../apis/CategoryApi";
import {Option} from "antd/es/mentions";

type Mode = "MONTHLY" | "YEARLY";

interface State extends OverviewState {
    entries: TransactionResponse[],
    parentCategories: Category[],
    selectedParentCategoryId: string,
    totalIncome: number,
    totalExpenses: number,
    mode: Mode
    type?: "BUDGET" | "RECURRING"
}

interface Props {

}

export default class RecurringTransactionsView extends OverviewView<Props, State, TransactionResponse> {

    private static isRecurringTransactionEntry(entry: TransactionResponse): entry is RecurringTransactionEntry {
        return "category" in entry;
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            totalIncome: 0,
            totalExpenses: 0,
            selectedParentCategoryId: "",
            query: "",
            mode: "MONTHLY",
            parentCategories: this.generateDummyData(),
            entries: this.generateDummyData()
        }
    }

    async componentDidMount() {
        await this.loadQueryParams();
        this.setState({parentCategories: await CategoryApi.getAllParents()})
        await this.onSearch();
    }

    protected async onSearch() {
        await this.setState({loading: true, entries: this.generateDummyData()});
        await this.pushQueryParams();
        const entries: TransactionResponse[] = await RecurringTransactionsApi.queryEntries(this.state.query, this.state.selectedParentCategoryId);
        entries.push(...(await BudgetsApi.getAll(this.state.query, this.state.selectedParentCategoryId)));
        await this.calculateBalance(this.state.mode, entries);
        this.setState({entries, loading: false})
    }

    protected renderList() {
        return <>
            <Row style={{margin: "10px"}}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    {this.renderSingularList(this.state.entries.filter(value => value.monthlyAmount > 0), true)}
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    {this.renderSingularList(this.state.entries.filter(value => value.monthlyAmount <= 0), false)}
                </Col>
            </Row>
        </>
    }

    private renderSingularList(entries: TransactionResponse[], positive: boolean) {
        return this.renderBasicCardList(entries, item => this.renderItem(item, positive));
    }

    private renderItem(entry: TransactionResponse, positive: boolean) {
        return <>
            <p style={{
                margin: 0,
                fontSize: "17px"
            }}>{entry.designation} {this.renderTag(entry)}</p>
            <p style={{
                margin: 0,
                fontSize: "15px"
            }}><i>{RecurringTransactionsView.generateCategoryString(entry)}</i></p>
            <p style={{
                margin: 0,
                fontSize: "15px",
                color: positive ? "green" : "red"
            }}>
                {Formatter.formatCurrency(this.getPerspectiveValue(entry.monthlyAmount))}
            </p>
        </>;
    }

    private static generateCategoryString(entry: TransactionResponse) {
        if (this.isRecurringTransactionEntry(entry)) {
            return entry.category ? `Kategorie: ${entry.category.designation}` : "-";
        }
        const asBudget = entry as Budget;
        return `Budget (${getIntervalTranslation(asBudget.interval)}) für: ${asBudget.categories.map(value => value.designation).join(", ")}`;
    }

    protected renderAdditionalContent() {

        const balance = this.state.totalIncome - this.state.totalExpenses;
        return <Collapse style={{margin: "10px"}} accordion={true}
                         defaultActiveKey={Config.budgetBookDetailsOpenedByDefault() ? 1 : undefined}>
            <CollapsePanel key={1} header={
                <span style={{padding: "1px"}}>Filter / Details (<span
                    className={`${this.state.totalIncome - this.state.totalExpenses > 0 ? "income-color" : "expenses-color"}`}>
                    Bilanz: {" " + Formatter.formatCurrency(balance)}</span>) </span>
            }>
                {this.renderFilter()} {<BalanceStatisticsPanel totalIncome={this.state.totalIncome}
                                                               totalExpenses={this.state.totalExpenses}/>}
            </CollapsePanel>
        </Collapse>
    }


    private renderFilter() {
        return <Row style={{margin: "10px"}}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                <p><i>Hinweis: Alle Werte werden relativ zum Modus angezeigt, egal wie da Interval ist.</i></p>

                Modus: <Switch checkedChildren="Monatlich" unCheckedChildren="Jährlich"
                               checked={this.state.mode === "MONTHLY"}
                               onChange={() => this.toggleMode()}/>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                Übergeordnete Kategorie:
                <Select
                    loading={this.state.loading}
                    disabled={this.state.loading}
                    showSearch
                    optionFilterProp="children"
                    value={this.state.selectedParentCategoryId}
                    style={{width: "100%"}}
                    onChange={async ev => {
                        await this.setState({selectedParentCategoryId: ev});
                        await this.onSearch();
                    }}
                >
                    <Option value={""}>Alle</Option>
                    {this.state.parentCategories?.map(value => <Option value={value.id}>{value.designation}</Option>)}
                </Select>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
            </Col>

        </Row>
    }

    protected renderAdditionalButtons() {
        return <>
            <Button onClick={ev => this.handleAddBudget()}
                    style={{marginLeft: "6px"}}
                    loading={this.state.loading}
                    icon={<Icon icon={faPlus}/>}>
                Budget
            </Button>

            <Popconfirm
                title="Alle Wiederkehrenden Einträge übertragen?"
                onConfirm={ev => this.handleTransferAll()}
                okText="Ja"
                cancelText="Nein"
            >
                <Button style={{marginLeft: "6px"}}
                        loading={this.state.loading}
                        icon={<Icon icon={faExchangeAlt}/>}>
                    Alle Übertragen
                </Button>
            </Popconfirm>
        </>
    }

    private async calculateBalance(mode: Mode, entries: TransactionResponse[]) {
        if (entries.length > 0) {
            const totalIncome = entries.filter(value => value.monthlyAmount > 0).map(value => value.monthlyAmount)
                .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
            const totalExpenses = entries.filter(value => value.monthlyAmount < 0).map(value => value.monthlyAmount)
                .reduce((previousValue, currentValue) => previousValue + currentValue, 0) * -1;
            await this.setState({mode});
            await this.setState({
                totalIncome: this.getPerspectiveValue(totalIncome),
                totalExpenses: this.getPerspectiveValue(totalExpenses)
            });
        }
        await this.setState({mode});
    }

    //Hint: Je nach ausgewähltem Modus entweder sicht auf Jährlich oder Monatlich...

    protected getPerspectiveValue(value: number): number {
        if (this.state.mode === "YEARLY") {
            return value * 12;
        } else {
            return value;
        }
    }

    private toggleMode() {
        const mode = this.state.mode === "MONTHLY" ? "YEARLY" : "MONTHLY";
        this.calculateBalance(mode, this.state.entries);
    }

    private handleTransferAll() {
        this.state.entries
            .filter(value => RecurringTransactionsView.isRecurringTransactionEntry(value))
            .map(value => value as RecurringTransactionEntry)
            .filter(value => value.active)
            .forEach(entry => BudgetBookApi.addFromRecurring(entry));
    }

    protected onItemSelected(item: TransactionResponse): void {
        this.setState({selectedId: item.id, type: RecurringTransactionsView.getTypeForItem(item)});
    }

    private static getTypeForItem(item: TransactionResponse) {
        return RecurringTransactionsView.isRecurringTransactionEntry(item) ? "RECURRING" : "BUDGET";
    }

    protected getBasePath(): string {
        if (this.state.type === "BUDGET") {
            return "/budgets";
        }
        return "/recurring-transactions";
    }

    private handleAddBudget() {
        this.setState({selectedId: "new", type: "BUDGET"});
    }

    private async loadQueryParams() {
        const params = await this.loadBaseQueryFromUrl();
        await this.setState({
            selectedParentCategoryId: params.get("selectedParentCategoryId") || ""
        });
    }

    private pushQueryParams() {
        window.history.pushState("", "Query", `${this.getBasePath()}?query=${this.state.query}&selectedParentCategoryId=${this.state.selectedParentCategoryId}`);
    }

    private renderTag(entry: TransactionResponse) {
        if(RecurringTransactionsView.isRecurringTransactionEntry(entry)) {
            return (entry as RecurringTransactionEntry).active ?  <Tag color="green">Aktiv</Tag>: <Tag color="default">Inaktiv</Tag>;
        }else {
            return  <Tag color="blue">Budget</Tag>;
        }
    }
}

